import { Theme, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Grid from "@material-ui/core/Grid";
import React, { useCallback, useRef, useState } from "react";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import { createStyles } from "@material-ui/styles";
import red from "@material-ui/core/colors/red";
import { SimpleIconButton } from "../../../../misc/simple-icon-button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {
      backgroundColor: red["600"],
      color: "white",
      "&:hover": {
        backgroundColor: red["900"],
      },
    },
    wrapper: {
      padding: theme.spacing(1),
    },
  })
);

export function RowDeleteButton(props: {
  disabled: boolean;
  onClick: () => void;
}) {
  const [showConfirm, setShowConfirm] = useState(false);
  const onDeleteClick = useCallback(() => setShowConfirm(true), [
    setShowConfirm,
  ]);
  const onCancel = useCallback(() => setShowConfirm(false), [setShowConfirm]);
  const deleteButtonRef = useRef<HTMLDivElement>(null);
  const styles = useStyles();

  return (
    <Grid item key="delete">
      <Tooltip title="Delete">
        <SimpleIconButton
          disabled={props.disabled}
          onClick={onDeleteClick}
          ref={deleteButtonRef}
          size="small"
        >
          <DeleteIcon />
        </SimpleIconButton>
      </Tooltip>
      <Popover
        anchorEl={deleteButtonRef.current}
        open={showConfirm}
        onClose={onCancel}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <Grid direction="row" container spacing={1} className={styles.wrapper}>
          <Grid item>
            <Button
              variant="contained"
              className={styles.deleteButton}
              onClick={props.onClick}
            >
              Confirm
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  );
}
