import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import * as React from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Button from "@material-ui/core/Button";
import { useCallback, useState } from "react";

export function DriverFilter(props: {
  drivers: string[];
  value: string;
  onClear(): void;
  onChange(value: string): void;
}) {
  const [buttonRef, setButtonRef] = useState<any>();
  const [open, setOpen] = useState(false);
  const onOpen = useCallback(() => setOpen(true), []);
  const onClose = useCallback(() => setOpen(false), []);
  const onClear = useCallback(() => {
    setOpen(false);
    props.onClear();
  }, [props]);

  return (
    <React.Fragment>
      <Button
        style={{ color: "white" }}
        ref={setButtonRef}
        endIcon={<KeyboardArrowDownIcon />}
        onClick={onOpen}
      >
        {props.value}
      </Button>
      <Menu anchorEl={buttonRef} keepMounted open={open} onClose={onClose}>
        <MenuItem onClick={onClear}>All Drivers</MenuItem>
        {props.drivers.map((driver) => (
          <MenuItem
            key={driver}
            onClick={() => {
              setOpen(false);
              props.onChange(driver);
            }}
          >
            {driver}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}
