import * as React from "react";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { ScheduleItem, ScheduleLastUploadInfo, User } from "../api/types";
import { api } from "../api/api";
import moment from "moment";
import { List } from "./schedule/list";
import { ScheduleItemWithDriver } from "./schedule/types";
import RefreshIcon from "@material-ui/icons/Refresh";
import { PageContent } from "../components/page-content";
import IconButton from "@material-ui/core/IconButton";
import { getDrivers } from "./schedule-editor/state/field";

interface Props {}

interface State {
  list: ScheduleItemWithDriver[];
  info?: ScheduleLastUploadInfo;
  error?: string;
  loading: boolean;
  dateFilter: Date;
  searchText: string;
}

export class Schedule extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      list: [],
      info: undefined,
      loading: false,
      error: undefined,
      dateFilter: new Date(),
      searchText: "",
    };
  }

  drivers: Promise<{ [k: number]: User }> = Promise.reject("not init");

  componentDidMount() {
    this.drivers = this.fetchDrivers();
    this.fetchList();
  }

  async fetchDrivers(): Promise<{ [k: number]: User }> {
    const users = await api.users.list();
    if (users === null) return {};
    if ("error" in users) {
      throw new Error(users.error);
    }

    return users.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {} as { [k: number]: User });
  }

  async updateDrivers(
    items: ScheduleItem[]
  ): Promise<ScheduleItemWithDriver[]> {
    const drivers = await this.drivers;

    return items.map((item) => {
      const newItem = item as ScheduleItemWithDriver;

      newItem.driverName =
        getDrivers(Object.values(drivers), newItem.driver)
          .map((d) => d.name)
          .join(", ") || "...";
      return newItem;
    });
  }

  async fetchList() {
    this.setState({
      loading: true,
      error: undefined,
      searchText: "",
    });

    try {
      const list = await api.schedule.list(this.state.dateFilter);

      const driverList = await this.updateDrivers(list.list || []);

      this.setState({
        info: list.info,
        list: driverList,
        loading: false,
        error: undefined,
      });
    } catch (err: any) {
      this.setState({
        error: err.message,
        loading: false,
      });
    }
  }

  rawList: ScheduleItemWithDriver[] = [];

  updateSearch(value: string) {
    if (this.state.searchText === value) return;

    if (value === "") {
      this.setState({
        list: this.rawList,
        searchText: value,
      });

      return;
    }

    if (this.state.searchText === "") {
      this.rawList = this.state.list;
    }

    const matches = this.rawList.filter((item) => {
      if (item.localDate.indexOf(value) !== -1) return true;
      if (item.driverName.indexOf(value) !== -1) return true;
      if (item.arrivalTime.indexOf(value) !== -1) return true;
      if (item.pickupLocation.indexOf(value) !== -1) return true;
      if (item.dropOffLocation.indexOf(value) !== -1) return true;
      if (item.customer.indexOf(value) !== -1) return true;
      if (item.unitSize.indexOf(value) !== -1) return true;
      if (item.unitId.indexOf(value) !== -1) return true;
      if (item.purchaseOrderNumber.indexOf(value) !== -1) return true;
      if (item.description.indexOf(value) !== -1) return true;

      if (item.contacts) {
        const matchedContacts = item.contacts.filter(
          (contact) => contact.description.indexOf(value) !== -1
        );
        if (matchedContacts.length > 0) return true;
      }

      if (item.userDefinedId?.toString() === value) return true;

      return false;
    });

    this.setState({
      list: matches,
      searchText: value,
    });
  }

  renderRight() {
    return (
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item>
          {this.state.info !== undefined && (
            <Typography variant={"caption"}>
              Schedule last uploaded on{" "}
              {moment(this.state.info.when).format("MMM D[ at ]H:ma")} by{" "}
              {this.state.info.userName}
            </Typography>
          )}
        </Grid>
        <Grid item>
          <IconButton
            color="inherit"
            aria-label="Refresh"
            onClick={() => this.fetchList()}
          >
            <RefreshIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  }

  render() {
    return (
      <PageContent title="Schedule" rightContent={this.renderRight()}>
        <Grid
          container
          direction="column"
          spacing={2}
          style={{ height: "100%", flexWrap: "nowrap" }}
        >
          <Grid item xs>
            <List
              dateFilter={this.state.dateFilter}
              onDateFilterChange={(date) => {
                this.setState({ dateFilter: date }, () => this.fetchList());
              }}
              searchText={this.state.searchText}
              onSearchTextChanged={(text) => this.updateSearch(text)}
              list={this.state.list}
              loading={this.state.loading}
              error={this.state.error}
            />
          </Grid>
        </Grid>
      </PageContent>
    );
  }
}
