import Paper from "@material-ui/core/Paper";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useAsync } from "../../api/async";
import { api } from "../../api/api";
import { TableCell, TableRow, Typography } from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import moment, { Moment } from "moment";
import { makeStyles } from "@material-ui/styles";
import { grey } from "@material-ui/core/colors";
import { InvoiceEdit } from "./edit/InvoiceEdit";
import { QbLineTypeProvider } from "./edit/QbLineItems";
import { RefreshContext } from "./Invoicing";
import { Invoice } from "../../api/invoice";

const useStyles = makeStyles((theme) => ({
  row: {
    cursor: "pointer",
    "&:hover td": {
      backgroundColor: grey["200"],
    },
  },
}));

export function List() {
  const list = useAsync(() => api.invoices.listPending());
  const styles = useStyles();
  const [edit, setEdit] = useState<number | null>(null);

  const ctx = useContext(RefreshContext);
  const sub = ctx.subscribe;
  useEffect(() => {
    const s = sub(() => {
      list.reload();
    });

    return () => s.cancel();
  }, [sub, list.reload]);

  const groupped = useMemo(() => {
    type GroupItem = {
      week: Moment;
      list: Invoice[];
    };

    let groupped: GroupItem[] = [];

    let last: GroupItem | null = null;

    list.asList.map((r) => {
      const now = moment.utc(r.date).startOf("week");

      if (!last || last.week.valueOf() != now.valueOf()) {
        last = {
          week: now,
          list: [],
        };

        groupped.push(last);
      }

      last.list.push(r);

      return null;
    });

    return groupped;
  }, [list.asList]);

  return (
    <Paper variant="outlined" style={{ overflow: "auto" }}>
      <QbLineTypeProvider>
        {list.LoadingOrErrorElement}
        {groupped.map((g) => (
          <div>
            <div
              style={{ paddingLeft: 16, paddingTop: 30, fontWeight: "bold" }}
            >
              Week of {g.week.format("YYYY-MMM-DD")}
            </div>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Customer</TableCell>
                  <TableCell>Project</TableCell>
                  <TableCell style={{ width: 150 }}>PO</TableCell>
                  <TableCell style={{ width: 150 }}>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {g.list.map((i) => (
                  <TableRow
                    key={i.id}
                    className={styles.row}
                    onClick={() => setEdit(i.id)}
                  >
                    <TableCell>{i.customerName}</TableCell>
                    <TableCell>{i.project}</TableCell>
                    <TableCell>{i.poNumber}</TableCell>
                    <TableCell>
                      {moment.utc(i.date).format("YYYY-MMM-DD")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ))}

        {edit && (
          <InvoiceEdit
            id={edit}
            onClose={() => {
              setEdit(null);
              ctx.reload();
            }}
          />
        )}
      </QbLineTypeProvider>
    </Paper>
  );
}
