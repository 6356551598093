import { api } from "../api/api";
import { sleep } from "../api/async";
import { auth } from "../core/authentication";

export function wsUrl(path: string) {
  const isSecure = window.location.toString().indexOf("https://") === 0;
  const scheme = isSecure ? "wss" : "ws";

  return scheme + "://" + window.location.host + path;
}

export class WebSocketManager {
  socket: WebSocket | null;
  isOpen: Promise<any>;
  onOpen: (input: any) => void = () => {};
  onMessage: (obj: object) => void;

  constructor(onMessage: (obj: object) => void) {
    this.socket = null;
    this.isOpen = new Promise((resolve) => resolve("not ready"));
    this.onMessage = onMessage;

    this.initialize();
  }

  initialize() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }

    this.socket = new WebSocket(wsUrl("/api/web-socket"));

    this.socket.onmessage = (e) => {
      const message = JSON.parse(e.data);

      if (message === "access-denied") {
        this.onClose();
        return;
      }

      this.onMessage(message);
    };

    this.socket.onclose = () => this.onClose();
    this.socket.onopen = () => this.onOpen(true);
    this.isOpen = new Promise((resolve) => {
      this.onOpen = resolve;
    });
  }

  async onClose() {
    try {
      // trigger login or cookie refresh if required.
      // resolves if cookie refresh happened, throws if login failed
      const result = await api.ping();
      if ("error" in result) {
        // if ping fails, we're going to have to wait for a page refresh (on login)
        return;
      }
    } catch (e: any) {
      // if network issue, retry
      await sleep(5 * 1000);
    }

    this.initialize();
  }

  async send(data: object): Promise<void> {
    await this.isOpen;

    if (!this.socket) throw new Error("socket closed");

    this.socket.send(JSON.stringify(data));
  }
}
