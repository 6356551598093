export function filterLimit<T>(
  items: T[],
  comparisonFx: (arg: T) => boolean,
  limit: number
): T[] {
  var results: T[] = [];

  for (let i = 0; i < items.length; i++) {
    if (comparisonFx(items[i])) {
      results.push(items[i]);

      if (results.length === limit) return results;
    }
  }

  return results;
}
