import { File as FileEntity } from "../../../../../../api/types";
import { FileListItem } from "./file-list-item";
import React from "react";
import { Grid } from "@material-ui/core";
import List from "@material-ui/core/List";
import { ImageViewer } from "./image-viewer";

export function FileList(props: {
  files: FileEntity[];
  isSignedDocs: boolean;
  jobName: string;
  extraAction?(file: FileEntity): JSX.Element | null;
  onFileArchived(file: FileEntity): void;
}) {
  const imagesFiles = props.files.filter((f) => isImage(f));
  const standardFiles = props.files.filter((f) => !isImage(f));

  return (
    <Grid container direction="column" spacing={1}>
      {standardFiles.length > 0 && (
        <Grid item>
          <List dense>
            {standardFiles.map((file: FileEntity) => (
              <FileListItem key={file.id + file.name} file={file} {...props} />
            ))}
          </List>
        </Grid>
      )}
      {imagesFiles.length > 0 && (
        <Grid item>
          <ImageViewer
            images={imagesFiles}
            onFileArchived={props.onFileArchived}
          />
        </Grid>
      )}
    </Grid>
  );
}

function isImage(file: FileEntity) {
  return file.name.toLowerCase().match(/\.(png|jpg|jpeg|gif)$/) !== null;
}
