import { Fetcher } from "./fetcher";
import { UserAPI } from "./user-api";
import { VehicleAPI } from "./vehicle-api";
import { ScheduleAPI } from "./schedule";
import { DriverReportAPI } from "./driver-report";
import { FilesAPI } from "./files";
import { WeeklyNoteAPI } from "./WeeklyNoteAPI";
import { InvoiceAPI } from "./invoice";

class API {
  fetcher: Fetcher;
  users: UserAPI;
  vehicles: VehicleAPI;
  schedule: ScheduleAPI;
  driverReport: DriverReportAPI;
  files: FilesAPI;
  weeklyNotes: WeeklyNoteAPI;
  invoices: InvoiceAPI;

  constructor(fetcher: Fetcher) {
    this.fetcher = fetcher;
    this.users = new UserAPI(fetcher);
    this.vehicles = new VehicleAPI(fetcher);
    this.schedule = new ScheduleAPI(fetcher);
    this.driverReport = new DriverReportAPI(fetcher);
    this.files = new FilesAPI(fetcher);
    this.weeklyNotes = new WeeklyNoteAPI(fetcher);
    this.invoices = new InvoiceAPI(fetcher);

    // trigger login immediately if not authenticated.
    // try/catch to prevent uncaught exception
    (async () => {
      try {
        await this.ping();
      } catch (e: any) {
        // do nothing, core/authentication will take care of it
      }
    })();
  }

  async ping() {
    return await this.fetcher.get<{}>("/api/ping");
  }
}

export const api = new API(new Fetcher());
