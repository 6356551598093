import { default as React, useContext } from "react";
import { HeaderRow } from "../schedule-editor/layout/header-row";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { HasNoSearchResultsContext } from "../schedule-editor/list";

export function Header() {
  const hasNoSearchResults = useContext(HasNoSearchResultsContext);

  return (
    <div key="wrapper">
      <HeaderRow key="header-row" />
      {hasNoSearchResults && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          style={{ marginTop: "30px" }}
        >
          <Grid item>
            <Typography color="textSecondary">No results</Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
