import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from "react";
import { api } from "../../../../api/api";
import { useAsync } from "../../../../api/async";

export const JobTypeContext = createContext<{
  list: string[];
  update(list: string[]): void;
}>({
  list: [],
  update: () => console.error("error"),
});

export function JobTypeProvider(props: PropsWithChildren<{}>) {
  const [list, setList] = useState<string[]>([]);

  const fetcher = useAsync(() => api.schedule.listJobTypes());
  useEffect(() => {
    setList(fetcher.asList);
  }, [fetcher.asList]);

  const ctx = useMemo(() => {
    return {
      list: list,
      update: setList,
    };
  }, [list, setList]);

  return (
    <JobTypeContext.Provider value={ctx}>
      {props.children}
    </JobTypeContext.Provider>
  );
}
