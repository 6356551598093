import withStyles from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core";
import { createStyles, WithStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import { DetailPadding } from "./detail-padding";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import * as React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { StyledComponent } from "../pages/schedule-editor/layout/boxes/styled-component";

const styles = (theme: Theme) =>
  createStyles({
    footer: {
      backgroundColor: theme.palette.grey["100"],
      height: "50px",
      paddingLeft: theme.spacing(2),
    },
    title: {
      paddingLeft: theme.spacing(3),
      paddingTop: theme.spacing(2),
    },
  });

interface ExtraButton {
  name: string;
  onClick(): void;
}

type Props = WithStyles<typeof styles> & {
  title: string;
  extraButtons?: ExtraButton[];
  onSave(): Promise<void>;
  onCancel(): void;
  children: any;
};

interface State {
  loading: boolean;
  error?: string;
}

export const EditSection: StyledComponent<Props> = withStyles(styles)(
  class EditSection extends React.Component<Props, State> {
    constructor(props: any) {
      super(props);

      this.state = {
        loading: false,
        error: undefined,
      };
    }

    async doAction(action: () => Promise<void>) {
      try {
        this.setState({
          loading: true,
          error: undefined,
        });

        await action();

        this.setState({
          loading: false,
        });
      } catch (e: any) {
        this.setState({
          loading: false,
          error: e.message,
        });
      }
    }

    render() {
      return (
        <Grid container direction="column">
          <Grid item>
            <div className={this.props.classes.title}>
              <Typography variant="h6">{this.props.title}</Typography>
            </div>
          </Grid>
          <Grid item>
            <DetailPadding>{this.props.children}</DetailPadding>
          </Grid>
          <Grid item xs>
            <div className={this.props.classes.footer}>
              <Grid
                container
                direction="row"
                justifyContent={"flex-start"}
                alignItems={"center"}
                spacing={2}
              >
                <Grid item>
                  <Button
                    color="primary"
                    onClick={() => this.doAction(this.props.onSave)}
                  >
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button color="primary" onClick={this.props.onCancel}>
                    Cancel
                  </Button>
                </Grid>
                {this.props.extraButtons &&
                  this.props.extraButtons.map((item) => {
                    return (
                      <Grid item key={item.name}>
                        <Button color="primary" onClick={item.onClick}>
                          {item.name}
                        </Button>
                      </Grid>
                    );
                  })}
                {this.state.error && (
                  <Grid item>
                    <Typography color="error">{this.state.error}</Typography>
                  </Grid>
                )}
                {this.state.loading && (
                  <Grid item>
                    <CircularProgress size="15px" />
                  </Grid>
                )}
              </Grid>
            </div>
          </Grid>
        </Grid>
      );
    }
  }
) as any;
