import FormatColorFillIcon from "@material-ui/icons/FormatColorFill";
import React from "react";
import { PaintButtonPopover } from "./paint-button-popover";
import { SimpleIconButton } from "../../../../misc/simple-icon-button";

type Props = {
  driverColors?: boolean;
  disabled: boolean;
  color: string;
  onChange(color: string): void;
};

interface State {
  open: boolean;
}

export class PaintButton extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      open: false,
    };
  }

  anchorEl: any = null;

  render() {
    return (
      <div ref={(r) => (this.anchorEl = r)}>
        <SimpleIconButton
          size="small"
          disabled={this.props.disabled}
          onClick={() => this.setState({ open: !this.state.open })}
        >
          <FormatColorFillIcon />
        </SimpleIconButton>

        <PaintButtonPopover
          driverColors={this.props.driverColors}
          open={this.state.open}
          color={this.props.color}
          anchorEl={this.anchorEl}
          onClose={() => this.setState({ open: false })}
          onSelect={(color) => {
            this.setState({ open: false });
            this.props.onChange(color);
          }}
        />
      </div>
    );
  }
}
