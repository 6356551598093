import { Moment } from "moment";
import { ScheduleItemWithDriverReportInfo } from "../../../../api/types";
import { dateColumnFormat } from "../../state/field";
import { ClickableBox } from "./clickable-box";
import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import { createStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      height: 20,

      "& td": {
        border: "none",
      },
    },
  })
);

export function ControlDateCell(props: {
  date?: Moment;
  onMakeUpdate(value: string): Promise<ScheduleItemWithDriverReportInfo>;
  scheduleId: number;
  isSearching?: boolean;
}) {
  const styles = useStyles();
  const dateStr = props.date
    ? props.date.format(props.isSearching ? "MMM D, YYYY" : dateColumnFormat)
    : "";

  return (
    <table className={styles.table} cellSpacing={0}>
      <tbody>
        <tr>
          <ClickableBox
            oneClickToEdit
            dataMoment={props.date}
            onMakeUpdate={props.onMakeUpdate}
            type="editable"
            index={0}
            height={30}
            column="localDate"
            scheduleId={props.scheduleId}
          >
            {dateStr}
          </ClickableBox>
        </tr>
      </tbody>
    </table>
  );
}
