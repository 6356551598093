import React, { createContext, useMemo } from "react";
import { PageContent } from "../../components/page-content";
import { Status } from "./Status";
import { List } from "./List";
import { EventEmitter } from "nate-react-api-helpers";

export const RefreshContext = createContext({
  subscribe: (cb: () => void) => {
    return {
      cancel: () => {},
    };
  },
  reload: () => {},
});

export function Invoicing() {
  const ctx = useMemo(() => {
    const emit = new EventEmitter();

    return {
      subscribe: (cb: () => void) => {
        return emit.subscribe(cb);
      },
      reload: () => {
        emit.emit(null);
      },
    };
  }, []);

  return (
    <PageContent title="Invoicing">
      <RefreshContext.Provider value={ctx}>
        <div
          style={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <Status />
          <List />
        </div>
      </RefreshContext.Provider>
    </PageContent>
  );
}
