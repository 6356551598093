import * as React from "react";
import { User } from "../../../../api/types";
import { showSnack } from "../../../../components/snacker";
import { Loading } from "../../../../components/loading";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { Ref, useCallback, useEffect, useMemo, useState } from "react";
import { CellAutoComplete } from "./cell-auto-complete";
import { Command } from "./command";
import { getDrivers } from "../../state/field";

const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option: User) => option.name,
});

export const DriverContext = React.createContext<User[]>([]);

type Props = {
  onMakeUpdate(newValue: string, cmd: Command): Promise<any>;
  onClose(cmd: Command): void;
  children: string;
};

interface State {
  loading: boolean;
  cleared: boolean;
}

export class DriverEditor extends React.Component<Props, State> {
  textField = React.createRef<HTMLTextAreaElement>();

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      cleared: false,
    };
  }

  async save(id: string | null, cmd: Command): Promise<any> {
    try {
      this.setState({ loading: true });
      await this.props.onMakeUpdate(id || "", cmd);
      showSnack("Saved");
    } catch (e: any) {
      this.setState({ loading: false });
      showSnack("Failed to save: " + e.message);
    }
  }

  renderField() {
    return (
      <DriverContext.Consumer>
        {(drivers) => (
          <DriverAutoComplete
            drivers={drivers}
            textField={this.textField}
            save={(id, cmd) => this.save(id, cmd)}
            close={this.props.onClose}
          >
            {this.props.children}
          </DriverAutoComplete>
        )}
      </DriverContext.Consumer>
    );
  }

  render() {
    if (this.state.loading) return <Loading />;
    return this.renderField();
  }
}

function DriverAutoComplete(props: {
  children: string;
  drivers: User[];
  textField: Ref<HTMLTextAreaElement>;
  save(id: string | null, e: Command): void;
  close(e: Command): void;
}) {
  const [selectedDriver, setSelectedDriver] = useState<User[]>([]);

  useEffect(() => {
    setSelectedDriver(getDrivers(props.drivers, props.children));
  }, [props.drivers, props.children]);

  useMemo(() => {
    props.drivers.sort((a, b) => {
      if (a.name > b.name) return 1;
      if (b.name === a.name) return 0;
      return -1;
    });
  }, [props.drivers]);

  const save = useCallback(
    (value: (string | User)[] | null, cmd) => {
      if (value === null) {
        console.error("unexpected value", value);
        props.save(null, cmd);
        return;
      }

      const ids = value
        .map((v) => (typeof v === "string" ? null : v.id))
        .filter((v) => v != null)
        .join(",");

      props.save(ids, cmd);
    },
    [props]
  );

  return (
    <CellAutoComplete
      options={props.drivers}
      value={selectedDriver}
      getOptionLabel={(dr) => dr.name}
      filterOptions={filterOptions}
      save={save}
      placeholder="Start typing a driver..."
      close={props.close}
      multiple={true}
      requireConfirm={true}
    />
  );
}
