import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { createStyles, Drawer, Theme } from "@material-ui/core";
import * as React from "react";
import Grid from "@material-ui/core/Grid";
import ExitToApp from "@material-ui/icons/ExitToApp";
import ScheduleIcon from "@material-ui/icons/Schedule";
import AssignmentIcon from "@material-ui/icons/AssignmentTurnedIn";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import PersonIcon from "@material-ui/icons/Person";
import ShippingIcon from "@material-ui/icons/LocalShipping";
import { Paths } from "../nav";
import * as Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { api } from "../api/api";
import { auth } from "../core/authentication";
import { SidebarItem } from "./sidebar-item";
import DatePickerIcon from "@material-ui/icons/Event";
import { StyledComponent } from "../pages/schedule-editor/layout/boxes/styled-component";

const width = 58;
const appBarHeight = "56px";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      top: appBarHeight,
      bottom: "0",
      height: "auto",
      width: width + "px",
      overflow: "hidden",
    },
    root: {
      position: "relative",
      zIndex: 0,
    },
    main: {
      position: "absolute",
      top: appBarHeight,
      left: width + "px",
      bottom: "0",
      right: "0",
    },
    mainPadding: {
      padding: theme.spacing(2),
    },
    ellipsis: {
      display: "block",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    linkContainer: {
      paddingTop: "10px",
      paddingLeft: "5px",
      paddingRight: "5px",
    },
  });

interface State {
  userName: string;
  email: string;
}

type Props = WithStyles<typeof styles> & {
  noPadding?: boolean;
};

export const Sidebar: StyledComponent<Props> = withStyles(styles)(
  class Sidebar extends React.Component<Props, State> {
    constructor(props: any) {
      super(props);

      this.state = {
        userName: "",
        email: "",
      };

      auth.onAccessTokenChanged = () => this.updateFromAccessToken();
    }

    componentDidMount(): void {
      this.updateFromAccessToken();
    }

    updateFromAccessToken(): void {
      const token = Cookies.get("token");
      if (!token) return;
      const result = jwt_decode(token) as any;

      this.setState({
        userName: result.Name,
        email: result.Email,
      });
    }

    async logout() {
      await api.users.logout();
    }

    render() {
      var mainClassName = this.props.classes.main;
      if (!this.props.noPadding) {
        mainClassName += " " + this.props.classes.mainPadding;
      }

      return (
        <div>
          <Drawer
            variant="persistent"
            anchor="left"
            open={true}
            classes={{
              root: this.props.classes.root,
              paper: this.props.classes.paper,
            }}
          >
            <Grid
              container
              direction="column"
              style={{ height: "100%" }}
              className={this.props.classes.linkContainer}
              justifyContent={"space-between"}
              spacing={2}
            >
              <Grid item style={{ width: "100%" }}>
                <Grid container direction="column" spacing={1}>
                  <SidebarItem
                    name="Schedule Editor"
                    key="schedule-editor"
                    path={Paths.ScheduleEditor}
                    icon={<DatePickerIcon />}
                  />
                  <SidebarItem
                    name="Schedule"
                    key="schedule"
                    path={Paths.Schedule}
                    icon={<ScheduleIcon />}
                  />
                  <SidebarItem
                    name="Driver Reports"
                    key="driver-report"
                    path={Paths.DriverReport}
                    icon={<AssignmentIcon />}
                  />
                  <SidebarItem
                    name="Invoicing"
                    key="invoicing"
                    path={Paths.Invoicing}
                    icon={<AccountBalanceIcon />}
                  />
                  <SidebarItem
                    name="Users"
                    key="users"
                    path={Paths.Users}
                    icon={<PersonIcon />}
                  />
                  <SidebarItem
                    name="Vehicles"
                    key="vehicles"
                    path={Paths.Vehicles}
                    icon={<ShippingIcon />}
                  />
                </Grid>
              </Grid>
              <SidebarItem
                name="Logout"
                onClick={() => this.logout()}
                icon={<ExitToApp />}
              />
            </Grid>
          </Drawer>
          <main className={mainClassName}>{this.props.children}</main>
        </div>
      );
    }
  }
) as any;
