import { KeyboardDatePicker } from "@material-ui/pickers";
import * as React from "react";
import { FilledInputProps, InputLabelProps, Theme } from "@material-ui/core";
import { useCallback } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import TodayIcon from "@material-ui/icons/Today";

const inputProps = {
  style: {
    color: "#ffffff",
    backgroundColor: "rgba(255,255,255,0.15)",
  },
  disableUnderline: true,
} as Partial<FilledInputProps>;

const labelProps = {
  style: {
    color: "rgba(255,255,255,0.80)",
  },
} as Partial<InputLabelProps>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    endButton: {
      color: "#ffffff",
      display: "flex",
    },
  })
);

export function WeekOfFilter(props: {
  value: Date;
  onChange(newDate: Date): void;
}) {
  const onChange = useCallback(
    (value: Date | null) => {
      if (!value) return;
      props.onChange(value);
    },
    [props]
  );
  const styles = useStyles();

  return (
    <div>
      <KeyboardDatePicker
        label="Week Of"
        autoOk
        format="yyyy/MM/dd"
        inputVariant="filled"
        value={props.value}
        InputProps={inputProps}
        InputLabelProps={labelProps}
        onChange={onChange}
        keyboardIcon={
          <span className={styles.endButton}>
            <TodayIcon color="inherit" />
          </span>
        }
      />
    </div>
  );
}
