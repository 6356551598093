import React, { useCallback, useState } from "react";
import { useActionButtons } from "../../../../../../components/use-action-buttons";
import { api } from "../../../../../../api/api";
import { showSnack } from "../../../../../../components/snacker";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import { EmailToAutoComplete } from "../EmailToAutoComplete";

export function EmailSignedDocModal(props: {
  id: number;
  docName: string;
  jobName: string;
  onDone(): void;
}) {
  const [to, setTo] = useState("");
  const [jobName, setJobName] = useState(props.jobName);

  const onSave = useCallback(async () => {
    await api.files.emailSigned({
      toCSV: to,
      jobName: jobName,
      id: props.id,
    });

    showSnack("Email sent");
    props.onDone();
  }, [props, jobName, to]);

  const { Actions } = useActionButtons({
    saveText: "Send",
    onSave: onSave,
    onCancel: props.onDone,
  });

  return (
    <Dialog open={true}>
      <DialogTitle>Email Signed Document</DialogTitle>
      <List>
        <ListItem>
          <EmailToAutoComplete value={to} onChange={setTo} />
        </ListItem>
        <ListItem>
          <TextField
            label="Job name"
            value={jobName}
            onChange={(e) => setJobName(e.target.value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </ListItem>
        <ListItem>
          <TextField
            label="Document"
            value={props.docName}
            InputLabelProps={{ shrink: true }}
            InputProps={{ disableUnderline: true }}
            fullWidth
          />
        </ListItem>
      </List>
      <DialogActions>
        <Actions />
      </DialogActions>
    </Dialog>
  );
}
