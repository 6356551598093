import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, WithStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import * as React from "react";
import { StyledComponent } from "../pages/schedule-editor/layout/boxes/styled-component";

const styles = (theme: Theme) =>
  createStyles({
    padding: {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
  });

type Props = WithStyles<typeof styles>;

export const DetailPadding: StyledComponent<Props> = withStyles(styles)(
  class DetailPadding extends React.Component<Props, {}> {
    render() {
      return (
        <div className={this.props.classes.padding}>{this.props.children}</div>
      );
    }
  }
) as any;
