type Callback<T> = (p: T) => void;
export interface Subscription {
  unsubscribe: () => void;
}

export class EventEmitter<T> {
  listeners: Callback<T>[] = [];

  emit(param: T) {
    this.listeners.map((listener) => listener(param));
  }

  subscribe(callback: Callback<T>): Subscription {
    this.listeners.push(callback);

    return {
      unsubscribe: () => {
        var i = this.listeners.indexOf(callback);
        if (i === -1) return;
        this.listeners.splice(i, 1);
      },
    };
  }
}
