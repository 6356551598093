import { TextField } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { useAsync } from "../../../api/async";
import { api } from "../../../api/api";
import { Autocomplete } from "@material-ui/lab";

type Input = {
  commonName: string;
  qbCustomer?: number;
};

export function CustomerSelect(props: {
  customerName: string;
  customerId?: number;
  onChange(id?: number): void;
}) {
  const id = props.customerId;
  const [search, setSearch] = useState("-");

  const customers = useAsync(
    async () => {
      if (search === "-") return [];
      return await api.invoices.listCustomers({
        search,
        id: id,
      });
    },
    { dependancies: [search, id] }
  );

  const selected = (r: Input) =>
    r.commonName + (r.qbCustomer ? " (#" + r.qbCustomer + ")" : "");
  const current = selected({
    commonName: props.customerName,
    qbCustomer: props.customerId,
  });

  const value = useMemo(() => {
    if (search !== "-" && search !== current && search !== props.customerName) {
      return {
        commonName: search,
        qbCustomer: undefined as any,
      };
    }

    if (props.customerId) {
      return {
        commonName: props.customerName,
        qbCustomer: props.customerId,
      };
    }

    return {
      commonName: props.customerName,
      qbCustomer: undefined as any,
    };
  }, [props.customerId, props.customerName, customers.asList, search, current]);

  return (
    <>
      <Autocomplete
        renderInput={(props) => (
          <TextField
            {...props}
            variant="outlined"
            size="small"
            fullWidth
            label="Customer"
            error={value?.qbCustomer === undefined}
            helperText={
              value?.qbCustomer === undefined ? "Unknown Customer" : null
            }
          />
        )}
        value={value}
        options={customers.asList}
        getOptionLabel={(r) => selected(r)}
        openOnFocus
        blurOnSelect
        onInputChange={(_, value) => {
          console.log("input", value);
          setSearch(value);
        }}
        onFocus={(e) => {
          setSearch("");
        }}
        onBlur={(e) => {
          setSearch("-");
        }}
        onChange={(e, value, details) => {
          console.log("on-change", value);
          if (typeof value === "string") return;
          if (!value?.qbCustomer) return;
          props.onChange(value?.qbCustomer);
        }}
        freeSolo
      />
      {customers.error ? customers.LoadingOrErrorElement : null}
    </>
  );
}
