import { ScheduleItemWithDriverReportInfo } from "../../../api/types";
import { ColumnName, DataField, dataFields, FieldValue } from "../state/field";
import { widths } from "../state/config";
import { renderCell } from "../layout/boxes/clickable-box";
import ReactDOM from "react-dom";
import { DataRow } from "../layout/data-row";

class HeightCalculator {
  testDiv: HTMLDivElement;
  testFields: DataField[];

  constructor() {
    this.testDiv = document.createElement("div");
    this.testDiv.className = "box-height-tester";
    this.testDiv.style.visibility = "hidden";
    this.testDiv.style.position = "absolute";
    this.testDiv.style.top = "0";
    this.testDiv.style.left = "-1000px";
    document.body.appendChild(this.testDiv);

    this.testFields = dataFields.filter(
      (field) =>
        [
          "description",
          "contacts",
          "pickupLocation",
          "dropOffLocation",
        ].indexOf(field.name) !== -1
    );
  }

  heightCalcTime: number[] = [];

  expensiveHeightCalculation(display: string, fieldName: ColumnName) {
    return new Promise<number>((resolve) => {
      ReactDOM.render(
        renderCell({
          boxClasses: "",
          height: "auto",
          width: widths[fieldName],
          display: display,
        }),
        this.testDiv,
        () => {
          const padding = 5;
          resolve(this.testDiv.offsetHeight + padding);
        }
      );
    });
  }

  calculateWithChanges(
    schedule: ScheduleItemWithDriverReportInfo,
    changes: FieldValue[]
  ) {
    const clone = Object.assign({}, schedule) as {
      [k in keyof ScheduleItemWithDriverReportInfo]: any;
    };
    for (let i = 0; i < changes.length; i++) {
      const change = changes[i];
      clone[change.field] = change.value;
    }

    return this.calculate(clone);
  }

  async calculate(schedule: ScheduleItemWithDriverReportInfo) {
    const start = new Date().getTime();

    const heights = [];
    for (let i = 0; i < this.testFields.length; i++) {
      const field = this.testFields[i];
      const display = field.getValue(schedule, []).display;

      // heuristic: if the text is short or doesn't have many newlines, it's
      // going to fit in DataRow.height
      if (
        display.length <= 50 &&
        display.indexOf("\n") === -1 &&
        display.indexOf("</p><p>") === -1
      ) {
        heights.push(0);
        continue;
      }

      heights.push(await this.expensiveHeightCalculation(display, field.name));
    }

    if (
      ["in-progress", "completed"].indexOf(schedule.driverReportStatus) !== -1
    ) {
      heights.push(95);
    }

    if (
      schedule.driverUploadedOneOrMoreFiles ||
      schedule.officeUploadedOneOrMoreFiles
    ) {
      heights.push(100);
    }

    const maxRequiredHeight = Math.max(...heights);
    let height = Math.max(maxRequiredHeight, DataRow.height); // prevent 0-height items

    this.heightCalcTime.push(new Date().getTime() - start);

    return height;
  }

  analyze() {
    const sum = this.heightCalcTime.reduce((acc, item) => acc + item, 0);
    console.log(
      "height-calc: total time: " +
        sum +
        "ms avg-per: " +
        sum / this.heightCalcTime.length +
        "ms"
    );
  }
}

export const heightCalculator = new HeightCalculator();
