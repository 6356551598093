import React from "react";
import { Router, Redirect, Route, Switch } from "react-router-dom";
import { Schedule } from "./pages/schedule";
import { history, Nav, Paths } from "./nav";
import { Users } from "./pages/users";
import { DriverReports } from "./pages/driver-reports";
import { Vehicles } from "./pages/vehicles";
import { NotFound } from "./pages/not-found";
import { createTheme, CssBaseline } from "@material-ui/core";
import { blue, grey } from "@material-ui/core/colors";
import { ThemeProvider } from "@material-ui/styles";
import { Snacker } from "./components/snacker";
import { Login } from "./components/login";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ScheduleEditor } from "./pages/schedule-editor";
import { Invoicing } from "./pages/invoicing/Invoicing";
import { ScheduleEditor2 } from "./pages/schedule-editor2";

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: {
      light: grey["100"],
      main: grey["300"],
      dark: grey["500"],
      contrastText: "#000000",
    },
  },
  overrides: {
    MuiGrid: {
      container: {
        alignItems: "stretch",
      },
    },
  },
});

interface State {}
interface Props {}

export class App extends React.Component<Props, State> {
  render() {
    return (
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router history={history}>
              <Switch>
                <Route
                  path={Paths.ScheduleEditor}
                  exact
                  component={ScheduleEditor as any}
                />
                <Route
                  path={Paths.ScheduleEditor + "2"}
                  exact
                  component={ScheduleEditor2 as any}
                />
                <Route path={Paths.Invoicing} exact component={Invoicing} />
                <Route
                  path={Paths.Schedule}
                  exact
                  component={Schedule as any}
                />
                <Route path={Paths.Users} exact component={Users as any} />
                <Route
                  path={Paths.DriverReport}
                  exact
                  component={DriverReports as any}
                />
                <Route
                  path={Nav.idPathTemplate(Paths.DriverReport)}
                  component={DriverReports as any}
                />
                <Route
                  path={Paths.Vehicles}
                  exact
                  component={Vehicles as any}
                />
                <Redirect from="/" exact to={Paths.Schedule} />
                <Route component={NotFound as any} />
              </Switch>
              <Snacker />
              <Login />
            </Router>
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </div>
    );
  }
}

export default App;
