import * as React from "react";
import { DefaultEditor } from "./default-editor";
import { DateEditor } from "./date-editor";
import { DriverEditor } from "./driver-editor";
import { JobType } from "./job-type";
import { ColumnName } from "../../state/field";
import { Moment } from "moment";
import { Command } from "./command";
import { useCallback } from "react";

export function CellEditor(props: {
  column: ColumnName;
  dataMoment?: Moment;
  dataString?: string;
  onCancel(cmd: Command): void;
  onMakeUpdate(value: string): Promise<void>;
}) {
  const onMakeUpdateNone = useCallback((e) => props.onMakeUpdate(e), [props]);
  const onCancelNone = useCallback(() => props.onCancel("none"), [props]);
  const onMakeUpdateDate = useCallback(
    (e) => props.onMakeUpdate(e.format("YYYY-MM-DD")),
    [props]
  );

  if (props.column === "driver") {
    return (
      <DriverEditor
        key="driver-editor"
        onMakeUpdate={props.onMakeUpdate}
        onClose={props.onCancel}
      >
        {props.dataString || ""}
      </DriverEditor>
    );
  }

  if (props.column === "jobType") {
    return (
      <JobType
        key="job-type"
        onMakeUpdate={onMakeUpdateNone}
        value={props.dataString || ""}
        onClose={props.onCancel}
      />
    );
  }

  if (props.dataMoment !== undefined) {
    return (
      <DateEditor
        key="date-editor"
        onMakeUpdate={onMakeUpdateDate}
        onClose={onCancelNone}
      >
        {props.dataMoment}
      </DateEditor>
    );
  }

  return (
    <DefaultEditor
      key="box-editor"
      initialValue={props.dataString || ""}
      onEsc={onCancelNone}
      onMakeUpdate={props.onMakeUpdate}
    />
  );
}
