import { Grid, Theme } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Paper from "@material-ui/core/Paper";
import * as React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, WithStyles } from "@material-ui/styles";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { ListItem } from "./list-item";
import { ScheduleItemWithDriver } from "./types";
import { StyledComponent } from "../schedule-editor/layout/boxes/styled-component";

const styles = (theme: Theme) =>
  createStyles({
    controls: {
      padding: theme.spacing(2),
    },
    message: {
      padding: theme.spacing(4),
    },
    noData: {
      color: theme.palette.text.disabled,
    },
    item: {
      padding: theme.spacing(4),
      "&:nth-child(even)": {
        backgroundColor: theme.palette.grey["100"],
      },
    },
  });

type Props = WithStyles<typeof styles> & {
  dateFilter: Date;
  onDateFilterChange(date: Date): void;
  onSearchTextChanged(text: string): void;
  searchText: string;
  list: ScheduleItemWithDriver[];
  loading: boolean;
  error?: string;
};

export const List: StyledComponent<Props> = withStyles(styles)(
  class List extends React.Component<Props, {}> {
    renderContent() {
      if (this.props.loading) {
        return (
          <Grid item className={this.props.classes.message}>
            <CircularProgress size={30} />
          </Grid>
        );
      }

      if (this.props.error) {
        return (
          <Grid item className={this.props.classes.message}>
            <Typography color="error">{this.props.error}</Typography>
          </Grid>
        );
      }

      if (this.props.list.length === 0) {
        return (
          <Grid item className={this.props.classes.message}>
            <Typography className={this.props.classes.noData}>
              No schedule items. <br />
              Upload some more, or adjust the date and search filters.
            </Typography>
          </Grid>
        );
      }

      return this.props.list.map((item) => {
        return (
          <Grid item key={item.id} className={this.props.classes.item}>
            <ListItem item={item} />
          </Grid>
        );
      });
    }

    render() {
      return (
        <Paper style={{ height: "100%" }}>
          <Grid container direction="column" style={{ height: "100%" }}>
            <Grid item style={{ width: "100%" }}>
              <Grid
                container
                direction="row"
                alignItems={"flex-start"}
                className={this.props.classes.controls}
              >
                <Grid item xs={6}>
                  <TextField
                    label="Search"
                    value={this.props.searchText}
                    onChange={(e) =>
                      this.props.onSearchTextChanged(e.currentTarget.value)
                    }
                    style={{ width: "300px" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <KeyboardDatePicker
                        label="Date"
                        value={this.props.dateFilter}
                        autoOk
                        variant="inline"
                        format="yyyy/MM/dd"
                        onChange={(value) => {
                          if (!value) return;
                          this.props.onDateFilterChange(value);
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider />
            </Grid>
            <Grid item style={{ overflow: "auto" }} xs>
              <Grid container direction={"column"}>
                {this.renderContent()}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      );
    }
  }
) as any;
