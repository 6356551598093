import * as React from "react";
import { PageContent } from "../components/page-content";
import { Footer } from "./schedule-editor/layout/footer";
import { fetcher, Item } from "./schedule-editor/utils/fetcher";
import { Grid } from "@material-ui/core";
import { Loading } from "../components/loading";
import { Subscription } from "../misc/event-emitter";
import { stateManager } from "./schedule-editor/state/state-manager";
import { _ScheduleItemWithDriverReportInfo, User } from "../api/types";
import { DriverContext } from "./schedule-editor/layout/cell-editor/driver-editor";
import Typography from "@material-ui/core/Typography";
import { filterLimit } from "../misc/filter-limited";
import { dataFields } from "./schedule-editor/state/field";
import { debounce } from "debounce";
import { List } from "./schedule-editor/list";
import Button from "@material-ui/core/Button";
import moment, { Moment } from "moment";
import { ListOnItemsRenderedProps } from "react-window";
import { UndoProvider } from "./schedule-editor/utils/undo";
import { PageHeaderRight } from "./schedule-editor/page-header-right";
import { PageHeaderCenter } from "./schedule-editor/page-header-center";
import { JobTypeProvider } from "./schedule-editor/layout/cell-editor/job-type-provider";

export const IsSearchingContext = React.createContext<boolean>(false);

interface Props {}

interface State {
  loading: boolean;
  error: string | null;
  items: Item[];
  drivers: User[];
  search: string;
  showAllSearchResults: boolean;
  displayMonth: string;
}

const defaultSearchLimit = 10;

export class ScheduleEditor extends React.Component<Props, State> {
  listRef: List | null = null;
  initializeHasScrolledToLastItem: boolean = false;

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      error: null,
      items: [],
      drivers: [],
      search: "",
      showAllSearchResults: false,
      displayMonth: "",
    };

    this.updateItemsFromRaw = this.updateItemsFromRaw.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.onScrollRenderChanged = this.onScrollRenderChanged.bind(this);
    this.autoScrollTo = this.autoScrollTo.bind(this);
    this.autoScrollToToday = this.autoScrollToToday.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
  }

  itemUpdated: Subscription | null = null;
  weeklyNoteUpdated: Subscription | null = null;

  componentDidMount(): void {
    this.itemUpdated = stateManager.scheduleItemUpdateEvents.subscribe(() =>
      this.onItemUpdated()
    );

    this.weeklyNoteUpdated = fetcher.updatedWeeklyNote.subscribe(() =>
      this.onItemUpdated()
    );

    this.fetchList();
  }

  componentWillUnmount(): void {
    if (this.itemUpdated) this.itemUpdated.unsubscribe();
    if (this.weeklyNoteUpdated) this.weeklyNoteUpdated.unsubscribe();
  }

  onItemUpdated() {
    this.updateItemsFromRaw();
  }

  getRawScheduleItems(): _ScheduleItemWithDriverReportInfo[] {
    if (this.state.search === "") return stateManager.rawScheduleItems;
    return this.filterRawScheduleItems(
      stateManager.rawScheduleItems,
      this.state.search
    );
  }

  async updateItemsFromRaw() {
    if (this.listRef === null) return;

    const rawItems = this.getRawScheduleItems();

    this.listRef.capturePreRenderScrollPosition();

    this.setState({
      items: await fetcher.convertToItems(rawItems, fetcher.drivers),
    });
  }

  async fetchList() {
    this.setState({
      loading: true,
      error: null,
    });

    try {
      const list = await fetcher.loadAll();
      stateManager.rawScheduleItems = list.scheduleItems.map((i) =>
        stateManager.toInternalDriverReport(i)
      );

      this.setState(
        {
          drivers: list.drivers,
          items: list.items,
          loading: false,
        },
        () => this.initializeAutoScroll()
      );
    } catch (err: any) {
      this.setState({
        error: err.message,
        loading: false,
      });
    }
  }

  initializeAutoScroll() {
    if (this.initializeHasScrolledToLastItem) return;
    if (this.state.items.length === 0) return;

    let scrollFx = () => this.autoScrollToToday();

    const params = new URLSearchParams(window.location.search);
    const scrollTo = params.get("show");
    if (scrollTo !== null) {
      try {
        const id = parseInt(scrollTo, 10);
        scrollFx = () => this.autoScrollToSchedule(id);
      } catch (e: any) {
        // ignore error from parseInt()
      }
    }

    this.initializeHasScrolledToLastItem = true;
    this.repeatUntilTrue(scrollFx, 100);
  }

  repeatUntilTrue(fx: () => boolean, interval: number) {
    if (fx()) return;
    setTimeout(() => this.repeatUntilTrue(fx, interval), interval);
  }

  autoScrollToToday(): boolean {
    if (this.state.search) {
      this.clearSearch(() => this.autoScrollToToday());
      return false;
    }

    return this.autoScrollTo(moment().startOf("day"));
  }

  autoScrollToSchedule(id: number): boolean {
    if (
      this.listRef === null ||
      this.listRef.listRef === null ||
      this.listRef.listRef.listRef.current === null
    )
      return false;

    const i = this.state.items.findIndex(
      (item) => item.scheduleItem != null && item.scheduleItem.id === id
    );
    if (i === -1) return false;

    this.listRef.scrollToItem(i);
    return true;
  }

  autoScrollTo(date: Moment): boolean {
    if (
      this.listRef === null ||
      this.listRef.listRef === null ||
      this.listRef.listRef.listRef.current === null
    )
      return false;

    const i = this.state.items.findIndex(
      (item) => item.date != null && item.date.isSameOrAfter(date)
    );
    if (i === -1) return false;

    this.listRef.scrollToItem(i);
    return true;
  }

  _updateSearch = debounce(
    (e: string, then?: (input: Item[]) => void) =>
      this._updateSearchWithExpensiveStateChange(e, then),
    100
  );

  updateSearch(e: string, then?: (input: Item[]) => void) {
    this.setState(
      {
        search: e,
        showAllSearchResults: false,
      },
      () => {
        this._updateSearch(e, then);
      }
    );
  }

  async _updateSearchWithExpensiveStateChange(
    e: string,
    then?: (items: Item[]) => void
  ) {
    if (e === "") {
      const items = await fetcher.convertToItems(
        stateManager.rawScheduleItems,
        this.state.drivers,
        false
      );
      this.setState(
        {
          items: items,
        },
        () => then?.(items)
      );

      return;
    }

    const rawItems = this.filterRawScheduleItems(
      stateManager.rawScheduleItems,
      e
    );
    const items = await fetcher.convertToItems(
      rawItems,
      this.state.drivers,
      true
    );

    this.setState(
      {
        items: items,
      },
      () => then?.(items)
    );
  }

  filterRawScheduleItems(
    items: _ScheduleItemWithDriverReportInfo[],
    search: string
  ): _ScheduleItemWithDriverReportInfo[] {
    search = search.toLowerCase();

    return filterLimit(
      items,
      (item) => {
        const match = dataFields.find((field) => {
          const value = field.getValue(item, this.state.drivers);
          return value.display.toLowerCase().indexOf(search) !== -1;
        });
        return match !== undefined;
      },
      this.state.showAllSearchResults ? 100000 : defaultSearchLimit
    );
  }

  isSearching(): boolean {
    return this.state.search !== "";
  }
  onScrollRenderChanged(params: ListOnItemsRenderedProps) {
    var topVisibleDate: Moment | null = null;

    console.log("onScrollRenderChanged", params);

    for (var i = params.visibleStartIndex; i < this.state.items.length; i++) {
      if (this.state.items[i].date !== null) {
        topVisibleDate = this.state.items[i].date;
        break;
      }
    }

    if (topVisibleDate === null) return;

    const month = topVisibleDate.format("MMMM YYYY");
    if (this.state.displayMonth === month) return;

    this.setState({
      displayMonth: month,
    });
  }

  renderInner() {
    if (this.state.loading) {
      return <Loading />;
    }

    return (
      <List
        ref={(r) => {
          this.listRef = r;
        }}
        isSearching={this.isSearching()}
        items={this.state.items}
        onScrollChange={this.onScrollRenderChanged}
        onClearSearch={this.clearSearch}
        onUpdateItemsFromRaw={this.updateItemsFromRaw}
      />
    );
  }

  clearSearch(then?: (items: Item[]) => void) {
    this.updateSearch("", then);
  }

  scheduleContainerStyle = { height: "100%", width: "100%" };

  render() {
    const isShowingAllResults =
      this.state.showAllSearchResults ||
      this.state.items.length < defaultSearchLimit;

    return (
      <UndoProvider>
        <PageContent
          title="Schedule Editor"
          noPadding
          centerContent={
            <PageHeaderCenter displayMonth={this.state.displayMonth} />
          }
          rightContent={
            <PageHeaderRight
              autoScrollTo={this.autoScrollTo}
              autoScrollToToday={this.autoScrollToToday}
              search={this.state.search}
              updateSearch={this.updateSearch}
            />
          }
        >
          <Grid
            container
            direction="column"
            style={this.scheduleContainerStyle}
            key="schedule-container"
          >
            <Grid key="list-cell" item xs style={{ width: "100%" }}>
              {this.state.error !== null && (
                <Typography color="error">{this.state.error}</Typography>
              )}
              <div style={{ textAlign: "center", backgroundColor: "#c9c9c9" }}>
                See <a href="/schedule-editor2">beta schedule editor</a>
              </div>
              <JobTypeProvider>
                <IsSearchingContext.Provider value={this.isSearching()}>
                  <DriverContext.Provider value={this.state.drivers}>
                    {this.renderInner()}
                  </DriverContext.Provider>
                </IsSearchingContext.Provider>
              </JobTypeProvider>
            </Grid>
            <Grid item>
              {this.isSearching() && !isShowingAllResults && (
                <Grid
                  key="search-button"
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Typography color="textSecondary">
                      Only showing {this.state.items.length} results
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() =>
                        setTimeout(() =>
                          this.setState({ showAllSearchResults: true }, () =>
                            this._updateSearchWithExpensiveStateChange(
                              this.state.search
                            )
                          )
                        )
                      }
                    >
                      Show All Results
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Footer key="footer" />
            </Grid>
          </Grid>
        </PageContent>
      </UndoProvider>
    );
  }
}
