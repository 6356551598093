import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";
import { grey } from "@material-ui/core/colors";
import React, { useState } from "react";
import Dropzone from "react-dropzone";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const dropZoneStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      padding: "15px",
      border: "2px solid " + grey["100"],
      borderRadius: "5px",
      textAlign: "center",
    },
    over: {
      borderColor: theme.palette.primary.main,
    },
    text: {
      width: "100%",
      textAlign: "center",
    },
  })
);

type DragState = "none" | "over";

export function DropZone(props: {
  accept?: string[] | string;
  onAdd(files: File[]): void;
}) {
  const classes = dropZoneStyles();
  const [dragState, setDragState] = useState<DragState>("none");

  return (
    <div style={{ width: "100%", padding: "10px 0" }}>
      <Dropzone
        accept={props.accept}
        onDrop={(acceptedFiles) => {
          setDragState("none");
          props.onAdd(acceptedFiles);
        }}
        onDragEnter={() => setDragState("over")}
        onDragLeave={() => setDragState("none")}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            className={
              classes.box + " " + (dragState === "none" ? "" : classes.over)
            }
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <Typography variant="caption" className={classes.text}>
              Drop or <Button>select</Button> files to upload
              {props.accept === ".pdf" ? " (PDF Only)" : ""}
            </Typography>
          </div>
        )}
      </Dropzone>
    </div>
  );
}
