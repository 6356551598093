import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";

export function useActionButtons(props: {
  onSave: () => Promise<any>;
  onCancel: () => void;
  saveText?: string;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);

  const Actions = () => (
    <Grid
      container
      direction="row"
      spacing={1}
      justifyContent="flex-end"
      wrap="nowrap"
    >
      <Grid item>
        {error !== null && <Typography color="error">{error}</Typography>}
        {loading && <CircularProgress />}
      </Grid>
      <Grid item>
        <Button disabled={loading} onClick={props.onCancel}>
          Cancel
        </Button>
      </Grid>
      <Grid item>
        <Button
          disabled={loading}
          onClick={async () => {
            try {
              setError(null);
              setLoading(true);
              await props.onSave();
            } catch (e: any) {
              setError(e.message);
            }

            setLoading(false);
          }}
        >
          {props.saveText ? props.saveText : "Save"}
        </Button>
      </Grid>
    </Grid>
  );

  return { Actions, loading };
}
