import { TextField } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";

export function TextInput(props: {
  label: string;
  value: string;
  onChange(value: string): void;
}) {
  const [value, setValue] = useState(props.value);
  const propValue = useRef(props.value);
  propValue.current = props.value;
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (focus) return;
    setValue(props.value);
  }, [props.value, focus]);

  useEffect(() => {
    if (propValue.current === value) return;

    const tm = setTimeout(() => {
      props.onChange(value);
    }, 500);

    return () => clearTimeout(tm);
  }, [value, props.onChange]);

  return (
    <TextField
      onFocus={() => setFocus(true)}
      onBlur={() => {
        props.onChange(value);
        setFocus(false);
      }}
      fullWidth
      label={props.label}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      variant="outlined"
      size="small"
    />
  );
}
