import React, { useEffect, useState } from "react";

export function Input(props: {
  number?: boolean;
  value: string;
  onChange(value: string): void;
}) {
  const [value, setValue] = useState(props.value);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (focused) return;
    setValue(props.value);
  }, [props.value, focused]);

  return (
    <div>
      <input
        type="text"
        style={{
          width: "100%",
          border: "none",
          padding: 8,
          paddingLeft: 16,
          paddingRight: 16,
          textAlign: props.number ? "right" : "left",
        }}
        value={value}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => setFocused(false)}
        onChange={(e) => {
          const v = e.currentTarget.value as string;
          setValue(v);
          props.onChange(v);
        }}
      />
    </div>
  );
}
