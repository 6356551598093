import * as React from "react";
import moment, { Duration } from "moment";
import { newline2br } from "../../components/newline2br";
import { DriverReportWithExtras } from "../../api/types";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { booleanToYesNo } from "../../components/util";
import { Grid, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { CompletedIcon } from "../../components/completed-icon";

type Props = {
  item: DriverReportWithExtras;
  onEditAdminNotes(): void;
};

type milliseconds = number;

export class ListItem extends React.PureComponent<Props> {
  formatHours(times: Date[] | null): string {
    if (times === null) return "";

    var out = [];
    const timeFormat = "h:mma";

    for (var i = 0; i < times.length; i += 2) {
      var list = [moment(times[i]).format(timeFormat)];
      if (i + 1 < times.length) {
        list.push(moment(times[i + 1]).format(timeFormat));
      }

      out.push(list.join(" - "));
    }

    return out.join("\n");
  }

  sumHourArray(list: Date[]): milliseconds {
    var total = 0;

    for (let i = 0; i < list.length - 1; i += 2) {
      total += list[i + 1].getTime() - list[i].getTime();
    }

    return total;
  }

  sumHours(): Duration {
    const down = this.sumHourArray(this.props.item.hoursDownTime || []);
    const onSite = this.sumHourArray(this.props.item.hoursOnSite || []);

    return moment.duration(down + onSite);
  }

  render() {
    const item = this.props.item;

    return (
      <TableRow>
        <TableCell style={{ whiteSpace: "nowrap" }}>
          {moment(item.scheduleItemLocalDate).utc().format("MMM-D")}
        </TableCell>
        <TableCell>{item.driverName}</TableCell>
        <TableCell>{item.vehicleName}</TableCell>
        <TableCell>{item.customer}</TableCell>
        <TableCell style={{ whiteSpace: "pre-wrap" }}>
          {item.contacts}
        </TableCell>
        <TableCell>{item.unitId}</TableCell>
        <TableCell style={{ whiteSpace: "nowrap" }}>
          <CompletedIcon
            status={item.completed ? "completed" : "in-progress"}
          />
        </TableCell>
        <TableCell style={{ whiteSpace: "nowrap" }}>
          {newline2br(this.formatHours(item.hoursOnSite))}
        </TableCell>
        <TableCell style={{ whiteSpace: "nowrap" }}>
          {newline2br(this.formatHours(item.hoursDownTime))}
        </TableCell>
        <TableCell style={{ whiteSpace: "nowrap" }}>
          {Math.round(this.sumHours().asHours() * 100) / 100}h
        </TableCell>
        <TableCell style={{ whiteSpace: "nowrap" }}>
          {booleanToYesNo(item.block)}
        </TableCell>
        <TableCell style={{ whiteSpace: "nowrap" }}>
          {booleanToYesNo(item.unblock)}
        </TableCell>
        <TableCell style={{ whiteSpace: "nowrap" }}>
          {item.changedTires}
        </TableCell>
        <TableCell style={{ whiteSpace: "nowrap" }}>
          {item.suppliedTires}
        </TableCell>
        <TableCell style={{ whiteSpace: "nowrap" }}>
          {item.clientReplacedTires}
        </TableCell>
        <TableCell>{newline2br(item.notes)}</TableCell>
        <TableCell>
          <Grid container alignItems="flex-start">
            <Grid
              item
              dangerouslySetInnerHTML={{ __html: item.adminNotes }}
              style={{ maxWidth: "300px", wordBreak: "break-all" }}
            />
            <Grid item>
              <IconButton onClick={this.props.onEditAdminNotes}>
                <EditIcon />
              </IconButton>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    );
  }
}
