import { stateManager } from "../../state/state-manager";

export type Command =
  | "next-cell"
  | "prev-cell"
  | "up-cell"
  | "down-cell"
  | "none";

export function processCommand(cmd: Command) {
  switch (cmd) {
    case "next-cell":
      stateManager.tabToNextCell(1);
      break;
    case "prev-cell":
      stateManager.tabToNextCell(-1);
      break;
    case "up-cell":
      stateManager.moveHighlightVertical(-1, "keyboard");
      stateManager.emitClick();
      break;
    case "down-cell":
      stateManager.moveHighlightVertical(1, "keyboard");
      stateManager.emitClick();
      break;
    case "none":
      stateManager.clearClickSelection();
      break;
  }
}
