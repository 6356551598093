import { Fetcher } from "./fetcher";
import {
  Paginated,
  ScheduleItem,
  ScheduleItemWithDriverReportInfo,
  ScheduleListResponse,
  User,
} from "./types";
import moment from "moment";

export class ScheduleAPI {
  fetcher: Fetcher;

  constructor(fetcher: Fetcher) {
    this.fetcher = fetcher;
  }

  list(date: Date) {
    return this.fetcher.getCacheable<ScheduleListResponse>(
      "/api/schedule/list",
      {
        date: moment(date).format("YYYY-MMM-DD"),
      }
    );
  }

  listByYear(year: number, excludeCurrent90DayWindow: boolean) {
    return this.fetcher.getCacheable<{
      list: ScheduleItemWithDriverReportInfo[];
    }>("/api/schedule/list", {
      year: year,
      excludeCurrent90DayWindow: true,
    });
  }

  getCurrentContext() {
    return this.fetcher.getOrThrow<{
      list: ScheduleItemWithDriverReportInfo[];
      drivers: User[];
      totalCountAfter: number;
      totalCountBefore: number;
    }>("/api/schedule/editor-current-context", {});
  }

  getEmails(input: { search: string }) {
    return this.fetcher.getOrThrow<string[]>(
      "/api/schedule/email-autocomplete",
      input
    );
  }

  listJobTypes() {
    return this.fetcher.getOrThrow<string[]>("/api/schedule/job-types");
  }

  updateJobTypes(list: string[]) {
    return this.fetcher.postOrThrow<ScheduleItemWithDriverReportInfo>(
      "/api/schedule/update-job-types",
      list
    );
  }

  listPaginate(params: { offset: number; limit: number }) {
    return this.fetcher.getCacheable<
      Paginated<ScheduleItemWithDriverReportInfo>
    >("/api/schedule/list", params);
  }

  updateField(data: {
    changes: {
      field: keyof ScheduleItem;
      value: string;
    }[];
    rowHeight: number;
    scheduleId: number;
  }): Promise<ScheduleItemWithDriverReportInfo> {
    return this.fetcher.postOrThrow<ScheduleItemWithDriverReportInfo>(
      "/api/schedule/update-field",
      data
    );
  }

  updateFiles(data: {
    schedule: number;
    toSignFiles: number[];
    backgroundFiles: number[];
    signedFiles: number[];
    notifyOnSignedEmailsCSV: string;
    notifyOnSignedJobName: string;
  }) {
    return this.fetcher.postOrThrow<ScheduleItemWithDriverReportInfo>(
      "/api/schedule/update-files",
      data
    );
  }

  newBlank(date: Date): Promise<ScheduleItem> {
    return this.fetcher.postOrThrow<ScheduleItem>(
      "/api/schedule/new-blank",
      date
    );
  }

  remove(id: number): Promise<{}> {
    return this.fetcher.postOrThrow<ScheduleItem>("/api/schedule/remove", id);
  }

  unremove(id: number): Promise<{}> {
    return this.fetcher.postOrThrow<ScheduleItem>(
      "/api/schedule/un-remove",
      id
    );
  }

  duplicate(id: number): Promise<ScheduleItem> {
    return this.fetcher.postOrThrow<ScheduleItem>(
      "/api/schedule/duplicate",
      id
    );
  }

  reOrder(params: ReOrderConfig): Promise<ScheduleItem> {
    return this.fetcher.postOrThrow<ScheduleItem>(
      "/api/schedule/re-order",
      params
    );
  }
}

export interface ReOrderConfig {
  scheduleId: number;
  newDate?: string;
  beforeScheduleId?: number;
  afterScheduleId?: number;
}
