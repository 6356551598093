import { useEffect, useRef } from "react";

var tStart: undefined | number;

export function perflog(message?: any, ...arg: any[]) {
  if (tStart === undefined) tStart = new Date().getTime();

  console.log(new Date().getTime() - tStart, message, ...arg);
}

export function resetperf() {
  tStart = undefined;
}

export function useTraceUpdate(name: string, props: Object) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {} as { [k: string]: any });

    console.log(`${name}: props '${Object.keys(changedProps).join(",")}'`);

    prev.current = props;
  });
}

type Object = { [k: string]: any };

export function traceComponentDidUpdate(params: {
  name: string;
  props: Object;
  state?: Object;
  prevProps: Object;
  prevState?: Object;
}) {
  const { props, state, prevProps, prevState, name } = params;
  var propsChanged: string[] = [];
  var stateChanged: string[] = [];

  Object.entries(props).forEach(
    ([key, val]) => prevProps[key] !== val && propsChanged.push(key)
  );
  if (state && prevState) {
    Object.entries(state).forEach(
      ([key, val]) => prevState[key] !== val && stateChanged.push(key)
    );
  }

  if (propsChanged.length > 0 || stateChanged.length > 0) {
    console.log(
      `${name}: props '${propsChanged.join(", ")}' state '${stateChanged.join(
        ", "
      )}'`
    );
  }
}

export function debug(message?: any, ...optionalParams: any[]) {
  console.log(message, optionalParams);
}
