import { FileWithSignedInfo } from "../../../../../api/files";
import React, { useCallback, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import TextField from "@material-ui/core/TextField";
import { KeyboardDatePicker } from "@material-ui/pickers";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

export function SignDocumentDetails(props: {
  file: File;
  hasMore: boolean;
  onCancel(): void;
  onSave(data: FileWithSignedInfo): void;
}) {
  const [signedAt, setSignedAt] = useState<Date>(new Date());
  const [signedBy, setSignedBy] = useState<string>("");
  const save = useCallback(
    () =>
      props.onSave({
        signedBy: signedBy,
        signedAt: signedAt,
        file: props.file,
      }),
    [props, signedAt, signedBy]
  );

  return (
    <Dialog open={true} onBackdropClick={props.onCancel}>
      <DialogTitle>Sign Details for {props.file.name}</DialogTitle>
      <List>
        <ListItem>
          <TextField
            label="Signed By"
            InputLabelProps={{ shrink: true }}
            value={signedBy}
            fullWidth
            onChange={(e) => setSignedBy(e.target.value)}
          />
        </ListItem>
        <ListItem>
          <KeyboardDatePicker
            label="Signed At"
            autoOk
            fullWidth
            variant="inline"
            format="yyyy/MM/dd"
            value={signedAt}
            onChange={(value) => {
              if (!value) return;
              setSignedAt(value);
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </ListItem>
      </List>
      <DialogActions>
        <Button onClick={props.onCancel}>Cancel</Button>
        <Button disabled={signedBy === ""} onClick={save}>
          {props.hasMore ? "Next" : "Done"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
