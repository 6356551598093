import { createStyles, withStyles, WithStyles } from "@material-ui/styles";
import * as React from "react";
import { DateRow } from "./date-row";
import { Moment } from "moment";
import { Theme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { StyledComponent } from "./boxes/styled-component";

const styles = (theme: Theme) =>
  createStyles({
    monthSeparator: {
      height: DateRow.height,
      backgroundColor: theme.palette.primary.dark,
      color: "white",
      verticalAlign: "middle",
      paddingLeft: theme.spacing(2),
    },
  });

type Props = WithStyles<typeof styles> & {
  date: Moment;
};

export const MonthSeparator: StyledComponent<Props> = withStyles(styles)(
  function (props: Props) {
    return (
      <td className={props.classes.monthSeparator}>
        <Typography variant="button">
          {props.date.format("MMMM YYYY")}
        </Typography>
      </td>
    );
  }
) as any;
