import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { api } from "../../../../../../api/api";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import GetAppIcon from "@material-ui/icons/GetApp";
import React from "react";
import { GooglePreview } from "./google-preview";
import { File as FileEntity } from "../../../../../../api/types";

export function FileAction(props: {
  id: number;
  fileName: string;
  file: FileEntity;
  extraAction?(file: FileEntity): JSX.Element | null;
  onArchive(): void;
}) {
  const isMedia = (function () {
    const name = props.fileName.toLowerCase();

    if (name.endsWith(".mp4")) return true;
    if (name.endsWith(".mov")) return true;
    if (name.endsWith(".jpg")) return true;
    if (name.endsWith(".jpeg")) return true;
    if (name.endsWith(".png")) return true;
    if (name.endsWith(".gif")) return true;
    return false;
  })();

  const isDoc = (function () {
    const name = props.fileName.toLowerCase();
    if (name.endsWith(".xlsx")) return true;
    if (name.endsWith(".xls")) return true;
    if (name.endsWith(".csv")) return true;
    if (name.endsWith(".doc")) return true;
    if (name.endsWith(".docx")) return true;
    if (name.endsWith(".txt")) return true;
    if (name.endsWith(".pdf")) return true;
    return false;
  })();

  return (
    <Grid direction="row" container spacing={0}>
      <IconButton onClick={props.onArchive}>
        <DeleteForeverIcon />
      </IconButton>
      {props.extraAction && props.extraAction(props.file)}
      {isMedia && (
        <IconButton onClick={() => api.files.view(props.id)}>
          <OpenInNewIcon />
        </IconButton>
      )}
      {isDoc && <GooglePreview {...props} />}
      <IconButton onClick={() => api.files.download(props.id)}>
        <GetAppIcon />
      </IconButton>
    </Grid>
  );
}
