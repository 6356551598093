import { auth } from "../core/authentication";
import { ErrResponse } from "./types";

export function makeQuery(props: { [k: string]: any }) {
  const search = new URLSearchParams();

  for (let i in props) {
    if (props[i] === null) {
      continue;
    }
    search.append(i, props[i].toString());
  }

  return search.toString();
}

export class Fetcher {
  defaultHeaders = {
    accept: "application/json",
  };

  updatePath(path: string): string {
    return "https://app.etrak.ca" + path;
    // return path;
  }

  async getOrThrow<T>(
    path: string,
    urlParameters?: { [k: string]: any }
  ): Promise<T> {
    path = this.updatePath(path);

    if (urlParameters) {
      path = path + "?" + makeQuery(urlParameters);
    }

    const result = await auth.fetch(path, {
      method: "GET",
      headers: this.defaultHeaders,
      cache: "no-cache",
    });

    return this.handleResponseOrThrow(result);
  }

  download(method: "POST", path: string, body: { [k: string]: string }) {
    path = this.updatePath(path);

    const form = document.createElement("form");
    form.method = method;
    form.action = path;
    form.style.display = "none";

    document.body.append(form);

    for (let i in body) {
      const input = document.createElement("input");
      input.name = i;
      input.value = body[i];
      form.appendChild(input);
    }

    form.submit();
  }

  async postFormData<T>(
    path: string,
    body: { [k: string]: string | Blob }
  ): Promise<T | ErrResponse> {
    path = this.updatePath(path);

    const fd = new FormData();
    for (var i in body) {
      fd.append(i, body[i]);
    }

    const result = await auth.fetch(path, {
      method: "POST",
      headers: this.defaultHeaders,
      credentials: "include",
      cache: "no-cache",
      body: fd,
    });

    return this.handleResponse<T>(result);
  }

  async postFormDataOrThrow<T>(
    path: string,
    body: { [k: string]: string | Blob }
  ): Promise<T> {
    path = this.updatePath(path);

    const fd = new FormData();
    for (var i in body) {
      fd.append(i, body[i]);
    }

    const result = await auth.fetch(path, {
      method: "POST",
      headers: this.defaultHeaders,
      credentials: "include",
      cache: "no-cache",
      body: fd,
    });

    return this.handleResponseOrThrow<T>(result);
  }

  async postOrThrow<T>(path: string, body: any): Promise<T> {
    path = this.updatePath(path);

    const result = await auth.fetch(path, {
      method: "POST",
      headers: this.defaultHeaders,
      credentials: "include",
      cache: "no-cache",
      body: JSON.stringify(body),
    });

    return this.handleResponseOrThrow<T>(result);
  }

  async post<T>(path: string, body: any): Promise<T | ErrResponse> {
    path = this.updatePath(path);

    const result = await auth.fetch(path, {
      method: "POST",
      headers: this.defaultHeaders,
      credentials: "include",
      cache: "no-cache",
      body: JSON.stringify(body),
    });

    return this.handleResponse<T>(result);
  }

  async publicPost<T>(path: string, body: any): Promise<T | ErrResponse> {
    path = this.updatePath(path);

    const result = await fetch(path, {
      method: "POST",
      headers: this.defaultHeaders,
      credentials: "include",
      cache: "no-cache",
      body: JSON.stringify(body),
    });

    return this.handleResponse<T>(result);
  }

  async put<T>(path: string, body: any): Promise<T | ErrResponse> {
    path = this.updatePath(path);

    const result = await auth.fetch(path, {
      method: "PUT",
      headers: this.defaultHeaders,
      credentials: "include",
      cache: "no-cache",
      body: JSON.stringify(body),
    });

    return this.handleResponse<T>(result);
  }

  async putOrThrow<T>(path: string, body: any): Promise<T | ErrResponse> {
    path = this.updatePath(path);

    const result = await auth.fetch(path, {
      method: "PUT",
      headers: this.defaultHeaders,
      credentials: "include",
      cache: "no-cache",
      body: JSON.stringify(body),
    });

    return this.handleResponseOrThrow<T>(result);
  }

  async get<T>(
    path: string,
    urlParameters?: { [k: string]: any }
  ): Promise<T | ErrResponse> {
    path = this.updatePath(path);

    if (urlParameters) {
      path = path + "?" + makeQuery(urlParameters);
    }

    const result = await auth.fetch(path, {
      method: "GET",
      headers: this.defaultHeaders,
      cache: "no-cache",
    });

    return this.handleResponse<T>(result);
  }

  async getCacheable<T>(
    path: string,
    urlParameters?: { [k: string]: any }
  ): Promise<T> {
    path = this.updatePath(path);

    if (urlParameters) {
      path = path + "?" + makeQuery(urlParameters);
    }

    const result = await auth.fetch(path, {
      method: "GET",
      headers: this.defaultHeaders,
    });

    return this.handleResponseOrThrow<T>(result);
  }

  async handleResponseOrThrow<T>(result: Response): Promise<T> {
    const contentType = result.headers.get("Content-Type");
    if (contentType && contentType.indexOf("json") === -1) {
      if (result.status === 404) {
        throw new Error("Not found");
      }
    }

    const jsonData = await result.json();

    // throw for app errors
    if (jsonData && typeof jsonData === "object" && "error" in jsonData) {
      throw new Error(jsonData.error);
    }

    if (!result.ok) {
      throw new Error(jsonData);
    }

    return jsonData as T;
  }

  async handleResponse<T>(result: Response): Promise<T | ErrResponse> {
    const contentType = result.headers.get("Content-Type");
    if (contentType && contentType.indexOf("json") === -1) {
      if (result.status === 404) {
        throw new Error("Not found");
      }
    }

    const jsonData = await result.json();
    if (jsonData && jsonData.error) {
      return jsonData;
    }

    if (!result.ok) {
      throw new Error(jsonData);
    }

    return jsonData as T;
  }
}
