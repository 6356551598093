import * as React from "react";
import { Grid } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import { List } from "./driver-reports/list";
import RefreshIcon from "@material-ui/icons/Refresh";
import {
  DriverReportWithExtras,
  DriverReportWithExtrasResponse,
} from "../api/types";
import { api } from "../api/api";
import { PageContent } from "../components/page-content";
import IconButton from "@material-ui/core/IconButton";
import { RoutePropsWithID } from "../nav";
import moment from "moment";
import { RefObject } from "react";
import { AppBarSearch } from "../components/app-bar-search";
import { WeekOfFilter } from "./driver-reports/week-of";
import { DriverFilter } from "./driver-reports/driverFilter";

type Props = RoutePropsWithID & {};

interface State {
  driverReportId: number | null;
  driverFilter: string;
  driversForFilter: string[];
  weekFilter: Date;
  list: DriverReportWithExtras[];
  search: string;
  error?: string;
  loading: boolean;
}

const allDrivers = "All Drivers";

export class DriverReports extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      driverReportId: null,
      driverFilter: allDrivers,
      driversForFilter: [allDrivers],
      weekFilter: new Date(),
      list: [],
      error: undefined,
      loading: false,
      search: "",
    };
  }

  componentWillMount(): void {
    this.fetch();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetch();
    }
  }

  transformReport(
    item: DriverReportWithExtrasResponse
  ): DriverReportWithExtras {
    return Object.assign(item, {
      customer: item.customer,
      unitId: item.unitId,
      contacts: item.contacts,
      hoursOnSite: (item.hoursOnSite || []).map((time) => new Date(time)),
      hoursDownTime: (item.hoursDownTime || []).map((time) => new Date(time)),
      scheduleItemLocalDate: new Date(item.scheduleItemLocalDate),
    });
  }

  async fetch() {
    const id = this.props.match.params.id;
    if (id === undefined) {
      return this.fetchList();
    }

    return this.fetchSingle(parseInt(id, 10));
  }

  async fetchSingle(id: number) {
    try {
      this.setState({
        driverReportId: id,
        loading: true,
        error: undefined,
      });

      const response = await api.driverReport.get(id);
      if ("error" in response) {
        throw new Error(response.error);
      }

      this.setState({
        list: [this.transformReport(response)],
        loading: false,
      });
    } catch (e: any) {
      this.setState({
        loading: false,
        error: e.message,
      });
    }
  }

  isSingleReport() {
    return this.state.driverReportId !== null;
  }

  fetchIndex: number = 0;

  async fetchList() {
    try {
      this.fetchIndex++;
      const id = this.fetchIndex;

      this.setState({
        loading: true,
        error: undefined,
        driverReportId: null,
      });

      const response = await (!!this.state.search
        ? api.driverReport.search(this.state.search)
        : api.driverReport.list(this.state.weekFilter));

      if (id !== this.fetchIndex) {
        return; // another request started while this one was happening
      }

      if (response === null) {
        this.setState({
          list: [],
          loading: false,
        });

        return;
      }

      if ("error" in response) {
        throw new Error(response.error);
      }

      const list = response.map((item) => this.transformReport(item));

      list.sort((a, b) => {
        return (
          a.scheduleItemLocalDate.getTime() - b.scheduleItemLocalDate.getTime()
        );
      });

      this.setState({
        list: list,
        driversForFilter: this.uniqueDrivers(list),
        loading: false,
      });
    } catch (e: any) {
      this.setState({
        loading: false,
        error: e.message,
      });
    }
  }

  uniqueDrivers(list: DriverReportWithExtras[]): string[] {
    const drivers = {};

    list.map((item) => {
      // @ts-ignore
      drivers[item.driverName] = true;
      return null;
    });

    return Object.keys(drivers);
  }

  makeFileName() {
    const date = moment(this.state.weekFilter).format("MMM-D");

    if (this.state.driverFilter === allDrivers) {
      return "all-drivers-week-of-" + date;
    }

    return this.state.driverFilter.toLowerCase() + "-week-of-" + date;
  }

  driverFilterButton: RefObject<HTMLButtonElement> = React.createRef();

  renderRight() {
    const isSearching = !!this.state.search;

    return (
      <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
        {!isSearching && (
          <Grid item key="week">
            <WeekOfFilter
              value={this.state.weekFilter}
              onChange={(date) =>
                this.setState({ weekFilter: date }, () => this.fetchList())
              }
            />
          </Grid>
        )}
        {!isSearching && (
          <Grid item key="driver">
            <DriverFilter
              value={this.state.driverFilter}
              drivers={this.state.driversForFilter}
              onClear={() =>
                this.setState({
                  driverFilter: allDrivers,
                })
              }
              onChange={(driver) =>
                this.setState({
                  driverFilter: driver,
                })
              }
            />
          </Grid>
        )}
        <Grid item key="search">
          <AppBarSearch
            search={this.state.search}
            onChange={(search) =>
              this.setState({ search: search }, () => this.fetchList())
            }
          />
        </Grid>
        {!isSearching && (
          <Grid item key="buttons">
            <IconButton
              color="inherit"
              aria-label="Download"
              onClick={() =>
                api.driverReport.download(
                  this.getList().map((item) => item.id),
                  this.makeFileName()
                )
              }
            >
              <DownloadIcon />
            </IconButton>
            <IconButton
              color="inherit"
              aria-label="Refresh"
              onClick={() => this.fetchList()}
            >
              <RefreshIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    );
  }

  getList() {
    const driver = this.state.driverFilter;
    if (driver === allDrivers) {
      return this.state.list;
    }

    return this.state.list.filter((item) => item.driverName === driver);
  }

  render() {
    return (
      <PageContent title="Driver Reports" rightContent={this.renderRight()}>
        <Grid
          container
          direction="column"
          spacing={2}
          style={{ height: "100%", flexWrap: "nowrap" }}
        >
          <Grid item xs>
            <List
              isSingleReport={this.isSingleReport()}
              list={this.getList()}
              loading={this.state.loading}
              error={this.state.error}
              onUpdate={(data) => {
                const i = this.state.list.findIndex(
                  (item) => item.id === data.id
                );
                if (i === -1) {
                  console.warn("missing list item, that's weird");
                  return;
                }

                const list = this.state.list.slice(0);
                list[i] = this.transformReport(data);

                this.setState({
                  list: list,
                });
              }}
            />
          </Grid>
        </Grid>
      </PageContent>
    );
  }
}
