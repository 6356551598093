import { EventEmitter } from "../../../misc/event-emitter";
import { showSnack } from "../../../components/snacker";
import { footer } from "../layout/footer";
import {
  ScheduleRemoveAction,
  ScheduleReOrderAction,
  ScheduleUpdateAction,
  SelectCellAction,
  WebSocketInteraction,
} from "../../../core/websocket";
import { StatefulEventEmitter } from "../../../misc/stateful-event-emitter";
import { api } from "../../../api/api";
import { fields, fieldValuesForIndexRange, isDataField } from "./field";
import {
  _ScheduleItemWithDriverReportInfo,
  ScheduleItemWithDriverReportInfo,
} from "../../../api/types";
import { DefaultEditorClass } from "../layout/cell-editor/default-editor";
import { heightCalculator } from "../utils/height-calculator";
import { RunWithTracking } from "../utils/undo";

export type HighlightParams =
  | {
      type: "weekly-note";
      weeklyNoteSunday: string;
      source: EtrakEventSource;
    }
  | {
      type: "schedule";
      scheduleId: number;
      leftCell: number;
      rightCell: number;
      centerCells: number[]; // including end cells
      source: EtrakEventSource;
    };

export type EtrakEventSource = "keyboard" | "mouse";

export type ClickParams =
  | {
      type: "weekly-note";
      sunday: string;
    }
  | {
      type: "schedule";
      scheduleId: number;
      cell: number;
    };

export type ExternalHighlight = HighlightParams & {
  userName: string;
};

// a hidden textarea that we can use for copy/paste handling because
// we can't directly access the clipboard for copy/paste events.
// Basically, we keep this focused and watch for text events on it.
const copyPasteTextArea = (function () {
  var txt = document.createElement("textarea");
  txt.style.position = "absolute";
  txt.style.left = "-1000px";
  txt.style.top = "0";
  document.body.appendChild(txt);
  return txt;
})();

const deselectClick: ClickParams = {
  type: "schedule",
  scheduleId: -1,
  cell: 0,
};

export interface PasteParams {
  promise: Promise<any>;
  indexes: number[];
  scheduleId: number;
}

const rightMostSelectableCellIndex = fields.length - 1;
const leftMostSelectableCellIndex = 0;

export class StateManager {
  ws: WebSocketInteraction = new WebSocketInteraction();

  mouseUpListener = () => this.onMouseUp();
  mouseMoveListener = (event: MouseEvent) => {
    // detect drag out of window
    event = event || window.event;

    if (event.buttons !== 1) {
      this.onMouseUp();
    }
  };

  active: boolean = false;

  lastClickScheduleId: number = 0;
  lastClickIndex: number = 0;

  mouseDownIndex: number = 0;
  mouseDownScheduleId: number = 0;
  lastMouseOverIndex: number = 0;

  currentHighlight: HighlightParams | null = null;
  selectedData: { [index: number]: string | null } = {};

  highlightEvents = new StatefulEventEmitter<HighlightParams>();
  externalHighlightEvents = new StatefulEventEmitter<ExternalHighlight[]>();
  clickEvents = new StatefulEventEmitter<ClickParams>();
  pasteEvents = new EventEmitter<PasteParams>();
  scheduleItemUpdateEvents = new EventEmitter<void>();

  externalSelections: { [sessionId: number]: ExternalHighlight } = {};

  rawScheduleItems: _ScheduleItemWithDriverReportInfo[] = [];
  currentEditor: DefaultEditorClass | null = null;

  runWithTracking: RunWithTracking = (obj) => obj.run();

  constructor() {
    copyPasteTextArea.onpaste = (ev: ClipboardEvent) => this.onPaste(ev);
    copyPasteTextArea.onkeydown = (ev: KeyboardEvent) => this.onKeyDown(ev);

    this.highlightEvents.subscribe((params) =>
      console.log("highlight", params)
    );
    this.externalHighlightEvents.subscribe((params) =>
      console.log("external-highlight", params)
    );
    this.clickEvents.subscribe((params) => console.log("click", params));

    document.body.addEventListener(
      "keydown",
      (e) => this.onBodyKeyDown(e),
      false
    );

    this.ws.externalHighlightEvents.subscribe((params) =>
      this.onOutsideHighlight(params)
    );
    this.ws.externalScheduleUpdateEvents.subscribe((params) =>
      this.onOutsideChange(params)
    );
    this.ws.externalScheduleRemovedEvents.subscribe((params) =>
      this.onOutsideRemoval(params)
    );
    this.ws.externalScheduleReOrderEvents.subscribe((params) =>
      this.onOutsideReOrder(params)
    );
  }

  moveHighlightHorizontal(direction: -1 | 1, source: EtrakEventSource) {
    const newIndex = this.nextSelectIndex(direction);
    if (newIndex === null) return;

    if (
      newIndex > rightMostSelectableCellIndex ||
      newIndex < leftMostSelectableCellIndex
    ) {
      console.log("can't go off the ends");
      return;
    }

    if (this.isExternallySelected(newIndex, this.mouseDownScheduleId)) {
      console.log("ignored because externally selected");
      return;
    }

    this.mouseDownIndex = newIndex;
    this.lastMouseOverIndex = newIndex;

    this.onSelectionChanged();
    this.emitScheduleHighlight(newIndex, source);
  }

  indexOfScheduleId(id: number): number {
    for (var i = 0; i < this.rawScheduleItems.length; i++) {
      if (this.rawScheduleItems[i].id === id) {
        return i;
      }
    }

    return -1;
  }

  getScheduleItem(id: number) {
    for (let i = 0; i < this.rawScheduleItems.length; i++) {
      if (this.rawScheduleItems[i].id === id) {
        return this.rawScheduleItems[i];
      }
    }

    throw new Error("can't find schedule item");
  }

  moveHighlightVertical(direction: -1 | 1, source: EtrakEventSource) {
    const i = this.indexOfScheduleId(this.mouseDownScheduleId);
    if (i === -1) return;

    const newIndex = i + direction;

    if (newIndex < 0 || newIndex >= this.rawScheduleItems.length) {
      console.log("can't go off the end");
      return;
    }

    const newScheduleId = this.rawScheduleItems[newIndex].id;

    if (this.isExternallySelected(this.mouseDownIndex, newScheduleId)) {
      console.log("ignored because externally selected");
      return;
    }

    this.mouseDownScheduleId = newScheduleId;

    this.onSelectionChanged();
    this.emitScheduleHighlight(this.mouseDownIndex, source);
  }

  onKeyDown(ev: KeyboardEvent) {
    switch (ev.key) {
      case "ArrowRight":
        this.moveHighlightHorizontal(1, "keyboard");
        break;
      case "ArrowLeft":
        this.moveHighlightHorizontal(-1, "keyboard");
        break;
      case "ArrowUp":
        this.moveHighlightVertical(-1, "keyboard");
        break;
      case "ArrowDown":
        this.moveHighlightVertical(1, "keyboard");
        break;
      case "Tab":
        ev.preventDefault();
        if (ev.shiftKey) {
          this.moveHighlightHorizontal(-1, "keyboard");
        } else {
          this.moveHighlightHorizontal(1, "keyboard");
        }
        break;
      case "Enter":
        this.emitClick();
        break;
      case "Backspace":
        this.clearHighlightedCells();
        break;
      default:
        console.log(ev.key);
        break;
    }
  }

  async clearHighlightedCells() {
    if (this.currentHighlight === null) return;

    const highlight = this.currentHighlight;

    if (highlight.type !== "schedule") return; // only applies to schedule-type

    const nCells =
      Math.max(highlight.rightCell, highlight.leftCell) -
      Math.min(highlight.rightCell, highlight.leftCell) +
      1;
    const source: string[] = [];
    for (var i = 0; i < nCells; i++) {
      source.push("");
    }

    const fields = fieldValuesForIndexRange(
      highlight.leftCell,
      highlight.rightCell,
      source
    );

    const item = this.getScheduleItem(highlight.scheduleId);
    const oldHeight = item.rowHeight;
    const oldFieldValues = fields.map((f) => ({
      field: f.field,
      value: stringifyFieldValue(item, f.field),
    }));

    const height = await heightCalculator.calculateWithChanges(item, fields);

    const updatePromise = this.runWithTracking({
      run: () =>
        api.schedule.updateField({
          changes: fields,
          scheduleId: highlight.scheduleId,
          rowHeight: height,
        }),
      undo: () =>
        api.schedule.updateField({
          changes: oldFieldValues,
          scheduleId: highlight.scheduleId,
          rowHeight: oldHeight,
        }),
    });

    this.pasteEvents.emit({
      promise: updatePromise,
      indexes: highlight.centerCells,
      scheduleId: this.lastClickScheduleId,
    });

    try {
      const updated = await updatePromise;
      this.updateOrAddScheduleItem(updated);
      showSnack("Saved cells");
    } catch (e: any) {
      showSnack("Failed to update: " + e.message);
      console.error(e);
      return;
    }
  }

  onOutsideRemoval(params: ScheduleRemoveAction) {
    this.rawScheduleItems = this.rawScheduleItems.filter(
      (id) => id.id !== params.removeSchedule
    );
    this.scheduleItemUpdateEvents.emit();
  }

  toInternalDriverReport(value: ScheduleItemWithDriverReportInfo) {
    let v = value as _ScheduleItemWithDriverReportInfo;
    v.parsedLocalDate = new Date(v.localDate).getTime();
    return v;
  }

  updateOrAddScheduleItem(item: ScheduleItemWithDriverReportInfo) {
    var updatedExisting = false;
    const internal = this.toInternalDriverReport(item);

    for (var i = 0; i < this.rawScheduleItems.length; i++) {
      if (this.rawScheduleItems[i].id === internal.id) {
        this.rawScheduleItems[i] = internal;
        updatedExisting = true;
        break;
      }
    }

    if (!updatedExisting) this.rawScheduleItems.push(internal);

    this.scheduleItemUpdateEvents.emit();
  }

  onOutsideChange(params: ScheduleUpdateAction) {
    this.updateOrAddScheduleItem(params.editSchedule.scheduleItem);
  }

  onOutsideReOrder(params: ScheduleReOrderAction) {
    const orderUpdates = params.reOrderSchedule.orderUpdates;
    const dateUpdates = params.reOrderSchedule.dateUpdates;

    for (var i = 0; i < this.rawScheduleItems.length; i++) {
      const id = this.rawScheduleItems[i].id;

      if (orderUpdates.hasOwnProperty(id)) {
        this.rawScheduleItems[i].adminSortOrder = orderUpdates[id];
      }

      if (dateUpdates.hasOwnProperty(id)) {
        this.rawScheduleItems[i].localDate = dateUpdates[id];
        this.rawScheduleItems[i].parsedLocalDate = new Date(
          dateUpdates[id]
        ).getTime();
      }
    }

    this.scheduleItemUpdateEvents.emit();
  }

  onBodyKeyDown(e: KeyboardEvent) {
    if (e.key === "Escape") {
      this.currentEditor?.onEscapePressed();
      this.clearClickSelection();
    }
  }

  clearClickSelection() {
    this.clickEvents.emit(deselectClick);
    this.onSelectionChanged();
  }

  onOutsideHighlight(param: SelectCellAction) {
    let data: ExternalHighlight;

    const selected = param.selectCell;
    if (selected.type === "schedule") {
      data = {
        type: "schedule",
        scheduleId: selected.scheduleId,
        leftCell: selected.selectIndexStart,
        rightCell: selected.selectIndexEnd,
        centerCells: this.centerCellIndexes(
          selected.selectIndexStart,
          selected.selectIndexEnd
        ),
        userName: param.sender.name,
        source: param.source,
      };
    } else {
      data = {
        type: "weekly-note",
        weeklyNoteSunday: selected.weeklyNoteSunday,
        userName: param.sender.name,
        source: param.source,
      };
    }

    this.externalSelections[param.sender.sessionId] = data;
    this.externalHighlightEvents.emit(Object.values(this.externalSelections));
  }

  async onPaste(ev: ClipboardEvent) {
    var data = ev.clipboardData;
    if (!data) {
      showSnack("Empty clipboard :(");
      return;
    }

    const text = data.getData("text");
    const cells = text.split("\t");

    var start = this.lastClickIndex;
    const fields = fieldValuesForIndexRange(
      start,
      start + cells.length - 1,
      cells
    );

    const item = this.getScheduleItem(this.lastClickScheduleId);
    const oldHeight = item.rowHeight;
    const height = await heightCalculator.calculateWithChanges(item, fields);
    const oldValues = fields.map((f) => ({
      field: f.field,
      value: stringifyFieldValue(item, f.field),
    }));

    const updatePromise = this.runWithTracking({
      run: () =>
        api.schedule.updateField({
          changes: fields,
          scheduleId: this.mouseDownScheduleId,
          rowHeight: height,
        }),
      undo: () =>
        api.schedule.updateField({
          changes: oldValues,
          scheduleId: this.mouseDownScheduleId,
          rowHeight: oldHeight,
        }),
    });

    this.pasteEvents.emit({
      promise: updatePromise,
      indexes: this.centerCellIndexes(
        this.lastClickIndex,
        this.lastClickIndex + cells.length - 1
      ),
      scheduleId: this.lastClickScheduleId,
    });

    try {
      const updated = await updatePromise;
      this.updateOrAddScheduleItem(updated);
      showSnack("Saved cells");
    } catch (e: any) {
      showSnack("Failed to update: " + e.message);
      console.error(e.message);
      return;
    }
  }

  externalSelectionsForScheduleId(scheduleId: number): ExternalHighlight[] {
    let list: ExternalHighlight[] = [];

    for (let k in this.externalSelections) {
      const selection = this.externalSelections[k];
      if (selection.type === "schedule") {
        if (selection.scheduleId === scheduleId) {
          list.push(selection);
        }
      }
    }

    return list;
  }

  isExternallySelected(index: number, scheduleId: number) {
    const overlaps = this.externalSelectionsForScheduleId(scheduleId).filter(
      (selection) => {
        if (selection.type !== "schedule") return false;
        return selection.centerCells.indexOf(index) !== -1;
      }
    );

    return overlaps.length > 0;
  }

  onMouseDown(index: number | undefined, scheduleId: number | undefined): void {
    if (index === undefined || scheduleId === undefined) return;

    document.body.addEventListener("mouseup", this.mouseUpListener, false);
    document.body.addEventListener("mousemove", this.mouseMoveListener, false);

    if (this.isExternallySelected(index, scheduleId)) {
      console.log("ignored because externally selected");
      return;
    }

    this.active = true;
    this.mouseDownIndex = index;
    this.mouseDownScheduleId = scheduleId;

    this.emitScheduleHighlight(index, "mouse");
  }

  onMouseOver(index: number | undefined, scheduleId: number | undefined): void {
    if (scheduleId === undefined || index === undefined) return;
    if (!this.active) return;

    this.emitScheduleHighlight(index, "mouse");
  }

  emitScheduleHighlight(index: number, source: EtrakEventSource) {
    // clear selectedData so it can be repopulated by event listeners
    this.selectedData = {};

    const left = Math.min(this.mouseDownIndex, index);
    const right = Math.max(this.mouseDownIndex, index);
    const centerCells = this.centerCellIndexes(left, right);

    const externalSelections = this.externalSelectionsForScheduleId(
      this.mouseDownScheduleId
    );
    const overlappingSelections = externalSelections.filter((selection) => {
      if (selection.type !== "schedule") return false;
      const commonCells = selection.centerCells.filter(
        (cell) => centerCells.indexOf(cell) !== -1
      );
      return commonCells.length > 0;
    });

    if (overlappingSelections.length > 0) {
      console.log("has overlap, ignoring");
      return; // ignore update
    }

    this.currentHighlight = {
      type: "schedule",
      scheduleId: this.mouseDownScheduleId,
      leftCell: left,
      rightCell: right,
      centerCells: centerCells,
      source: source,
    };

    this.highlightEvents.emit(this.currentHighlight);
    this.lastMouseOverIndex = index;
  }

  centerCellIndexes(left: number, right: number): number[] {
    var list = [];

    for (var i = left; i <= right; i++) {
      list.push(i);
    }

    return list;
  }

  updateCopyPaste() {
    copyPasteTextArea.value = Object.keys(this.selectedData)
      .map((key: any) => {
        if (this.selectedData[key] === null) return "";
        return this.selectedData[key];
      })
      .join("\t");

    this.reFocusCopyPaste();
  }

  reFocusCopyPaste() {
    copyPasteTextArea.focus();
    copyPasteTextArea.setSelectionRange(
      0,
      copyPasteTextArea.value.length,
      "forward"
    );
  }

  onMouseUp(): void {
    this.active = false;

    document.body.removeEventListener("mouseup", this.mouseUpListener);
    document.body.removeEventListener("mousemove", this.mouseMoveListener);

    if (this.currentHighlight === null) return; // must have been a click

    this.onSelectionChanged();
  }

  nextSelectIndex(direction: -1 | 1): number | null {
    var newIndex = this.mouseDownIndex + direction;

    if (newIndex > rightMostSelectableCellIndex) {
      console.log("can't go off the ends");
      return null;
    }

    while (!isDataField(fields[newIndex])) {
      newIndex += direction;

      if (
        newIndex > rightMostSelectableCellIndex ||
        newIndex < leftMostSelectableCellIndex
      ) {
        console.log("can't go off the ends");
        return null;
      }
    }

    if (
      newIndex > rightMostSelectableCellIndex ||
      newIndex < leftMostSelectableCellIndex
    ) {
      console.log("can't go off the ends");
      return null;
    }

    return newIndex;
  }

  tabToNextCell(direction: 1 | -1) {
    const nextIndex = this.nextSelectIndex(direction);
    if (nextIndex === null) {
      this.clearClickSelection();
      return;
    }

    if (this.isExternallySelected(nextIndex, this.mouseDownScheduleId)) {
      console.log("ignored because externally selected");
      return;
    }

    this.mouseDownIndex = nextIndex;

    this.onSelectionChanged();
    this.emitScheduleHighlight(nextIndex, "keyboard");

    this.emitClick();
  }

  onClick(
    e: MouseEvent,
    index: number | undefined,
    scheduleId: number | undefined,
    oneClickToEdit: boolean = false
  ): void {
    if (index === undefined || scheduleId === undefined) return;

    if (
      e.shiftKey &&
      scheduleId === this.lastClickScheduleId &&
      index !== this.lastClickIndex
    ) {
      this.mouseDownIndex = this.lastClickIndex;
      this.mouseDownScheduleId = this.lastClickScheduleId;

      this.emitScheduleHighlight(index, "mouse");
      this.onSelectionChanged();
      return;
    }

    if (this.isExternallySelected(index, scheduleId)) {
      console.log("ignored because externally selected");
      return;
    }

    if (
      this.lastClickIndex === index &&
      this.lastClickScheduleId === scheduleId
    ) {
      this.emitClick();
      return;
    }

    this.mouseDownIndex = index;
    this.mouseDownScheduleId = scheduleId;

    this.lastClickIndex = index;
    this.lastClickScheduleId = scheduleId;

    this.onSelectionChanged();
    this.clickEvents.clearCurrentState();

    if (oneClickToEdit) {
      this.emitClick();
    }
  }

  emitClick() {
    if (this.currentHighlight) {
      if (this.currentHighlight.type === "schedule") {
        this.clickEvents.emit({
          type: "schedule",
          scheduleId: this.mouseDownScheduleId,
          cell: this.mouseDownIndex,
        });
      } else {
        this.clickEvents.emit({
          type: "weekly-note",
          sunday: this.currentHighlight.weeklyNoteSunday,
        });
      }
    }

    this.setCanDoActions(false);
    this.notifyWS();
  }

  onSelectionChanged() {
    this.updateCopyPaste();
    this.setCanDoActions(Object.keys(this.selectedData).length > 0);
    this.notifyWS();
  }

  setCanDoActions(tf: boolean) {
    if (footer !== null) {
      footer.setCanManipulate(tf);
    }
  }

  notifyWS() {
    if (this.currentHighlight) {
      const highlight = this.currentHighlight;
      if (highlight.type === "weekly-note") {
        this.ws.selectedCell({
          type: "weekly-note",
          weeklyNoteSunday: highlight.weeklyNoteSunday,
        });

        return;
      }
    }

    this.ws.selectedCell({
      type: "schedule",
      scheduleId: this.mouseDownScheduleId,
      selectIndexStart: Math.min(this.mouseDownIndex, this.lastMouseOverIndex),
      selectIndexEnd: Math.max(this.mouseDownIndex, this.lastMouseOverIndex),
    });
  }

  highlightIndexToConfigColumnIndex(index: number): number {
    return index;
  }
}

export function stringifyFieldValue(
  item: ScheduleItemWithDriverReportInfo,
  key: keyof ScheduleItemWithDriverReportInfo
): string {
  switch (key) {
    case "documentsToSign":
    case "signedDocuments":
    case "backgroundDocuments":
      return "";
  }

  const value = item[key];

  if (value === null || value === undefined) return "";
  if (value instanceof Array)
    return "<p>" + value.map((v) => v.description).join("</p><p>") + "</p>";
  return value.toString();
}

export const stateManager = new StateManager();
