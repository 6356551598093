import { Fetcher } from "./fetcher";
import { DateString, DriverReportResponse, ScheduleItem } from "./types";

export class InvoiceAPI {
  fetcher: Fetcher;

  constructor(fetcher: Fetcher) {
    this.fetcher = fetcher;
  }

  archive(input: { id: number }) {
    return this.fetcher.postOrThrow("/api/invoices/archive", input);
  }

  refresh() {
    return this.fetcher.postOrThrow("/api/invoices/refresh", {});
  }

  status() {
    return this.fetcher.getOrThrow<{
      pending: number;
      pendingQuickbooks: number;
    }>("/api/invoices/status");
  }

  quickbooksInvoiceLineTypes() {
    return this.fetcher.getOrThrow<{ id: number; name: string }[]>(
      "/api/invoices/quickbooks/invoice-line-types",
      {}
    );
  }

  listPending() {
    return this.fetcher.getOrThrow<Invoice[]>("/api/invoices/pending");
  }

  get(input: { id: number }) {
    return this.fetcher.getOrThrow<Invoice>("/api/invoice", input);
  }

  save(input: Invoice) {
    return this.fetcher.postOrThrow("/api/invoice", input);
  }

  listCustomers(props: { search: string; id?: number }) {
    return this.fetcher.getOrThrow<Customer[]>(
      "/api/invoices/qb-customer-map",
      props
    );
  }
}

type Customer = {
  qbCustomer: number;
  commonName: string;
};

export type Invoice = {
  id: number;
  poNumber: string;
  orderNumber: string;
  customerName: string;
  customer?: number;
  project: string;
  submittedToQuickbooks: boolean;
  date: DateString;

  invoiceItems: InvoiceItem[];
  scheduleItems: ScheduleItem[];
  driverReports: DriverReportResponse[];
};

export type InvoiceItem = {
  id: number;
  invoice: number;
  qbItemId: number;
  description: string;
  quantity: number;
  unit: string;
  rate: number;
  amount: number;
};
