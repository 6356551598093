import { Dialog } from "@material-ui/core";
import * as React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import { api } from "../../api/api";
import { showSnack } from "../../components/snacker";

interface Props {
  userId: number;
  name: string;
  open: boolean;
  onDone(): void;
}

interface State {
  password: string;
  loading: boolean;
  error?: string;
}

export class SetPasswordDialog extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      password: "",
      loading: false,
      error: undefined,
    };
  }

  async setPassword() {
    try {
      this.setState({
        error: undefined,
        loading: true,
      });

      await api.users.setPassword({
        id: this.props.userId,
        newPassword: this.state.password,
      });

      this.setState({
        loading: false,
      });

      this.props.onDone();
      showSnack("Password updated");
    } catch (e: any) {
      this.setState({
        error: e.message,
        loading: false,
      });
    }
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.onDone}
          fullWidth
          maxWidth="sm"
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.setPassword();
            }}
          >
            <DialogTitle>Set Password for {this.props.name}</DialogTitle>
            <DialogContent>
              <TextField
                label="New Password"
                type="password"
                required
                value={this.state.password}
                onChange={(event) =>
                  this.setState({ password: event.currentTarget.value })
                }
                margin="normal"
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              {this.state.error && (
                <Typography color="error">{this.state.error}</Typography>
              )}
              {this.state.loading && <CircularProgress size="15px" />}
              <Button onClick={this.props.onDone} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Update
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}
