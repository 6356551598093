import * as React from "react";
import { Link } from "react-router-dom";

export class NotFound extends React.Component<{}, {}> {
  render() {
    return (
      <div>
        Not Found: Invalid link or url. <Link to={"/"}>Home</Link>
      </div>
    );
  }
}
