import { NativeSelect, TableCell, TableRow } from "@material-ui/core";
import { yellow } from "@material-ui/core/colors";
import { Warning } from "@material-ui/icons";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { roundMoneyFloat } from "./InvoiceEdit";
import { InvoiceItem } from "../../../api/invoice";
import { QbLineTypeContext } from "./QbLineItems";
import { Input } from "./Input";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { Sorter } from "./Sorter";

function useUpdateDelay<T, K extends keyof T>(props: {
  value: T;
  onChange: (value: T) => void;
  key: K;
}): [T[K], Dispatch<SetStateAction<T[K]>>] {
  const [value, setValue] = useState<T[K]>(props.value[props.key]);

  const changeRef = useRef(props.onChange);
  changeRef.current = props.onChange;

  const valueRef = useRef(props.value);
  valueRef.current = props.value;

  useEffect(() => {
    if (value === valueRef.current[props.key]) return;

    const t = setTimeout(() => {
      if (valueRef.current[props.key] === value) return;

      changeRef.current(
        Object.assign({}, valueRef.current, {
          [props.key]: value,
        })
      );
    }, 100);

    return () => clearTimeout(t);
  }, [value]);

  const rootValue = props.value[props.key];
  useEffect(() => {
    setValue(rootValue);
  }, [rootValue]);

  return [value, setValue];
}

export function RowEdit(props: {
  value: InvoiceItem;
  onShift(value: 1 | -1): void;
  onChange(item: InvoiceItem): void;
  onRemove(): void;
}) {
  const item = props.value;
  const qbTypes = useContext(QbLineTypeContext);

  const [description, setDescription] = useUpdateDelay({
    value: item,
    key: "description",
    onChange: props.onChange,
  });

  const [unit, setUnit] = useUpdateDelay({
    value: item,
    key: "unit",
    onChange: props.onChange,
  });

  const [rate, setRate] = useUpdateDelay({
    value: item,
    key: "rate",
    onChange: props.onChange,
  });

  const [qty, setQty] = useUpdateDelay({
    value: item,
    key: "quantity",
    onChange: props.onChange,
  });

  const [amount, setAmount] = useUpdateDelay({
    value: item,
    key: "amount",
    onChange: props.onChange,
  });

  useEffect(() => {
    setAmount(qty * rate);
  }, [qty, rate]);

  return (
    <TableRow>
      <TableCell
        padding="checkbox"
        style={{ width: 10, paddingLeft: 0, paddingRight: 0 }}
      >
        <Sorter onShift={props.onShift} />
      </TableCell>
      <TableCell padding="checkbox" style={{ width: "auto" }}>
        <div
          style={{ display: "flex", flexWrap: "nowrap", alignItems: "center" }}
        >
          {!item.qbItemId && (
            <span
              style={{
                color: yellow["700"],
                fontSize: "0.9rem",
                height: "0.9rem",
                paddingRight: 10,
              }}
            >
              <Warning fontSize="inherit" color="inherit" />
            </span>
          )}
          <NativeSelect
            value={item.qbItemId || "null"}
            style={{ fontSize: "0.8rem" }}
            onChange={(e) => {
              props.onChange(
                Object.assign({}, item, {
                  qbItemId:
                    e.target.value === "null" ? null : parseInt(e.target.value),
                })
              );
            }}
            disableUnderline
            fullWidth
          >
            <option value="null">(none)</option>
            {qbTypes.items.map((l) => (
              <option key={l.id} value={l.id}>
                {l.name}
              </option>
            ))}
          </NativeSelect>
        </div>
      </TableCell>
      <TableCell style={{ padding: 0 }}>
        <Input value={unit} onChange={setUnit} />
      </TableCell>
      <TableCell style={{ padding: 0 }}>
        <Input value={description} onChange={setDescription} />
      </TableCell>
      <TableCell style={{ padding: 0 }}>
        <Input
          number
          value={roundMoneyFloat(rate)}
          onChange={(v) => setRate(niceParseNumber(v))}
        />
      </TableCell>
      <TableCell style={{ padding: 0 }}>
        <Input
          number
          value={qty.toString()}
          onChange={(v) => setQty(niceParseNumber(v))}
        />
      </TableCell>
      <TableCell style={{ padding: 0 }}>
        <Input
          number
          value={roundMoneyFloat(amount)}
          onChange={(v) => setAmount(niceParseNumber(v))}
        />
      </TableCell>
      <TableCell padding="checkbox" onClick={props.onRemove}>
        <IconButton size="small">
          <CloseIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

function niceParseNumber(value: string): number {
  const v = parseFloat(value);
  if (isNaN(v)) return 0;
  return v;
}
