import * as React from "react";
import { Grid } from "@material-ui/core";
import { TextWithLabel } from "../../components/text-with-label";
import moment from "moment";
import { newline2br } from "../../components/newline2br";
import { ScheduleItemWithDriver } from "./types";

interface Props {
  item: ScheduleItemWithDriver;
}

export class ListItem extends React.PureComponent<Props> {
  render() {
    console.log(
      "desc",
      this.props.item.description,
      newline2br(this.props.item.description)
    );
    return (
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <TextWithLabel label="Driver" text={this.props.item.driverName} />
            </Grid>
            <Grid item xs={6}>
              <TextWithLabel label="Customer" text={this.props.item.customer} />
            </Grid>
            <Grid item xs={6}>
              <TextWithLabel
                label="Time"
                text={this.props.item.arrivalTime.toString()}
              />
            </Grid>
            <Grid item xs={6}>
              <TextWithLabel
                label="From"
                text={this.props.item.pickupLocation}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <TextWithLabel
            label="Contact(s)"
            text={
              this.props.item.contacts &&
              this.props.item.contacts
                .map((item) => item.description)
                .join("\n")
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <TextWithLabel label="Unit" text={this.props.item.unitId} />
            </Grid>
            <Grid item xs={6}>
              <TextWithLabel
                label="To"
                text={this.props.item.dropOffLocation}
              />
            </Grid>
            <Grid item xs={6}>
              <TextWithLabel label="Size" text={this.props.item.unitSize} />
            </Grid>
            <Grid item xs={6}>
              <TextWithLabel
                label="PO Number"
                text={this.props.item.purchaseOrderNumber}
              />
            </Grid>
            <Grid item xs={6}>
              <TextWithLabel
                label="Date"
                text={moment(this.props.item.localDate)
                  .utc()
                  .format("MMM D, YYYY")}
              />
            </Grid>
            <Grid item xs={6}>
              <TextWithLabel label="ID" text={this.props.item.userDefinedId} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <TextWithLabel
            label="Description"
            text={newline2br(this.props.item.description)}
          />
        </Grid>
      </Grid>
    );
  }
}
