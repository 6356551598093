import DialogTitle from "@material-ui/core/DialogTitle";
import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
  Table,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import { api } from "../../api/api";
import { useAsync } from "../../api/async";
import moment from "moment";
import { wsUrl } from "../../misc/websocket";
import { Alert } from "@material-ui/lab";

export function QuickbooksLog(props: { onClose(): void }) {
  const [messages, setMessages] = useState<
    { createdAt: number; value: string }[]
  >([]);
  const [refresh, setRefresh] = useState(0);
  const tStart = useRef(Date.now());

  useEffect(() => {
    const addMessage = (value: string) =>
      setMessages((old) => [{ createdAt: Date.now(), value: value }, ...old]);
    addMessage("Connecting to server...");

    try {
      const ws = new WebSocket(wsUrl("/api/web-socket/quickbooks"));
      ws.addEventListener("open", () => addMessage("Started Quickbooks Sync"));
      ws.addEventListener("message", (e) =>
        addMessage(JSON.parse(e.data).toString())
      );
      ws.addEventListener("error", (e) => addMessage(e.toString()));

      return () => ws.close();
    } catch (e: any) {
      addMessage((e || "").toString());
      addMessage("Click Retry to try again");
    }
  }, [refresh]);

  const done = messages.length > 0 && messages[0].value.startsWith("done!");
  let failed = false;

  for (let i = 0; i < messages.length; i++) {
    if (messages[i].value.toLowerCase().indexOf("error") !== -1) {
      failed = true;
    }

    if (messages[i].value.indexOf("Connecting to server") !== -1) {
      break;
    }
  }

  let hasAttachedFiles =
    messages.filter(
      (m) =>
        m.value.indexOf(
          "To help Quickbooks recognize the files we attached"
        ) !== -1
    ).length > 0;

  return (
    <Dialog open maxWidth="lg">
      <DialogTitle>Quickbooks Sync</DialogTitle>
      <DialogContent>
        {!done && <LinearProgress variant="indeterminate" />}
        {failed && (
          <Alert color="error">
            Failed to complete synchronize. Please check log below for errors.
          </Alert>
        )}
        {done && !failed && (
          <Alert color="success">
            Success! Your invoices have been synchronized
          </Alert>
        )}
        {hasAttachedFiles && (
          <Alert color="success">
            To help Quickbooks recognize the files we attached, please open
            Quickbooks and select Company &gt; Documents &gt; Repair Attached
            Document Links, click Repair Links
          </Alert>
        )}
        <Table>
          <TableRow>
            <TableCell padding="checkbox" style={{ width: "auto" }}>
              <Button onClick={() => setRefresh(refresh + 1)}>Retry</Button>
            </TableCell>
            <TableCell />
          </TableRow>
          {messages.map((l, index) => (
            <TableRow key={index}>
              <TableCell style={{ whiteSpace: "nowrap" }}>
                {moment.duration(l.createdAt - Date.now()).humanize(true)}
              </TableCell>
              <TableCell>{l.value}</TableCell>
            </TableRow>
          ))}
        </Table>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}
