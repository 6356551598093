import { Grid } from "@material-ui/core";
import * as React from "react";
import TextField from "@material-ui/core/TextField";
import { Vehicle } from "../../api/types";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

type Props = Vehicle & {
  onChange(key: keyof Vehicle, value: any): void;
};

export class VehicleDetail extends React.Component<Props, {}> {
  render() {
    return (
      <Grid container direction="row" spacing={2} style={{ width: "100%" }}>
        <Grid item xs={10}>
          <Grid container direction={"column"}>
            <TextField
              label="Name"
              type="text"
              required
              value={this.props.name}
              onChange={(event) =>
                this.props.onChange("name", event.currentTarget.value)
              }
              margin="normal"
              fullWidth
            />
            <TextField
              label="VIN"
              type="text"
              required
              value={this.props.vin}
              onChange={(event) =>
                this.props.onChange("vin", event.currentTarget.value)
              }
              margin="normal"
              fullWidth
            />
            <TextField
              label="Plate"
              type="text"
              required
              value={this.props.plate}
              onChange={(event) =>
                this.props.onChange("plate", event.currentTarget.value)
              }
              margin="normal"
              fullWidth
            />
            <TextField
              label="Year"
              type="number"
              required
              value={this.props.year}
              onChange={(event) => {
                const value = event.currentTarget.value;
                if (value === "") {
                  this.props.onChange("year", "");
                  return;
                }

                this.props.onChange("year", parseInt(value, 10));
              }}
              margin="normal"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel
            value="top"
            control={
              <Switch
                color="primary"
                checked={this.props.isArchived}
                onChange={(event) =>
                  this.props.onChange("isArchived", event.currentTarget.checked)
                }
              />
            }
            label="Archived"
            labelPlacement="top"
          />
        </Grid>
      </Grid>
    );
  }
}
