import * as React from "react";

export function newline2br(text: string) {
  // ignore text that is itself already html
  if (text.indexOf("<p>") === 0) return text;

  return text.split("\n").map((item, i) => {
    return (
      <span key={i}>
        {item}
        <br />
      </span>
    );
  });
}
