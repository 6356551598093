import { Fetcher } from "./fetcher";

export class WeeklyNoteAPI {
  fetcher: Fetcher;

  constructor(fetcher: Fetcher) {
    this.fetcher = fetcher;
  }

  set(params: { sunday: string; note: string }) {
    return this.fetcher.postOrThrow("/api/weekly-notes/set", params);
  }

  list() {
    return this.fetcher.getOrThrow<WeeklyNote[]>("/api/weekly-notes/list");
  }
}

export interface WeeklyNote {
  sunday: string;
  note: string;
}
