import React, { createContext, PropsWithChildren, useMemo } from "react";
import { useAsync } from "../../../api/async";
import { api } from "../../../api/api";

export const QbLineTypeContext = createContext({
  items: [] as { name: string; id: number }[],
});

export function QbLineTypeProvider(props: PropsWithChildren<{}>) {
  const lines = useAsync(() => api.invoices.quickbooksInvoiceLineTypes());

  const ctx = useMemo(() => {
    return {
      items: lines.asList,
    };
  }, [lines.asList]);

  return (
    <QbLineTypeContext.Provider value={ctx}>
      {props.children}
    </QbLineTypeContext.Provider>
  );
}
