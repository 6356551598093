import * as React from "react";
import { createStyles, WithStyles } from "@material-ui/styles";
import withStyles from "@material-ui/styles/withStyles";
import { Theme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { operatingSystem } from "../../../misc/is-mac";
import { StyledComponent } from "./boxes/styled-component";

const style = (theme: Theme) =>
  createStyles({
    wrapper: {
      borderTop: "1px solid " + theme.palette.grey["200"],
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  });

type Props = WithStyles<typeof style> & {};

interface State {
  canManipulate: boolean;
}

const superKey = (function () {
  if (operatingSystem === "mac") {
    return "⌘";
  }

  return "ctrl";
})();

export var footer: FooterClass | null = null;

class FooterClass extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      canManipulate: false,
    };

    footer = this;
  }

  setCanManipulate(tf: boolean) {
    this.setState({
      canManipulate: tf,
    });
  }

  render() {
    if (!this.state.canManipulate) return null;

    return (
      <Grid
        container
        className={this.props.classes.wrapper}
        justifyContent="flex-start"
        spacing={2}
      >
        {this.state.canManipulate && (
          <Grid item>
            <Typography variant="caption">
              Press {superKey} + C to copy
            </Typography>
          </Grid>
        )}
        {this.state.canManipulate && (
          <Grid item>
            <Typography variant="caption">
              Press {superKey} + V to paste
            </Typography>
          </Grid>
        )}
        {this.state.canManipulate && (
          <Grid item>
            <Typography variant="caption">Press delete to clear</Typography>
          </Grid>
        )}

        <Typography variant="caption">&nbsp;</Typography>
      </Grid>
    );
  }
}

export const Footer: StyledComponent<Props> = withStyles(style)(
  FooterClass
) as any;
