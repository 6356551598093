import { Button, CircularProgress } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { QuickbooksLog } from "./QuickbooksLog";
import { RefreshContext } from "./Invoicing";

export function QuickbooksStatus(props: { count?: number }) {
  const [show, setShow] = useState(false);
  const ctx = useContext(RefreshContext);

  return (
    <>
      <div>
        <Button
          variant="outlined"
          size="small"
          color={props.count && props.count > 0 ? "primary" : "default"}
          onClick={() => setShow(true)}
        >
          Sync with Quickbooks
          {!!props.count && props.count > 0 && <> ({props.count} pending)</>}
        </Button>
      </div>
      {show && (
        <QuickbooksLog
          onClose={() => {
            setShow(false);
            ctx.reload();
          }}
        />
      )}
    </>
  );
}
