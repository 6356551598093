import * as React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, WithStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { widths } from "../../state/config";
import { ColumnName } from "../../state/field";
import { StyledComponent } from "./styled-component";

const styles = (theme: Theme) =>
  createStyles({
    tableCell: {
      padding: 0,
      margin: 0,
      position: "relative",
      verticalAlign: "top",
    },
    boxInner: {
      position: "relative",
      overflow: "hidden",
      textOverflow: "ellipsis",
      cursor: "pointer",
      userSelect: "none",
    },
    vCenter: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  });

type Props = {
  height: number | string;
  column: ColumnName;
  dateRow?: boolean;
  valignCenter?: boolean;
  noPadding?: boolean;
  children: any;
} & WithStyles<typeof styles>;

export const Box: StyledComponent<Props> = withStyles(styles)(
  class Box extends React.Component<Props, {}> {
    getBoxClass(): string {
      const c = this.props.classes;

      var innerClass = c.boxInner;

      if (this.props.valignCenter) {
        innerClass += " " + c.vCenter;
      }

      return innerClass;
    }

    cellWidth() {
      if (this.props.column === "controls") return 129;
      return widths[this.props.column] + (this.props.dateRow ? -1 : 0);
    }

    render() {
      const width = this.cellWidth();

      var children = this.props.children;

      if (this.props.children instanceof String) {
        children = (
          <span
            style={{ whiteSpace: "pre-wrap", wordBreak: "break-all" }}
            key="data"
          >
            {this.props.children}
          </span>
        );
      }

      const borderWidth = 2;
      const paddingWidth = 5;

      return (
        <td
          key="cell-normal"
          className={this.props.classes.tableCell}
          style={{
            width: width,
          }}
        >
          <div
            key="wrapper"
            className={this.getBoxClass()}
            style={{
              height: this.props.height,
              width: this.props.noPadding
                ? width - borderWidth * 2
                : width - paddingWidth * 2 - borderWidth * 2,
              padding: this.props.noPadding ? "0" : undefined,
            }}
          >
            {children}
          </div>
        </td>
      );
    }
  }
) as any;
