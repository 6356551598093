import { Theme, WithStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";
import React from "react";
import Popover from "@material-ui/core/Popover";
import CheckIcon from "@material-ui/icons/Check";
import withStyles from "@material-ui/styles/withStyles";
import { StyledComponent } from "../boxes/styled-component";

const styles = (theme: Theme) =>
  createStyles({
    colorTab: {
      width: "100px",
      height: "50px",
      padding: "10px",
      cursor: "pointer",
      "&:hover": {
        opacity: 0.6,
      },
    },
  });

type Props = WithStyles<typeof styles> & {
  anchorEl: any;
  open: boolean;
  color?: string;
  driverColors?: boolean;

  onClose(): void;
  onSelect(color: string): void;
};

const colors = [
  "#ffffff",
  "#d73a4a",
  "#0075ca",
  "#cfd3d7",
  "#008672",
  "#d876e3",
  "#a2eeef",
];

export const driverColors = [
  "#ffffff",
  "#4d7100",
  "#d246c8",
  "#008539",
  "#b386ff",
  "#9a9400",
  "#6e94ff",
  "#ffb843",
  "#244ea4",
  "#fd4548",
  "#00a2e8",
  "#a13b00",
  "#e7a0ff",
  "#d1c885",
  "#aa0079",
  "#9db17b",
  "#ff6486",
  "#863469",
  "#ffa8b2",
  "#853b44",
  "#945571",
];

export const PaintButtonPopover: StyledComponent<Props> = withStyles(styles)(
  class PaintButtonPopover extends React.Component<Props, {}> {
    render() {
      const list = this.props.driverColors ? driverColors : colors;

      return (
        <Popover
          open={this.props.open}
          anchorEl={this.props.anchorEl}
          onClose={this.props.onClose}
          onClick={(e) => e.stopPropagation()}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {!this.props.driverColors && (
            <div
              key="from-driver"
              className={this.props.classes.colorTab}
              style={{
                backgroundColor: "white",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                this.props.onSelect(null as any);
              }}
            >
              {list.indexOf(this.props.color || "") === -1 && <CheckIcon />}
              <span>From Driver</span>
            </div>
          )}
          {list.map((color) => {
            return (
              <div
                key={color}
                className={this.props.classes.colorTab}
                style={{ backgroundColor: color, color: "white" }}
                onClick={() => {
                  this.props.onSelect(color);
                }}
              >
                {color === this.props.color && <CheckIcon />}
              </div>
            );
          })}
        </Popover>
      );
    }
  }
) as any;
