import React, { useCallback, useState } from "react";
import {
  Button,
  DialogActions,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

type Config = {
  callback: () => any;
  message: string;
  actionText: string;
  actionDestroys: boolean;
};

export function useConfirm() {
  const [current, setCurrent] = useState<Config>();

  const element = current && (
    <Dialog open>
      <DialogTitle>Confirm</DialogTitle>
      <DialogContent>
        <DialogContentText>{current.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setCurrent(undefined)}>Cancel</Button>
        <Button
          color={current.actionDestroys ? ("red" as any) : "primary"}
          onClick={() => {
            current?.callback();
            setCurrent(undefined);
          }}
        >
          {current.actionText}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return {
    confirm: setCurrent,
    Element: element,
  };
}
