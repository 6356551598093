import * as React from "react";
import moment, { Moment } from "moment";
import { useTraceUpdate } from "../../misc/perfdebug";
import { useCallback, useContext, useEffect } from "react";
import { UndoContext } from "./utils/undo";
import { Grid } from "@material-ui/core";
import { SimpleIconButton } from "../../misc/simple-icon-button";
import UndoIcon from "@material-ui/icons/Undo";
import Button from "@material-ui/core/Button";
import { EditorDatePicker } from "./layout/cell-editor/editor-date-picker";
import { AppBarSearch } from "../../components/app-bar-search";
import RedoIcon from "@material-ui/icons/Redo";

export const PageHeaderRight = React.memo(function (props: {
  search: string;
  autoScrollToToday(): void;
  autoScrollTo(date: Moment): void;
  updateSearch(e: string): void;
}) {
  useTraceUpdate("PageHeaderRight", props);
  const undoCtx = useContext(UndoContext);

  const autoScrollTo = useCallback(
    (date) => props.autoScrollTo(moment(date)),
    [props]
  );

  useEffect(() => {
    const keyListener = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === "z" && undoCtx.canUndo) {
        undoCtx.undo();
      }

      if (e.ctrlKey && e.key === "y" && undoCtx.canRedo) {
        undoCtx.redo();
      }
    };

    document.body.addEventListener("keydown", keyListener);
    return () => document.body.removeEventListener("keydown", keyListener);
  }, [undoCtx]);

  return (
    <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
      <Grid item>
        <SimpleIconButton
          style={{ color: "white" }}
          disabled={!undoCtx.canUndo}
          onClick={undoCtx.undo}
        >
          <UndoIcon />
        </SimpleIconButton>
        <SimpleIconButton
          style={{ color: "white" }}
          disabled={!undoCtx.canRedo}
          onClick={undoCtx.redo}
        >
          <RedoIcon />
        </SimpleIconButton>
      </Grid>
      <Grid item>
        <Button
          onClick={props.autoScrollToToday}
          variant="text"
          style={{ color: "white" }}
        >
          Today
        </Button>
      </Grid>
      <Grid item>
        <EditorDatePicker onChange={autoScrollTo} />
      </Grid>
      <Grid item>
        <AppBarSearch search={props.search} onChange={props.updateSearch} />
      </Grid>
    </Grid>
  );
});
