import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  Grid,
} from "@material-ui/core";
import { useAsync } from "../../../api/async";
import { api } from "../../../api/api";
import Button from "@material-ui/core/Button";
import { useAsyncAction } from "nate-react-api-helpers";
import { DatePicker } from "@material-ui/pickers";
import { RowEdit } from "./RowEdit";
import { InvoiceItem } from "../../../api/invoice";
import { ScheduleDetail } from "./ScheduleDetail";
import { grey, red } from "@material-ui/core/colors";
import { ExtraHoursWarning } from "./ExtraHoursWarning";
import { TextInput } from "./TextInput";
import { AddCircleOutlined } from "@material-ui/icons";
import moment from "moment";
import { CustomerSelect } from "./CustomerSelect";
import { useConfirm } from "./Confirm";

export function InvoiceEdit(props: { id: number; onClose(): void }) {
  const invoiceData = useAsync(() => api.invoices.get({ id: props.id }), {
    dependancies: [props.id],
  });

  const save = useAsyncAction(
    async (invoice) => {
      await api.invoices.save(invoice);
      props.onClose();
    },
    [props.onClose]
  );

  const archive = useAsyncAction(
    async (input) => {
      await api.invoices.archive(input);
      props.onClose();
    },
    [props.onClose]
  );

  useEffect(() => {
    if (invoiceData.result?.submittedToQuickbooks) {
      props.onClose();
    }
  }, [invoiceData.result, props.onClose]);
  const inv = invoiceData.result;

  const [date, setDate] = useState(new Date());
  const [items, setItems] = useState<InvoiceItem[]>([]);
  const [orderNumber, setOrderNumber] = useState("");
  const [poNumber, setPoNumber] = useState("");
  const [project, setProject] = useState("");
  const [customerId, setCustomerId] = useState<number>();

  useEffect(() => {
    if (!inv) return;

    setDate(
      moment(moment.utc(inv.date).format("YYYY-MM-DD"), "YYYY-MM-DD").toDate()
    );
    setItems(inv.invoiceItems);
    setOrderNumber(inv.orderNumber);
    setPoNumber(inv.poNumber);
    setProject(inv.project);
    setCustomerId(inv.customer);
  }, [inv]);

  useEffect(() => {
    console.log("new invoice-edit");
    return () => console.log("invoice-edit done");
  }, []);

  const confirmer = useConfirm();

  if (confirmer.Element) return confirmer.Element;

  if (!inv) {
    return (
      <Dialog open>
        <DialogContent>{invoiceData.LoadingElement}</DialogContent>
        <DialogActions>
          <Button variant="text" onClick={props.onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const invObject = (submit: boolean) => {
    return {
      id: inv.id,
      poNumber: poNumber,
      orderNumber: orderNumber,
      customerName: inv.customerName,
      customer: customerId,
      project: project,
      submittedToQuickbooks: submit,
      date: date,
      invoiceItems: items,
    };
  };

  return (
    <Dialog open fullWidth maxWidth="xl">
      <DialogContent>
        <Grid container justifyContent="space-between" spacing={1}>
          <Grid item xs>
            <CustomerSelect
              customerName={inv.customerName}
              customerId={customerId}
              onChange={setCustomerId}
            />
          </Grid>
          <Grid item>
            <TextInput
              label="Order #"
              value={orderNumber}
              onChange={setOrderNumber}
            />
          </Grid>
          <Grid item>
            <TextInput label="PO #" value={poNumber} onChange={setPoNumber} />
          </Grid>
          <Grid item xs>
            <TextInput label="Project" value={project} onChange={setProject} />
          </Grid>
          <Grid item>
            <DatePicker
              variant="dialog"
              format="yyyy-MMM-dd"
              value={date}
              inputVariant="outlined"
              size="small"
              label="Date"
              style={{ width: 135 }}
              onChange={(e) => {
                if (e) {
                  setDate(e);
                }
              }}
            />
          </Grid>
        </Grid>

        <div style={{ height: 16 }} />

        <div style={{ marginLeft: -24, marginRight: -24 }}>
          <Table size="small" style={{ borderTop: "1px solid " + grey["300"] }}>
            <TableHead>
              <TableRow style={{ backgroundColor: grey["200"] }}>
                <TableCell></TableCell>
                <TableCell>Item</TableCell>
                <TableCell>Unit</TableCell>
                <TableCell>Description</TableCell>
                <TableCell style={{ width: 100 }}>Rate</TableCell>
                <TableCell style={{ width: 100 }}>Qty</TableCell>
                <TableCell style={{ width: 100 }}>Total</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, index) => (
                <RowEdit
                  key={item.id}
                  value={item}
                  onChange={(value) => {
                    setItems(items.map((i) => (i === item ? value : i)));
                  }}
                  onRemove={() => setItems(items.filter((i) => i !== item))}
                  onShift={(value) => {
                    let newIndex = index + value;
                    if (newIndex < 0) return;
                    if (newIndex >= items.length) return;

                    const oldIndex = index;
                    const shiftItem = item;

                    setItems((items) =>
                      items.map((item, index) => {
                        if (index === newIndex) return shiftItem;
                        if (index === oldIndex) return items[newIndex];
                        return item;
                      })
                    );
                  }}
                />
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell
                  colSpan={2}
                  padding="checkbox"
                  style={{ width: "auto" }}
                >
                  <Button
                    startIcon={<AddCircleOutlined />}
                    onClick={() => {
                      setItems([
                        ...items,
                        {
                          id: 0,
                          invoice: inv.id,
                          qbItemId: 0,
                          description: "",
                          quantity: 1,
                          unit: "",
                          rate: 0,
                          amount: 0,
                        },
                      ]);
                    }}
                  >
                    Add Row
                  </Button>
                </TableCell>
                <TableCell colSpan={4} style={{ textAlign: "right" }}>
                  Total
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "right",
                    paddingRight: 16,
                    fontWeight: "bold",
                  }}
                >
                  {roundMoneyFloat(
                    items.reduce((acc, item) => acc + item.amount, 0)
                  )}
                </TableCell>
                <TableCell />
              </TableRow>
            </TableFooter>
          </Table>

          <div style={{ margin: 16 }}>
            <ExtraHoursWarning reports={inv.driverReports} />
          </div>

          <div style={{ marginTop: 16 }}>
            <div
              style={{
                padding: 4,
                backgroundColor: grey["300"],
                paddingLeft: 16,
              }}
            >
              Driver details
            </div>
            <div style={{ backgroundColor: grey["200"] }}>
              <ScheduleDetail
                items={inv.scheduleItems}
                reports={inv.driverReports}
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs>
            <span style={{ color: red["800"] }}>
              <Button
                variant="text"
                color="inherit"
                onClick={() =>
                  confirmer.confirm({
                    callback: () => archive.callback({ id: inv.id }),
                    message: "Are you sure you want to archive this?",
                    actionText: "Archive",
                    actionDestroys: true,
                  })
                }
              >
                Archive
              </Button>
            </span>
            {archive.LoadingElement}
          </Grid>
          <Grid item>
            {save.LoadingElement}
            <Button variant="text" onClick={props.onClose}>
              Cancel
            </Button>
            <Button
              variant="text"
              onClick={() => save.callback(invObject(false))}
            >
              Save Draft
            </Button>
            <Button
              variant="text"
              onClick={() => save.callback(invObject(true))}
            >
              Save & Submit to QB
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export function roundMoneyFloat(value: number) {
  return value.toFixed(2);
}
