import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { makeStyles } from "@material-ui/styles";
import { grey } from "@material-ui/core/colors";
import React from "react";

const useStyles = makeStyles((theme) => ({
  button: {
    border: "1px solid " + grey["200"],
    backgroundColor: grey["200"],
    fontSize: "1.6rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    width: 30,
    cursor: "pointer",

    "&:hover": {
      backgroundColor: grey["300"],
    },

    "& > div": {
      height: "1em",
      fontSize: "0.5em",
    },
  },
}));

export function Sorter(props: { onShift(value: 1 | -1): void }) {
  const styles = useStyles();

  return (
    <div>
      <div className={styles.button} onClick={() => props.onShift(-1)}>
        <div>
          <ArrowDropUpIcon fontSize="inherit" />
        </div>
      </div>
      <div className={styles.button} onClick={() => props.onShift(1)}>
        <div>
          <ArrowDropDownIcon fontSize="inherit" />
        </div>
      </div>
    </div>
  );
}
