import * as React from "react";
import { CellAutoComplete } from "./cell-auto-complete";
import { Command } from "./command";
import { useContext } from "react";
import { JobTypeContext } from "./job-type-provider";

export function JobType(props: {
  value: string;
  onMakeUpdate(value: string): void;
  onClose(cmd: Command): void;
}) {
  const ctx = useContext(JobTypeContext);

  return (
    <CellAutoComplete
      freeSolo
      value={props.value}
      options={ctx.list}
      save={(value: string | null) => {
        props.onMakeUpdate(value || "");
      }}
      close={props.onClose}
    />
  );
}
