import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { File } from "../../../../../../api/types";
import { api } from "../../../../../../api/api";
import React, { useCallback, useEffect, useState } from "react";
import Viewer from "viewerjs";
import "viewerjs/dist/viewer.css";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import GetAppIcon from "@material-ui/icons/GetApp";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import { FileEntity } from "../../../../../../../../driver-app/api/types";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      display: "inline-block",
      border: "2px solid white",
      width: 100,
      height: 100,
      backgroundSize: "contain",
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",

      "&:hover": {
        border: "2px solid " + theme.palette.primary.dark,
      },
    },
    innerImg: {
      width: 0,
    },
    tooltipBg: {
      backgroundColor: "transparent",
    },
  })
);

export function ImageViewer(props: {
  images: File[];
  onFileArchived(file: FileEntity): void;
}) {
  const [imageWrapperRef, setImageWrapperRef] = useState<any>();
  const [viewer, setViewer] = useState<Viewer>();

  const onClick = useCallback(
    (index) => {
      if (!viewer) {
        console.warn("viewer not setup yet");
        return;
      }

      viewer.view(index);
    },
    [viewer]
  );

  useEffect(() => {
    if (!imageWrapperRef) return;

    const vw = new Viewer(imageWrapperRef);
    setViewer(vw);

    return () => {
      vw.destroy();
    };
  }, [imageWrapperRef]);

  return (
    <div ref={setImageWrapperRef}>
      {props.images.map((img, index) => (
        <Img
          key={img.id}
          img={img}
          index={index}
          onClick={onClick}
          onFileArchived={props.onFileArchived}
        />
      ))}
    </div>
  );
}

function Img(props: {
  img: File;
  index: number;
  onClick(index: number): void;
  onFileArchived(file: File): void;
}) {
  const styles = useStyles();
  const img = props.img;
  const showBig = useCallback(() => props.onClick(props.index), [props]);
  const onArchived = useCallback(() => props.onFileArchived(img), [props, img]);

  return (
    <React.Fragment>
      <Tooltip
        interactive
        classes={{ tooltip: styles.tooltipBg }}
        title={
          <Paper>
            <IconButton onClick={showBig}>
              <FullscreenIcon />
            </IconButton>
            <IconButton onClick={onArchived}>
              <DeleteForeverIcon />
            </IconButton>
            <IconButton onClick={() => api.files.download(props.img.id)}>
              <GetAppIcon />
            </IconButton>
          </Paper>
        }
      >
        <div
          onClick={showBig}
          style={{ backgroundImage: "url(" + api.files.viewSrc(img.id) + ")" }}
          className={styles.img}
        >
          <img
            alt=""
            src={api.files.viewSrc(img.id)}
            className={styles.innerImg}
          />
        </div>
      </Tooltip>
    </React.Fragment>
  );
}
