import { api } from "../api/api";
import * as Cookies from "js-cookie";

class Authentication {
  // called when the user needs to log back in.
  // once user is logged in, onLoginComplete should be called
  onLoginRequired: () => void = () => {};

  // called when the access token is updated
  onAccessTokenChanged: () => void = () => {};

  async isProbablyLoggedIn(): Promise<boolean> {
    try {
      const result = Cookies.get("token");
      if (!result) return false;
      return true;
    } catch (e: any) {
      return false;
    }
  }

  // throws if login fails for any reason
  async login(email: string, password: string): Promise<boolean> {
    const result = await api.users.login(email, password);
    if (!("accessToken" in result)) {
      throw new Error(result.error);
    }

    // access/refresh tokens are stored in cookies
    this.onAccessTokenChanged();
    return true;
  }

  async refreshToken(): Promise<boolean> {
    const result = await api.users.refreshAccessToken();
    if ("accessToken" in result) {
      // access/refresh tokens are stored in cookies
      this.onAccessTokenChanged();
      return true;
    }

    return false;
  }

  async fetch(path: string, init?: RequestInit): Promise<Response> {
    if (!init) {
      init = {};
    }

    if (!init.headers) {
      init.headers = {};
    }

    init.credentials = "include";

    const result = await fetch(path, init);
    if (result.status === 403 || result.status === 401) {
      if (!(await this.refreshToken())) {
        this.onLoginRequired();
        return result;
      }

      return this.fetch(path, init);
    }

    return result;
  }
}

export const auth = new Authentication();
