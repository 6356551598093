import { PageContent } from "../components/page-content";
import { PageHeaderCenter } from "./schedule-editor/page-header-center";
import { PageHeaderRight } from "./schedule-editor/page-header-right";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { JobTypeProvider } from "./schedule-editor/layout/cell-editor/job-type-provider";
import { DriverContext } from "./schedule-editor/layout/cell-editor/driver-editor";
import Button from "@material-ui/core/Button";
import { Footer } from "./schedule-editor/layout/footer";
import { UndoProvider } from "./schedule-editor/utils/undo";
import * as React from "react";
import { IsSearchingContext } from "./schedule-editor";
import { useCallback, useEffect, useState } from "react";
import { api } from "../api/api";
import { ScheduleItemWithDriverReportInfo, User } from "../api/types";
import { Loading } from "../components/loading";
import { List } from "./schedule-editor2/List";

const defaultSearchLimit = 100;

export function ScheduleEditor2() {
  const [displayMonth, setDisplayMonth] = useState("");
  const [search, setSearch] = useState("");
  const [error, setError] = useState<null | string>(null);
  const [items, setItems] = useState<ScheduleItemWithDriverReportInfo[]>([]);
  const [drivers, setDrivers] = useState<User[]>([]);
  const [showAllSearchResults, setShowAllSearchResults] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchList = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const result = await api.schedule.getCurrentContext();
      setDrivers(result.drivers);
      setItems(result.list);

      setLoading(false);
    } catch (e: any) {
      setError(e.toString());
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  const isShowingAllResults =
    showAllSearchResults || items.length < defaultSearchLimit;

  return (
    <UndoProvider>
      <PageContent
        title="Schedule Editor"
        noPadding
        centerContent={<PageHeaderCenter displayMonth={displayMonth} />}
        rightContent={
          <PageHeaderRight
            autoScrollTo={() => console.error("todo")}
            autoScrollToToday={() => console.error("todo")}
            search={search}
            updateSearch={setSearch}
          />
        }
      >
        <Grid
          container
          direction="column"
          style={{ height: "100%", width: "100%" }}
          key="schedule-container"
        >
          <Grid
            key="list-cell"
            item
            xs
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            {error !== null && <Typography color="error">{error}</Typography>}
            <JobTypeProvider>
              <IsSearchingContext.Provider value={search !== ""}>
                <DriverContext.Provider value={drivers}>
                  <div
                    style={{ textAlign: "center", backgroundColor: "#ffc400" }}
                  >
                    This is a beta feature and doesn't work all the way. Here's
                    a link back to the{" "}
                    <a href="/schedule-editor">Regular Editor</a>
                  </div>
                  {loading ? <Loading /> : <List items={items} />}
                </DriverContext.Provider>
              </IsSearchingContext.Provider>
            </JobTypeProvider>
          </Grid>
          <Grid item>
            {search !== "" && !isShowingAllResults && (
              <Grid
                key="search-button"
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Typography color="textSecondary">
                    Only showing {items.length} results
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() =>
                      setTimeout(() => setShowAllSearchResults(true))
                    }
                  >
                    Show All Results
                  </Button>
                </Grid>
              </Grid>
            )}
            <Footer key="footer" />
          </Grid>
        </Grid>
      </PageContent>
    </UndoProvider>
  );
}
