import { DatePicker } from "@material-ui/pickers";
import * as React from "react";
import { Moment } from "moment";
import moment from "moment";
import { showSnack } from "../../../../components/snacker";
import { Loading } from "../../../../components/loading";

interface State {
  saving: boolean;
  open: boolean;
  value: Date | null;
}

interface Props {
  children: Moment;
  onMakeUpdate(d: Moment): Promise<any>;
  onClose(): void;
}

const dateFormat = "YYYY-MMM-DD";

export class DateEditor extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      saving: false,
      value: new Date(this.props.children.utc().format(dateFormat)),
      open: false,
    };
  }

  componentDidMount(): void {
    this.setState({
      open: true,
    });
  }

  render() {
    return (
      <React.Fragment>
        {this.state.saving && <Loading />}
        <DatePicker
          open={this.state.open}
          variant="inline"
          format="yyyy-MM-dd"
          value={this.state.value}
          onClose={this.props.onClose}
          onChange={(e) => {
            if (e === null) return;

            this.setState({
              value: new Date(moment(e).format(dateFormat)),
              saving: true,
              open: false,
            });

            try {
              this.props.onMakeUpdate(moment(e));
              showSnack("Saved cell");
            } catch (e: any) {
              showSnack("Failed to save: " + e.message);
              this.setState({
                saving: false,
                open: true,
              });
            }
          }}
        />
      </React.Fragment>
    );
  }
}
