import * as React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, WithStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { widths } from "../../state/config";
import {
  ClickParams,
  ExternalHighlight,
  HighlightParams,
  PasteParams,
  stateManager,
} from "../../state/state-manager";
import { Subscription } from "../../../../misc/event-emitter";
import { Moment } from "moment";
import { Loading } from "../../../../components/loading";
import { ColumnName } from "../../state/field";
import { CellEditor } from "../cell-editor/editor";
import { Command, processCommand } from "../cell-editor/command";
import { StyledComponent } from "./styled-component";

const borderBlue = (theme: Theme) => theme.palette.primary.dark;
const borderGrey = (theme: Theme) => theme.palette.grey["600"];

const styles = (theme: Theme) =>
  createStyles({
    tableCell: {
      padding: 0,
      margin: 0,
      position: "relative",
    },
    boxInner: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      overflow: "hidden",
      textOverflow: "ellipsis",
      cursor: "pointer",
      userSelect: "none",
      "& p": {
        margin: "0",
      },
    },
    boxCanHover: {
      "&:hover::after": {
        position: "absolute",
        content: '" "',
        top: 0,
        left: 0,
        right: 0,
        bottom: 1,
        zIndex: -2,
        border: "1px solid " + theme.palette.grey["300"],
      },
    },
    selectedCell: {
      "&::after": {
        position: "absolute",
        content: '" "',
        top: 0,
        left: 0,
        right: 0,
        bottom: 1,
        zIndex: -2,
      },
      "&::before": {
        position: "absolute",
        content: '" "',
        top: 0,
        left: 0,
        right: 0,
        bottom: 1,
        zIndex: -1,
      },
    },
    leftSelectedCell: {
      "&::after": {
        borderLeft: "1px solid " + borderBlue(theme),
        borderTop: "1px solid " + borderBlue(theme),
        borderBottom: "1px solid " + borderBlue(theme),
      },
    },
    rightSelectedCell: {
      "&::after": {
        borderRight: "1px solid " + borderBlue(theme),
        borderTop: "1px solid " + borderBlue(theme),
        borderBottom: "1px solid " + borderBlue(theme),
      },
    },
    centerSelectedCell: {
      "&::after": {
        borderTop: "1px solid " + borderBlue(theme),
        borderBottom: "1px solid " + borderBlue(theme),
      },
    },
    leftExtSelectedCell: {
      "&::after": {
        borderLeft: "1px solid " + borderGrey(theme),
        borderTop: "1px solid " + borderGrey(theme),
        borderBottom: "1px solid " + borderGrey(theme),
      },
    },
    rightExtSelectedCell: {
      "&::after": {
        borderRight: "1px solid " + borderGrey(theme),
        borderTop: "1px solid " + borderGrey(theme),
        borderBottom: "1px solid " + borderGrey(theme),
      },
    },
    centerExtSelectedCell: {
      "&::after": {
        borderTop: "1px solid " + borderGrey(theme),
        borderBottom: "1px solid " + borderGrey(theme),
      },
      "&::before": {
        backgroundColor: borderGrey(theme),
        opacity: 0.1,
      },
    },
    extLabel: {
      backgroundColor: borderGrey(theme),
      position: "absolute",
      top: 0,
      right: 0,
      color: "white",
      fontSize: "11px",
      zIndex: 1,
    },
    loadingWrapper: {
      position: "absolute",
      zIndex: 1,
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    },
  });

interface CommonProps {
  index: number;
  height: number | string;
  column: ColumnName;
  scheduleId: number;
}

interface RenderOnlyProps extends CommonProps {
  type: "render" | undefined;
  children: any;
}

interface EditableProps extends CommonProps {
  type: "editable";
  children: string;

  dataString?: string;
  dataMoment?: Moment;

  oneClickToEdit?: boolean;

  onMakeUpdate(value: string): Promise<any>;
}

type Props = (RenderOnlyProps | EditableProps) & WithStyles<typeof styles>;

interface State {
  selected: boolean;
  isLeftCellInSelection: boolean;
  isRightCellInSelection: boolean;
  isCenterCellInSelection: boolean;

  isLeftCellInExtSelection: boolean;
  isRightCellInExtSelection: boolean;
  isCenterCellInExtSelection: boolean;

  loading: boolean;

  extSelectionUserName: string;
}

export const ClickableBox: StyledComponent<RenderOnlyProps> &
  StyledComponent<EditableProps> = withStyles(styles)(
  class ClickableBox extends React.Component<Props, State> {
    constructor(props: any) {
      super(props);

      this.state = {
        selected: false,
        isLeftCellInSelection: false,
        isRightCellInSelection: false,
        isCenterCellInSelection: false,
        isLeftCellInExtSelection: false,
        isRightCellInExtSelection: false,
        isCenterCellInExtSelection: false,
        extSelectionUserName: "",
        loading: false,
      };

      this.cellEditorCancel = this.cellEditorCancel.bind(this);
      this.onMakeUpdate = this.onMakeUpdate.bind(this);
    }

    highlightSubscription: Subscription | null = null;
    externalHighlightSubscription: Subscription | null = null;
    clickSubscription: Subscription | null = null;
    pasteSubscription: Subscription | null = null;

    componentDidMount(): void {
      this.highlightSubscription = stateManager.highlightEvents.subscribe((p) =>
        this.onHighlight(p)
      );
      this.externalHighlightSubscription =
        stateManager.externalHighlightEvents.subscribe((p) =>
          this.onExternalHighlight(p)
        );
      this.clickSubscription = stateManager.clickEvents.subscribe((p) =>
        this.onClick(p)
      );
      this.pasteSubscription = stateManager.pasteEvents.subscribe((p) =>
        this.onPaste(p)
      );
    }

    componentWillUnmount(): void {
      if (this.externalHighlightSubscription)
        this.externalHighlightSubscription.unsubscribe();
      if (this.highlightSubscription) this.highlightSubscription.unsubscribe();
      if (this.clickSubscription) this.clickSubscription.unsubscribe();
      if (this.pasteSubscription) this.pasteSubscription.unsubscribe();
    }

    async onPaste(p: PasteParams) {
      if (
        p.scheduleId !== this.props.scheduleId ||
        p.indexes.indexOf(this.props.index) === -1
      ) {
        return;
      }

      this.setState({
        loading: true,
      });

      await p.promise;

      this.setState({
        loading: false,
      });
    }

    onExternalHighlight(list: ExternalHighlight[]) {
      var userName: string = "";
      var isLeft = false;
      var isRight = false;
      var isCenter = false;

      list.map((p) => {
        if (p.type !== "schedule") {
          this.clearExtHighlightIfHighlighted();
          return null;
        }

        if (p.scheduleId === undefined) return null;

        if (p.scheduleId !== this.props.scheduleId) {
          this.clearExtHighlightIfHighlighted();
          return null;
        }

        if (!isLeft) {
          isLeft = p.leftCell === this.props.index;
        }

        if (!isRight) {
          isRight = p.rightCell === this.props.index;
          if (isRight) {
            userName = p.userName;
          }
        }

        if (!isCenter) {
          isCenter = p.centerCells.indexOf(this.props.index) !== -1;
        }

        return null;
      });

      const stateChange: Partial<State> = {};
      if (isLeft !== this.state.isLeftCellInExtSelection)
        stateChange.isLeftCellInExtSelection = isLeft;
      if (isRight !== this.state.isRightCellInExtSelection)
        stateChange.isRightCellInExtSelection = isRight;
      if (isCenter !== this.state.isCenterCellInExtSelection)
        stateChange.isCenterCellInExtSelection = isCenter;
      if (userName !== this.state.extSelectionUserName)
        stateChange.extSelectionUserName = userName;

      this.setState(stateChange as any);
    }

    onClick(p: ClickParams) {
      if (p.type !== "schedule") {
        this.setState({
          selected: false,
        });

        return;
      }

      if (
        p.scheduleId !== this.props.scheduleId ||
        p.cell !== this.props.index
      ) {
        if (this.state.selected) {
          this.setState({
            selected: false,
          });
        }
        return;
      }

      this.setState({
        selected: true,
      });
    }

    onHighlight(p: HighlightParams) {
      if (p.type !== "schedule") {
        this.clearHighlightIfHighlighted();
        return;
      }
      if (p.scheduleId !== this.props.scheduleId) {
        this.clearHighlightIfHighlighted();
        return;
      }

      const isLeft = p.leftCell === this.props.index;
      const isRight = p.rightCell === this.props.index;
      const isCenter = p.centerCells.indexOf(this.props.index) !== -1;

      this.setState({
        isLeftCellInSelection: isLeft,
        isRightCellInSelection: isRight,
        isCenterCellInSelection: isCenter,
        selected: false,
      });

      if (isLeft || isRight || isCenter) {
        if (this.isEditable(this.props)) {
          stateManager.selectedData[this.props.index] = this.dataValueString(
            this.props
          );
        } else {
          stateManager.selectedData[this.props.index] = null;
        }
      }
    }

    dataValueString(props: EditableProps): string {
      if (props.dataString !== undefined) return props.dataString;
      if (props.dataMoment !== undefined)
        return props.dataMoment.format("YYYY-MM-DD");

      return "";
    }

    clearExtHighlightIfHighlighted() {
      if (
        this.state.isLeftCellInExtSelection ||
        this.state.isRightCellInExtSelection ||
        this.state.isCenterCellInExtSelection
      ) {
        this.setState({
          isLeftCellInExtSelection: false,
          isRightCellInExtSelection: false,
          isCenterCellInExtSelection: false,
          extSelectionUserName: "",
        });
      }
    }

    clearHighlightIfHighlighted() {
      if (
        this.state.isLeftCellInSelection ||
        this.state.isRightCellInSelection ||
        this.state.isCenterCellInSelection ||
        this.state.selected
      ) {
        this.setState({
          selected: false,
          isLeftCellInSelection: false,
          isRightCellInSelection: false,
          isCenterCellInSelection: false,
        });
      }
    }

    getBoxClass(): string {
      const c = this.props.classes;
      const s = this.state;

      var innerClass = this.isEditable(this.props) ? c.boxInner : "";
      var isPartOfSelection = false;

      if (s.isLeftCellInSelection) {
        innerClass += " " + c.leftSelectedCell + " " + c.selectedCell;
        isPartOfSelection = true;
      }

      if (s.isRightCellInSelection) {
        innerClass += " " + c.rightSelectedCell + " " + c.selectedCell;
        isPartOfSelection = true;
      }

      if (s.isCenterCellInSelection) {
        innerClass += " " + c.centerSelectedCell + " " + c.selectedCell;
        isPartOfSelection = true;
      }

      if (s.isLeftCellInExtSelection) {
        innerClass += " " + c.leftExtSelectedCell + " " + c.selectedCell;
        isPartOfSelection = true;
      }

      if (s.isRightCellInExtSelection) {
        innerClass += " " + c.rightExtSelectedCell + " " + c.selectedCell;
        isPartOfSelection = true;
      }

      if (s.isCenterCellInExtSelection) {
        innerClass += " " + c.centerExtSelectedCell + " " + c.selectedCell;
        isPartOfSelection = true;
      }

      if (!isPartOfSelection && this.isEditable(this.props)) {
        innerClass += " " + c.boxCanHover;
      }

      return innerClass;
    }

    cellWidth() {
      return widths[this.props.column];
    }

    isEditable(props: EditableProps | RenderOnlyProps): props is EditableProps {
      return props.type === "editable";
    }

    async onMakeUpdate(e: string, cmd?: Command): Promise<any> {
      const props = this.props as EditableProps;

      if (!props.onMakeUpdate) return Promise.resolve(true);

      this.setState({
        loading: true,
      });

      if (cmd) {
        processCommand(cmd);
      }

      await props.onMakeUpdate(e);

      this.setState({
        loading: false,
      });
    }

    cellEditorCancel(cmd: Command) {
      this.setState({ selected: false });
      processCommand(cmd);
    }

    renderInnerCell(width: number) {
      const props = this.props;
      if (this.state.selected && this.isEditable(props)) {
        return (
          <CellEditor
            column={this.props.column}
            onCancel={this.cellEditorCancel}
            onMakeUpdate={this.onMakeUpdate}
            dataString={props.dataString}
            dataMoment={props.dataMoment}
          />
        );
      }

      return renderCell({
        boxClasses: this.getBoxClass(),
        height: this.props.height && this.props.height,
        display: this.props.children,
        width: width,
      });
    }

    renderSelected() {
      const width = this.cellWidth();

      return (
        <td
          key="cell-selected"
          className={this.props.classes.tableCell}
          style={{
            width: width,
            zIndex: 1, // so editor box will go ontop of other cells when it overflows
          }}
        >
          <div
            style={{
              width: width,
              height: this.props.height && this.props.height,
              padding: 0,
            }}
          >
            {this.renderInnerCell(width)}
          </div>
        </td>
      );
    }

    renderNormalTableCell(width: number, inner: any) {
      if (!this.isEditable(this.props)) {
        return (
          <td
            key="cell-normal"
            className={this.props.classes.tableCell}
            style={{
              width: width,
            }}
          >
            {inner}
          </td>
        );
      }

      const oneClickToEdit = this.props.oneClickToEdit;

      return (
        <td
          key="cell-normal"
          className={this.props.classes.tableCell}
          style={{
            width: width,
          }}
          onMouseDown={() => {
            stateManager.onMouseDown(this.props.index, this.props.scheduleId);
          }}
          onMouseOver={() =>
            stateManager.onMouseOver(this.props.index, this.props.scheduleId)
          }
          onClick={(e) => {
            stateManager.onClick(
              e as any,
              this.props.index,
              this.props.scheduleId,
              oneClickToEdit || false
            );
          }}
        >
          {inner}
        </td>
      );
    }

    render() {
      if (this.state.selected) {
        return this.renderSelected();
      }

      const width = this.cellWidth();

      return this.renderNormalTableCell(
        width,
        <React.Fragment>
          {this.state.isRightCellInExtSelection && (
            <div key="label" className={this.props.classes.extLabel}>
              {this.state.extSelectionUserName}
            </div>
          )}
          {this.state.loading && (
            <div key="loader" className={this.props.classes.loadingWrapper}>
              <Loading />
            </div>
          )}
          {this.renderInnerCell(width)}
        </React.Fragment>
      );
    }
  }
) as any;

export function renderCell(params: {
  boxClasses: string;
  height: number | string | undefined;
  width: number;
  display: string | JSX.Element;
}) {
  var render;

  if (typeof params.display === "string") {
    render = (
      <span
        style={{ whiteSpace: "pre-wrap", wordBreak: "break-all" }}
        key="data"
        dangerouslySetInnerHTML={{ __html: params.display }}
      />
    );
  } else {
    render = params.display;
  }

  return (
    <div
      key="wrapper"
      className={params.boxClasses}
      style={{
        height: params.height,
        padding: "5px",
        maxWidth: params.width,
      }}
    >
      {render}
    </div>
  );
}
