import {
  _ScheduleItemWithDriverReportInfo,
  ScheduleItemWithDriverReportInfo,
  User,
} from "../../../api/types";
import { Item, weeklyNoteDateFormat, WeeklyNoteMap } from "./fetcher";
import moment, { Moment } from "moment";
import { DateRow } from "../layout/date-row";
import { heightCalculator } from "./height-calculator";

// it's a bit complicated with the sorting and what not to convert
// ScheduleItems to Items, so it's in a separate class
//
// insert date items between scheduleItems so there will
// exist separators for every date from start=max(now(), max(scheduleItems.localDate))
// till min(scheduleItems.localDate)
export class ScheduleItemsToItems {
  async convert(
    scheduleItems: _ScheduleItemWithDriverReportInfo[],
    drivers: User[],
    notes: WeeklyNoteMap,
    noFillerDates?: boolean
  ) {
    const fillDates = !noFillerDates;

    scheduleItems.sort((a, b) => {
      if (a.parsedLocalDate !== b.parsedLocalDate) {
        return a.parsedLocalDate - b.parsedLocalDate;
      }

      return a.adminSortOrder - b.adminSortOrder;
    });

    const result: Item[] = [
      {
        header: true,
        date: moment(0),
        dayIndex: null,
        scheduleItem: null,
        cellHeight: null,
        includeMonthSeparator: null,
        weeklyNote: null,
      },
    ];

    var pos: Moment;
    if (scheduleItems.length > 0) {
      pos = moment(scheduleItems[0].localDate).utc().startOf("day");
    } else {
      pos = moment().utc().startOf("day");
    }

    var dayIndex: number = 1;

    scheduleItems.map((item) => {
      const date = moment(item.localDate).utc().startOf("day");

      while (pos.isSameOrBefore(date)) {
        if (fillDates) {
          this.addDate(result, pos, notes);
        }

        pos.add(1, "day");
        dayIndex = 1;
      }

      result.push(this.convertToItem(item, drivers, dayIndex, pos));
      dayIndex++;
      return null;
    });

    const tStart = moment().add(1, "year").startOf("day");

    while (tStart.isAfter(pos)) {
      if (fillDates) {
        this.addDate(result, pos, notes);
      }

      pos.add(1, "day");
    }

    const heights = ["id,row_height"];
    const needsHeightCalc = result.filter(
      (r) => r.scheduleItem !== null && r.scheduleItem.rowHeight === 0
    );

    for (let i = 0; i < needsHeightCalc.length; i++) {
      const r = needsHeightCalc[i];
      if (r.scheduleItem === null) continue;
      r.cellHeight = await heightCalculator.calculate(r.scheduleItem);
      heights.push(r.scheduleItem.id + "," + r.cellHeight);
    }

    console.log(heights.join("\n"));
    heightCalculator.analyze();

    return result;
  }

  addDate(result: Item[], pos: Moment, notes: WeeklyNoteMap) {
    result.push({
      header: false,
      date: pos.clone(),
      scheduleItem: null,
      dayIndex: null,
      weeklyNote: null,
      includeMonthSeparator: pos.date() === 1,
      cellHeight: pos.date() === 1 ? DateRow.height * 2 : DateRow.height,
    });

    if (pos.isoWeekday() === 7) {
      const key = pos.format(weeklyNoteDateFormat);

      result.push({
        header: false,
        date: pos.clone(),
        scheduleItem: null,
        dayIndex: null,
        includeMonthSeparator: false,
        weeklyNote: notes[key] || { note: "", sunday: key },
        cellHeight: 150,
      });
    }
  }

  now: Moment = moment();

  convertToItem(
    scheduleItem: ScheduleItemWithDriverReportInfo,
    drivers: User[],
    dayIndex: number,
    pos: Moment
  ): Item {
    return {
      date: pos.clone(),
      scheduleItem: scheduleItem,
      dayIndex: dayIndex,
      header: false,
      weeklyNote: null,
      cellHeight: scheduleItem.rowHeight,
      includeMonthSeparator: null,
    };
  }

  timer: number = 0;
}
