import { ScheduleItem, User } from "../../../api/types";
import { Moment } from "moment";
import moment from "moment";
import SortIcon from "@material-ui/icons/Sort";
import * as React from "react";
import { EditJobTypeList } from "../layout/cell-editor/edit-job-type-list";

export type ScheduleItemKey =
  | "localDate"
  | "arrivalTime"
  | "pickupLocation"
  | "dropOffLocation"
  | "customer"
  | "driver"
  | "unitSize"
  | "unitId"
  | "purchaseOrderNumber"
  | "description"
  | "contacts"
  | "adminNotes"
  | "jobType"
  | "invoiceNumber";

export type ColumnName =
  | ScheduleItemKey
  | "controls"
  | "officeUse"
  | "jobOrder"
  | "status"
  | "files";

export const dateColumnFormat = "ddd MMM D";

interface ConstructFieldParams {
  name: ColumnName;
  header: JSX.Element | string;
  columnWidth: number;
}

export class Field {
  name: ColumnName;
  columnWidth: number;
  headerEl: JSX.Element | string;
  isDataField: boolean = false;

  constructor(params: ConstructFieldParams) {
    this.name = params.name;
    this.columnWidth = params.columnWidth;
    this.headerEl = params.header || "";
  }
}

export function parseDrivers(str: string): number[] {
  if (str === "") return [];
  let list: number[] = [];
  str.split(",").map((s) => {
    try {
      list.push(parseInt(s));
    } catch (e: any) {
      // ignore invalid
    }

    return null;
  });

  return list;
}

export function getDrivers(userList: User[], idStr: string): User[] {
  const ids = parseDrivers(idStr);
  return userList.filter((d) => ids.indexOf(d.id) !== -1);
}

export class DataField extends Field {
  isDataField: boolean = true;
  name: ScheduleItemKey;

  constructor(params: ConstructFieldParams & { name: ScheduleItemKey }) {
    super(params);
    this.name = params.name;
  }

  getValue(
    item: ScheduleItem,
    drivers: User[]
  ): {
    moment: Moment | undefined;
    string: string | undefined;
    display: string;
  } {
    switch (this.name) {
      case "localDate":
        const date = moment(item.localDate).utc();

        return {
          moment: date,
          string: undefined,
          display: date.format(dateColumnFormat),
        };
      case "contacts":
        item.contacts = item.contacts || [];
        const str =
          "<p>" +
          item.contacts.map((contact) => contact.description).join("</p><p>") +
          "</p>";

        return {
          moment: undefined,
          string: str,
          display: str,
        };
      case "driver":
        const driverNames = getDrivers(drivers, item.driver)
          .map((d) => d.name)
          .join(", ");

        return {
          moment: undefined,
          string: (item.driver || "").toString(),
          display: driverNames || "(none)",
        };
      default:
        return {
          moment: undefined,
          display: item[this.name],
          string: item[this.name],
        };
    }
  }
}

// fields to display, order is important
export const fields: (Field | DataField)[] = [
  new DataField({ name: "localDate", header: "Date", columnWidth: 130 }),
  new Field({ name: "jobOrder", header: <SortIcon />, columnWidth: 40 }),
  new DataField({ name: "driver", header: "Driver", columnWidth: 100 }),
  new DataField({ name: "customer", header: "Client", columnWidth: 100 }),
  new DataField({ name: "unitId", header: "Unit", columnWidth: 100 }),
  new DataField({ name: "unitSize", header: "Size", columnWidth: 100 }),
  new DataField({
    name: "arrivalTime",
    header: "Time",
    columnWidth: 100,
  }),
  new DataField({
    name: "jobType",
    header: (
      <div>
        Type <EditJobTypeList />
      </div>
    ),
    columnWidth: 70,
  }),
  new DataField({
    name: "pickupLocation",
    header: "From",
    columnWidth: 200,
  }),
  new DataField({
    name: "dropOffLocation",
    header: "To",
    columnWidth: 200,
  }),
  new Field({ name: "status", header: "Status", columnWidth: 70 }),
  new DataField({
    name: "description",
    header: "Description",
    columnWidth: 300,
  }),
  new Field({ name: "files", header: "Files", columnWidth: 65 }),
  new DataField({ name: "contacts", header: "Contacts", columnWidth: 300 }),
  new DataField({
    name: "purchaseOrderNumber",
    header: "PO Number",
    columnWidth: 130,
  }),
  new DataField({ name: "invoiceNumber", header: "Inv #", columnWidth: 68 }),
  new DataField({ name: "adminNotes", header: "Office Use", columnWidth: 200 }),
];

export function isDataField(input: Field): input is DataField {
  return input.isDataField;
}

export const dataFields: DataField[] = fields
  .filter((field) => isDataField(field))
  .map((field) => field as DataField);

export interface FieldValue {
  field: keyof ScheduleItem;
  value: string;
}

export function fieldValuesForIndexRange(
  startIndex: number,
  endIndex: number,
  source: string[]
): FieldValue[] {
  var out: FieldValue[] = [];
  var sourceIndex = 0;

  for (var i = startIndex; i <= endIndex; i++) {
    const field = fields[i];

    if (isDataField(field)) {
      out.push({
        field: field.name,
        value: source[sourceIndex],
      });
    }

    sourceIndex++;
  }

  return out;
}
