import * as React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, WithStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { totalWidth } from "../state/config";
import { fields } from "../state/field";
import { StyledComponent } from "./boxes/styled-component";

const styles = (theme: Theme) =>
  createStyles({
    title: {
      fontWeight: "bold",
      paddingLeft: "10px",
      paddingTop: "4px",
    },
    table: {
      position: "sticky",
      zIndex: 2,
      top: "0",
      left: "0",
      width: "100%",
      borderBottom: "1px solid " + theme.palette.grey["300"],
      boxShadow: "1px 1px 4px 0px #cacaca",
      paddingBottom: "3px",
      backgroundColor: "white",
    },
  });

type Props = {} & WithStyles<typeof styles>;
interface State {}

export const headerRowHeight = 47;

export const HeaderRow: StyledComponent<Props> = withStyles(styles)(
  class HeaderRow extends React.Component<Props, State> {
    render() {
      return (
        <table
          style={{ width: totalWidth }}
          className={this.props.classes.table}
        >
          <tbody>
            <tr>
              {fields.map((field) => (
                <td
                  key={field.name}
                  style={{ width: field.columnWidth }}
                  className={
                    !!field.headerEl ? this.props.classes.title : undefined
                  }
                >
                  {field.headerEl}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      );
    }
  }
) as any;
