import { Header } from "./Header";
import { GridRow } from "../schedule-editor/layout/grid-row";
import moment, { Moment } from "moment";
import { ScheduleItemWithDriverReportInfo } from "../../api/types";
import { ListControl, ListController } from "./ListControl";
import { default as React, useEffect, useRef, useState } from "react";
import { WeeklyNote } from "../../api/WeeklyNoteAPI";
import { weeklyNoteDateFormat } from "../schedule-editor/utils/fetcher";
import { DateRow } from "../schedule-editor/layout/date-row";
import { api } from "../../api/api";
import { EventEmitter } from "nate-react-api-helpers";
import { CircularProgress } from "@material-ui/core";

export function List(props: { items: ScheduleItemWithDriverReportInfo[] }) {
  const [data] = useState(() => {
    const manager = new DataManager();
    manager.ingest(props.items);
    manager.backgroundLoadMore();

    return manager;
  });

  const controllerRef = useRef<null | ListController>(null);

  const dataRef = useRef(data);
  console.log(`List: raw data len=${data.list.length}`);
  // @ts-ignore debugging
  window.dataRef = data;

  const [min, setMin] = useState(data.min);
  const [max, setMax] = useState(data.max);

  const loadingMore = useEmitterValue(data.loadingMore);

  useEffect(() => {
    const sub = data.minMaxUpdated.subscribe(() => {
      setMin(data.min);
      setMax(data.max);
    });

    return () => sub.cancel();
  }, [data]);

  return (
    <div
      style={{
        flex: 1,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Header />
      <ListControl
        minIndex={min}
        maxIndex={max}
        onViewingAtUpdated={(value) => {
          dataRef.current.viewingAt = value;
        }}
        controller={(r) => {
          controllerRef.current = r;
          if (r) {
            r.jumpTo(data.getTodayIndex());
          }
        }}
        getItemHeight={(index) => {
          const item = dataRef.current.get(index);
          if (!item) {
            console.warn(
              `missing getItemHeight at index ${index}. minIndex=0, maxIndex=${
                props.items.length - 1
              }`
            );
            return 150;
          }

          return item.cellHeight || 150;
        }}
        getItem={(index) => {
          const item = dataRef.current.get(index);
          return <ListItem item={item} index={index} />;
        }}
      />

      <div
        style={{
          position: "absolute",
          bottom: 10,
          right: 10,
          backgroundColor: "hsla(0,0%,100%,0.2)",
          padding: 3,
          display: "flex",
          alignItems: "center",
        }}
      >
        {loadingMore ? (
          <span style={{ paddingRight: 8 }}>
            <CircularProgress size={20} />
          </span>
        ) : null}
        <span>
          Loaded {data.list.length.toLocaleString()} records (
          {data.list[0].date.format("MMM YYYY")} -{" "}
          {data.list[data.list.length - 1].date.format("MMM YYYY")})
        </span>
      </div>
    </div>
  );
}

const ListItem = React.memo(function (props: {
  item: GridRowInput;
  index: number;
}) {
  const item = props.item;

  return (
    <GridRow
      isSearching={false}
      onJumpToRow={() => console.error("todo")}
      includeMonthSeparator={item.includeMonthSeparator}
      onDragStart={() => console.error("todo")}
      height={item.cellHeight}
      weeklyNote={item.weeklyNote}
      scheduleItem={item.scheduleItem}
      jobOrder={item.dayIndex}
      date={item.date}
      onChange={() => console.error("todo")}
    />
  );
});

class DataManager {
  list: GridRowInput[] = [];
  scheduleItemIndex: { [id: string]: GridRowInput } = {};
  loadBackgroundTriggered = false;

  minMaxUpdated = new EventEmitter();
  min: number = 0;
  max: number = -1;
  viewingAt: number = -1;
  loadingMore = new EventEmitter<boolean>();

  async backgroundLoadMore() {
    if (this.loadBackgroundTriggered) return;
    this.loadBackgroundTriggered = true;

    this.loadingMore.emit(true);

    try {
      const year = this.list[0].date.year();

      const thisYear = await api.schedule.listByYear(year, true);
      if (thisYear.list) this.ingest(thisYear.list);

      const lastYear = await api.schedule.listByYear(year - 1, true);
      if (lastYear.list) this.ingest(lastYear.list);

      this.loadingMore.emit(false);
    } catch (e) {
      console.error(e);
      this.loadingMore.emit(false);
    }
  }

  getTodayIndex() {
    const now = moment().valueOf();
    const best = this.list.reduce((best, item) => {
      if (!best) return item;

      const itemDiff = Math.abs(item.date.valueOf() - now);
      const bestDiff = Math.abs(best.date.valueOf() - now);

      if (itemDiff === bestDiff) {
        if (item.type === "date") return item;
        if (best.type === "date") return best;
      }

      if (itemDiff < bestDiff) return item;
      return best;
    }, null as null | GridRowInput);

    if (!best) return 0;
    return this.list.indexOf(best) + this.min;
  }

  ingest(list: ScheduleItemWithDriverReportInfo[]) {
    let newList = this.list.slice(0);

    for (let i = 0; i < list.length; i++) {
      const item = list[i];

      const obj: GridRowInput = {
        type: "scheduleItem",
        cellHeight: item.rowHeight,
        date: moment(item.localDate).utc().startOf("day"),
        dayIndex: 0,
        includeMonthSeparator: false,
        weeklyNote: null,
        scheduleItem: item,
      };

      newList.push(obj);
      this.scheduleItemIndex[item.id] = obj;
    }

    // order by date ascending
    newList.sort((a, b) => {
      if (a.date.valueOf() !== b.date.valueOf()) {
        return a.date.valueOf() - b.date.valueOf();
      }

      if (a.type !== b.type) {
        return typeOrder[a.type] - typeOrder[b.type];
      }

      if (b.type === "scheduleItem" && a.type === "scheduleItem") {
        return a.scheduleItem!.adminSortOrder - b.scheduleItem!.adminSortOrder;
      }

      return 0;
    });

    // add dates and weekly notes
    let lastDateObj: Moment | null = null;
    let dayIndex = 1;

    const listCopy = newList;
    newList = [];
    listCopy.map((item, index) => {
      if (item.type === "scheduleItem") {
        if (lastDateObj === null) {
          lastDateObj = item.date.clone();
        }

        while (lastDateObj.isBefore(item.date)) {
          const currentDate = lastDateObj.clone().add(1, "day");

          newList.push(this.makeDate(currentDate));

          if (currentDate.isoWeekday() === 7) {
            newList.push(this.makeWeeklyNote(currentDate));
          }

          lastDateObj.add(1, "day");
          dayIndex = 1;
        }

        item.dayIndex = dayIndex;
        dayIndex++;
      } else if (item.type === "date") {
        lastDateObj = item.date.clone();
      }

      newList.push(item);
    });

    const now = moment();

    while (
      newList.length === 0 ||
      newList[newList.length - 1].date.isBefore(now)
    ) {
      const lastDate = newList[newList.length - 1]?.date || moment();
      const current = lastDate.clone().add(1, "day");

      newList.push(this.makeDate(current));

      if (current.isoWeekday() === 7) {
        newList.push(this.makeWeeklyNote(current));
      }
    }

    if (this.viewingAt === -1) {
      this.min = 0;
      this.max = newList.length - 1;
    } else {
      const i = newList.indexOf(this.get(this.viewingAt));
      if (i === -1) {
        console.warn("don't know how to handle wrong 'at' index");
      } else {
        console.log(
          `update min/max viewingAt=${this.viewingAt}, actualIndex=${i}`
        );
        this.min = this.viewingAt - i;
        this.max = this.min + newList.length - 1;
      }
    }

    this.list = newList;
    this.minMaxUpdated.emit(true);
  }

  makeDate(currentDate: Moment): GridRowInput {
    return {
      type: "date",
      cellHeight:
        currentDate.date() === 1 ? DateRow.height * 2 : DateRow.height,
      date: currentDate.clone(),
      dayIndex: 0,
      includeMonthSeparator: currentDate.date() === 1,
      weeklyNote: null,
      scheduleItem: null,
    };
  }

  makeWeeklyNote(currentDate: Moment): GridRowInput {
    return {
      type: "weeklyNote",
      cellHeight: 150,
      date: currentDate.clone(),
      dayIndex: 0,
      includeMonthSeparator: false,
      weeklyNote: {
        note: "",
        sunday: currentDate.format(weeklyNoteDateFormat),
      },
      scheduleItem: null,
    };
  }

  get(index: number): GridRowInput {
    return this.list[index - this.min];
  }
}

const typeOrder = {
  date: 0,
  weeklyNote: 1,
  scheduleItem: 2,
};

type GridRowInput = {
  type: "scheduleItem" | "weeklyNote" | "date";
  cellHeight: number | null;
  date: moment.Moment;
  dayIndex: number;
  includeMonthSeparator: boolean;
  weeklyNote: WeeklyNote | null;
  scheduleItem: ScheduleItemWithDriverReportInfo | null;
};

export function useEmitterValue<T>(input: EventEmitter<T>) {
  const [value, setValue] = useState(input.lastValue);
  useEffect(() => {
    const sub = input.subscribe(setValue);
    return () => sub.cancel();
  }, [input]);

  return value;
}
