import { Fetcher } from "./fetcher";
import { ErrResponse, Vehicle } from "./types";

export class VehicleAPI {
  fetcher: Fetcher;
  constructor(fetcher: Fetcher) {
    this.fetcher = fetcher;
  }

  create(user: Vehicle): Promise<Vehicle[] | null | ErrResponse> {
    return this.fetcher.post<Vehicle[] | null>("/api/vehicle", user);
  }

  list(): Promise<Vehicle[] | null | ErrResponse> {
    return this.fetcher.get<Vehicle[] | null>("/api/vehicles");
  }

  edit(user: Vehicle): Promise<Vehicle[] | null | ErrResponse> {
    return this.fetcher.put<Vehicle[] | null>("/api/vehicle", user);
  }
}
