import * as React from "react";
import { createStyles, WithStyles } from "@material-ui/styles";
import withStyles from "@material-ui/styles/withStyles";
import { totalWidth } from "../state/config";
import { Theme } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import moment, { Moment } from "moment";
import { StyledComponent } from "./boxes/styled-component";

const styles = (theme: Theme) =>
  createStyles({
    row: {
      borderCollapse: "collapse",
      width: totalWidth,

      "& td": {
        borderCollapse: "collapse",
        borderWidth: 1,
        borderStyle: "solid",
        borderBottom: "none",
        borderColor: theme.palette.grey["300"],

        "&:first-child": {
          borderLeft: "none",
        },

        "& > div": {
          padding: "5px",
        },
      },
    },
    currentWeek: {
      "& td:nth-child(1)": {
        backgroundColor: blue["200"],
      },
    },
  });

type Props = {
  children: any;
  backgroundColor?: string;
  date?: Moment | null;
  noFade?: boolean;
  ignoreCurrentWeek?: boolean;
} & WithStyles<typeof styles>;

const fiftyPercentOpacityHexSuffix = "80";

export const Row: StyledComponent<Props> = withStyles(styles)(
  class Row extends React.PureComponent<Props> {
    getBackgroundColor(): string {
      if (!this.props.backgroundColor) return "auto";
      if (this.props.noFade) return this.props.backgroundColor;
      return this.props.backgroundColor + fiftyPercentOpacityHexSuffix;
    }

    render() {
      const currentWeek = this.props.date && isCurrentWeek(this.props.date);
      return (
        <table
          className={this.props.classes.row}
          style={{ backgroundColor: this.getBackgroundColor() }}
        >
          <tbody>
            <tr
              className={
                currentWeek
                  ? this.props.ignoreCurrentWeek
                    ? undefined
                    : this.props.classes.currentWeek
                  : undefined
              }
            >
              {this.props.children}
            </tr>
          </tbody>
        </table>
      );
    }
  }
) as any;

function weekFormat(date: Moment) {
  return date.week() + "-" + date.year();
}

function isCurrentWeek(date: Moment) {
  const now = moment();
  return weekFormat(date) === weekFormat(now);
}
