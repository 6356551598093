import { WebSocketManager } from "../misc/websocket";
import { EventEmitter } from "../misc/event-emitter";
import { DateString, ScheduleItemWithDriverReportInfo } from "../api/types";
import { EtrakEventSource } from "../pages/schedule-editor/state/state-manager";

export type SelectCellDetails =
  | {
      type: "weekly-note";
      weeklyNoteSunday: string;
    }
  | {
      type: "schedule";
      scheduleId: number;
      selectIndexStart: number;
      selectIndexEnd: number;
    };

export interface SelectCellAction {
  action: "select-cell";
  selectCell: SelectCellDetails;
  sender: Sender;
  source: EtrakEventSource;
}

interface Sender {
  name: string;
  sessionId: number;
}

export interface ScheduleUpdateAction {
  action: "edit-schedule";
  editSchedule: {
    scheduleItem: ScheduleItemWithDriverReportInfo;
  };
  sender: Sender;
}

export interface ScheduleUpdateWeeklyNoteAction {
  action: "update-weekly-note";
  updateWeeklyNote: {
    note: string;
    sunday: string;
  };
  sender: Sender;
}

export interface ScheduleRemoveAction {
  action: "remove-schedule";
  removeSchedule: number;
  sender: Sender;
}

export interface ScheduleReOrderAction {
  action: "re-order-schedule";
  reOrderSchedule: {
    orderUpdates: { [scheduleId: number]: number };
    dateUpdates: { [scheduleId: number]: DateString };
  };
}

export class WebSocketInteraction {
  ws: WebSocketManager;
  externalHighlightEvents = new EventEmitter<SelectCellAction>();
  externalScheduleUpdateEvents = new EventEmitter<ScheduleUpdateAction>();
  externalScheduleRemovedEvents = new EventEmitter<ScheduleRemoveAction>();
  externalScheduleReOrderEvents = new EventEmitter<ScheduleReOrderAction>();
  updateWeeklyNote = new EventEmitter<ScheduleUpdateWeeklyNoteAction>();

  constructor() {
    this.ws = new WebSocketManager((data) => this.onReceive(data as any));
  }

  selectedCell(params: SelectCellDetails) {
    this.ws.send({
      action: "select-cell",
      selectCell: params,
    });
  }

  onReceive(
    data:
      | SelectCellAction
      | ScheduleUpdateAction
      | ScheduleRemoveAction
      | ScheduleReOrderAction
      | ScheduleUpdateWeeklyNoteAction
  ) {
    switch (data.action) {
      case "select-cell":
        this.externalHighlightEvents.emit(data);
        return;
      case "edit-schedule":
        this.externalScheduleUpdateEvents.emit(data);
        return;
      case "update-weekly-note":
        this.updateWeeklyNote.emit(data);
        return;
      case "remove-schedule":
        this.externalScheduleRemovedEvents.emit(data);
        return;
      case "re-order-schedule":
        this.externalScheduleReOrderEvents.emit(data);
        return;
      default:
        console.log("unhandled websocket event", data);
    }
  }
}
