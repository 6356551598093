import { Fetcher } from "./fetcher";
import { DriverReportWithExtrasResponse, ErrResponse } from "./types";
import moment from "moment";

export class DriverReportAPI {
  fetcher: Fetcher;

  constructor(fetcher: Fetcher) {
    this.fetcher = fetcher;
  }

  list(
    weekOf: Date
  ): Promise<DriverReportWithExtrasResponse[] | null | ErrResponse> {
    return this.fetcher.get<DriverReportWithExtrasResponse[] | null>(
      "/api/driver-report/list",
      {
        "start-date": moment(weekOf).startOf("week").format("YYYY-MMM-DD"),
        "end-date": moment(weekOf).endOf("week").format("YYYY-MMM-DD"),
      }
    );
  }

  search(
    search: string
  ): Promise<DriverReportWithExtrasResponse[] | null | ErrResponse> {
    return this.fetcher.get<DriverReportWithExtrasResponse[] | null>(
      "/api/driver-report/list",
      {
        search: search,
      }
    );
  }

  get(id: number) {
    return this.fetcher.get<DriverReportWithExtrasResponse>(
      "/api/driver-report",
      {
        id: id,
      }
    );
  }

  download(ids: number[], fileName: string) {
    this.fetcher.download("POST", "/api/driver-report/download-list", {
      ids: ids.join(","),
      fileName: fileName,
    });
  }

  setAdminNotes(params: { note: string; driverReportId: number }) {
    return this.fetcher.put<DriverReportWithExtrasResponse>(
      "/api/driver-report/set-admin-notes",
      params
    );
  }
}
