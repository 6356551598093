import { DateRow } from "./date-row";
import { DataRow } from "./data-row";
import { api } from "../../../api/api";
import * as React from "react";
import {
  ScheduleItem,
  ScheduleItemWithDriverReportInfo,
} from "../../../api/types";
import { Moment } from "moment";
import { MouseEvent } from "react-window-sortable";
import { heightCalculator } from "../utils/height-calculator";
import { useContext } from "react";
import { UndoTrackerContext } from "../utils/undo";
import { stringifyFieldValue } from "../state/state-manager";
import { WeeklyNote } from "../../../api/WeeklyNoteAPI";
import { WeeklyNoteRow } from "./weekly-note-row";

export function GridRow(props: {
  height: number | null;
  date: Moment;
  scheduleItem: ScheduleItemWithDriverReportInfo | null;
  weeklyNote: WeeklyNote | null;
  jobOrder: number | null;
  isSearching: boolean;
  includeMonthSeparator: boolean;

  onJumpToRow(): void;
  onDragStart(e: MouseEvent): void;
  onChange(item: ScheduleItemWithDriverReportInfo): void;
}) {
  const trackingCtx = useContext(UndoTrackerContext);

  if (props.weeklyNote !== null) {
    return <WeeklyNoteRow note={props.weeklyNote} cellHeight={props.height} />;
  }

  if (props.scheduleItem === null || props.jobOrder === null) {
    return (
      <DateRow
        key={props.date.unix().toString()}
        date={props.date}
        includeMonthSeparator={props.includeMonthSeparator}
      />
    );
  }

  const scheduleId = props.scheduleItem.id;

  return (
    <DataRow
      jobOrder={props.jobOrder}
      onDragStart={props.onDragStart}
      key={scheduleId}
      height={props.height === null ? null : props.height - 2}
      date={props.date}
      data={props.scheduleItem}
      isSearching={props.isSearching}
      onJumpToRow={props.onJumpToRow}
      onMakeUpdate={async (field: keyof ScheduleItem, value: string) => {
        if (!props.scheduleItem) throw new Error("invalid scheduleItem");

        const scheduleId = props.scheduleItem.id;

        const oldHeight = props.height
          ? props.height
          : await heightCalculator.calculate(props.scheduleItem);
        const oldValue = stringifyFieldValue(props.scheduleItem, field);
        const height = await heightCalculator.calculate(props.scheduleItem);

        const newItem = await trackingCtx.runWithTracking({
          run: () =>
            api.schedule.updateField({
              changes: [
                {
                  field: field,
                  value: value,
                },
              ],
              rowHeight: height,
              scheduleId: scheduleId,
            }),
          undo: () =>
            api.schedule.updateField({
              changes: [
                {
                  field: field,
                  value: oldValue,
                },
              ],
              rowHeight: oldHeight,
              scheduleId: scheduleId,
            }),
        });

        props.onChange(newItem);
        return newItem;
      }}
    />
  );
}
