import { File as FileEntity } from "../../../../../../api/types";
import { useCallback, useState } from "react";
import { api } from "../../../../../../api/api";
import { showSnack } from "../../../../../../components/snacker";
import { FileWithSignedInfo } from "../../../../../../api/files";

export function useUploadSignedFiles(input: {
  onFilesUploaded(files: FileEntity[]): void;
}) {
  const { onFilesUploaded } = input;
  const [filesBeingUploaded, setFilesBeingUploaded] = useState<FileEntity[]>(
    []
  );

  const upload = useCallback(
    async (list: FileWithSignedInfo[]) => {
      const files = list.map((file) => {
        return {
          id: 0,
          name: file.file.name,
        } as FileEntity;
      });

      setFilesBeingUploaded((existing) => [...existing, ...files]);

      try {
        const newFiles = await api.files.uploadSigned(list);
        if (newFiles == null) throw new Error("No files made it to the server");

        onFilesUploaded(newFiles);
      } catch (e: any) {
        showSnack("Failed to upload file: " + e.message);
      }

      setFilesBeingUploaded((existing) =>
        existing.filter((item) => files.indexOf(item) === -1)
      );
    },
    [onFilesUploaded, setFilesBeingUploaded]
  );

  return {
    upload,
    filesBeingUploaded,
  };
}
