import { ListItemText } from "@material-ui/core";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { SignedFileAction } from "./single-file-action";
import { FileAction } from "./file-action";
import ListItem from "@material-ui/core/ListItem";
import React, { useCallback, useMemo } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import { createStyles } from "@material-ui/styles";
import { File as FileEntity } from "../../../../../../api/types";

const useStyles = makeStyles((theme) =>
  createStyles({
    listSecondaryAction: {
      paddingRight: "150px",
    },
  })
);

export function FileListItem(props: {
  isSignedDocs: boolean;
  jobName: string;
  file: FileEntity;
  extraAction?(file: FileEntity): JSX.Element | null;
  onFileArchived(file: FileEntity): void;
}) {
  const file = props.file;
  const styles = useStyles();
  const listItemClasses = useMemo(
    () => ({ secondaryAction: styles.listSecondaryAction }),
    [styles.listSecondaryAction]
  );
  const onArchived = useCallback(() => props.onFileArchived(file), [
    props,
    file,
  ]);

  return (
    <ListItem classes={listItemClasses}>
      <ListItemText primary={file.name} />
      <ListItemSecondaryAction>
        {props.isSignedDocs ? (
          <SignedFileAction
            id={file.id}
            docName={file.name}
            jobName={props.jobName}
          />
        ) : (
          <FileAction
            id={file.id}
            fileName={file.name}
            file={file}
            onArchive={onArchived}
            extraAction={props.extraAction}
          />
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
}
