import { RouteComponentProps } from "react-router";
// @ts-ignore
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

export enum Paths {
  ScheduleEditor = "/schedule-editor",
  Schedule = "/schedule",
  DriverReport = "/driver-report",
  Invoicing = "/invoicing",
  Users = "/users",
  Vehicles = "/vehicles",
}

export class Nav {
  static IsPath(testPath: string, pathConst: Paths): boolean {
    // this isn't a perfect check, but it'll work for now
    return testPath === pathConst || testPath.indexOf(pathConst + "/") === 0;
  }

  static WithId(path: Paths, id: number): string {
    return path + "/" + id;
  }

  static idPathTemplate(path: Paths) {
    return path + "/:id";
  }
}

export type RoutePropsWithID = RouteComponentProps<{ id: string | undefined }>;
