import React, { useState } from "react";
import { Dialog, DialogActions } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import {
  ScheduleItemWithDriverReportInfo,
  File,
} from "../../../../../api/types";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { api } from "../../../../../api/api";
import { Label } from "../../../../../components/label";
import { FileGroup } from "./file-group";
import { useActionButtons } from "../../../../../components/use-action-buttons";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { EmailToAutoComplete } from "./EmailToAutoComplete";

interface Props {
  data: ScheduleItemWithDriverReportInfo;
  onClose(): void;
}

function stripTags(str: string) {
  return str.replace(/(<([^>]+)>)/gi, "");
}

function Detail(props: { label: string; value?: string | null }) {
  return (
    <TextField
      label={props.label}
      type="text"
      InputProps={{ disableUnderline: true }}
      value={stripTags(props.value || " - ")}
      margin="normal"
      fullWidth
    />
  );
}

export function DocumentDialog(props: Props) {
  const [docsToSign, setDocsToSign] = useState<File[]>(
    props.data.documentsToSign || []
  );
  const [signedDocuments, setSignedDocuments] = useState<File[]>(
    props.data.signedDocuments || []
  );
  const [backgroundDocuments, setBackgroundDocuments] = useState<File[]>(
    props.data.backgroundDocuments || []
  );
  const [to, setTo] = useState(props.data.notifyOnDocumentSignEmailsCSV);
  const [jobName, setJobName] = useState(
    props.data.notifyOnDocumentSignJobName
  );

  const { Actions } = useActionButtons({
    onSave: async () => {
      await api.schedule.updateFiles({
        schedule: props.data.id,
        toSignFiles: docsToSign.map((file) => file.id),
        backgroundFiles: backgroundDocuments.map((file) => file.id),
        signedFiles: signedDocuments.map((file) => file.id),
        notifyOnSignedEmailsCSV: to,
        notifyOnSignedJobName: jobName,
      });

      props.onClose();
    },
    onCancel: () => props.onClose(),
  });

  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth="md"
      onBackdropClick={() => props.onClose()}
    >
      <DialogContent>
        <Grid container direction="row" spacing={5} wrap="nowrap">
          <Grid item style={{ flexBasis: 0, flexGrow: 1 }}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Detail label="Customer" value={props.data.customer} />
              </Grid>
              <Grid item>
                <Detail
                  label="Contact"
                  value={
                    props.data.contacts &&
                    props.data.contacts.map((c) => c.description).join(", ")
                  }
                />
              </Grid>
              <Grid item>
                <FileGroup
                  label="Original Documents"
                  accept=".pdf"
                  onFilesUploaded={(files) =>
                    setDocsToSign([...docsToSign, ...files])
                  }
                  files={docsToSign}
                  onFileArchived={(file) => {
                    setDocsToSign(docsToSign.filter((f) => f !== file));
                  }}
                  jobName={jobName}
                  extraAction={(file) => (
                    <Tooltip title="Move to Background Documents">
                      <IconButton
                        onClick={() => {
                          setDocsToSign((docs) =>
                            docs.filter((d) => d !== file)
                          );
                          setBackgroundDocuments((docs) => [...docs, file]);
                        }}
                      >
                        <ArrowDownwardIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                />
              </Grid>
              <Grid item>
                <FileGroup
                  label="Background Documents"
                  onFilesUploaded={(files) =>
                    setBackgroundDocuments([...backgroundDocuments, ...files])
                  }
                  files={backgroundDocuments}
                  onFileArchived={(file) => {
                    setBackgroundDocuments(
                      backgroundDocuments.filter((f) => f !== file)
                    );
                  }}
                  jobName={jobName}
                  extraAction={(file) => {
                    if (file.name.toLowerCase().endsWith(".pdf")) {
                      return (
                        <Tooltip title="Move to Original Documents">
                          <IconButton
                            onClick={() => {
                              setBackgroundDocuments((docs) =>
                                docs.filter((d) => d !== file)
                              );
                              setDocsToSign((docs) => [...docs, file]);
                            }}
                          >
                            <ArrowUpwardIcon />
                          </IconButton>
                        </Tooltip>
                      );
                    }

                    return null;
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ flexBasis: 0, flexGrow: 1 }}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Detail label="From" value={props.data.pickupLocation} />
              </Grid>
              <Grid item>
                <Detail label="To" value={props.data.dropOffLocation} />
              </Grid>
              <Grid item>
                <FileGroup
                  label="Signed Documents"
                  accept=".pdf"
                  onFilesUploaded={(files) =>
                    setSignedDocuments([...signedDocuments, ...files])
                  }
                  files={signedDocuments}
                  onFileArchived={(file) => {
                    setSignedDocuments(
                      signedDocuments.filter((f) => f !== file)
                    );
                  }}
                  isSignedDocs
                  jobName={jobName}
                />
              </Grid>
              <Grid item>
                <Label>Document Signing Notification</Label>
                <Typography variant="body2">
                  Send document sign notifications with a copy of the original
                  document
                </Typography>

                <EmailToAutoComplete value={to} onChange={setTo} />

                <TextField
                  label="Job Name"
                  type="text"
                  value={jobName}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setJobName(e.target.value)}
                  margin="normal"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Actions />
      </DialogActions>
    </Dialog>
  );
}
