import IconButton from "@material-ui/core/IconButton";
import React, { useEffect, useState } from "react";
import { api } from "../../../../../../api/api";
import { useAsyncAction } from "../../../../../../api/async";
import makeStyles from "@material-ui/styles/makeStyles";
import { createStyles } from "@material-ui/styles";
import { Modal } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import grey from "@material-ui/core/colors/grey";
import Grid from "@material-ui/core/Grid";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloseIcon from "@material-ui/icons/Close";
import GetAppIcon from "@material-ui/icons/GetApp";

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      width: "100%",
      height: "100%",
    },
    toolbar: {
      background: grey["800"],
      color: "white",
    },
    iframe: {
      width: "100%",
      height: "100%",
    },
  })
);

export function GooglePreview(props: { id: number; fileName: string }) {
  const styles = useStyles();
  const [previewUrl, setPreviewUrl] = useState<string>();
  const loader = useAsyncAction(async () => {
    const path = await api.files.publicDownloadLink(props.id);
    const url = window.location.protocol + "//" + window.location.host + path;
    setPreviewUrl(
      "https://docs.google.com/viewerng/viewer?url=" + url + "&embedded=true"
    );
  }, [props]);
  const [iframeRef, setIframeRef] = useState<HTMLIFrameElement | null>(null);

  useEffect(() => {
    if (!iframeRef) return;
    if (!previewUrl) return;

    if (iframeRef.contentWindow) {
      // @ts-ignore
      iframeRef.contentWindow.location = previewUrl;
    }

    const int = setInterval(() => {
        if(iframeRef.contentWindow && iframeRef.contentWindow.location.toString() === "about:blank") {
            // @ts-ignore
            iframeRef.contentWindow.location = previewUrl;
        }
    }, 800)

    return () => clearInterval(int);
  }, [previewUrl, iframeRef]);

  return (
    <React.Fragment>
      <IconButton onClick={() => loader.callback(null)}>
        <VisibilityIcon />
      </IconButton>
      {(previewUrl || loader.LoadingElement) && (
        <Modal open={true}>
          <div className={styles.wrapper}>
            <Grid container direction="column" style={{ height: "100%" }}>
              <Grid item>
                <div className={styles.toolbar}>
                  <Toolbar variant="dense">
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="menu"
                          onClick={() => setPreviewUrl(undefined)}
                        >
                          <ArrowBackIcon />
                        </IconButton>
                      </Grid>
                      <Grid item xs>
                        <Typography variant="h6" color="inherit">
                          {props.fileName}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <IconButton
                          onClick={() => api.files.download(props.id)}
                        >
                          <GetAppIcon />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <IconButton
                          edge="end"
                          color="inherit"
                          aria-label="menu"
                          onClick={() => setPreviewUrl(undefined)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Toolbar>
                </div>
              </Grid>
              <Grid item xs>
                {previewUrl && (
                  <iframe
                    title="Document Preview"
                    ref={setIframeRef}
                    className={styles.iframe}
                    frameBorder={0}
                    loading="eager"
                  />
                )}
                {!previewUrl && loader.LoadingElement}
              </Grid>
            </Grid>
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
}
