import Dropzone from "react-dropzone";
import React, { PropsWithChildren, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme, Grid, CircularProgress } from "@material-ui/core";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import Typography from "@material-ui/core/Typography";
import { useUploadRegularFiles } from "./file-group/upload-regular-files";
import { File as FileEntity, ScheduleItem } from "../../../../../api/types";
import { useAsyncAction } from "../../../../../api/async";
import { api } from "../../../../../api/api";
import { showSnack } from "../../../../../components/snacker";
type DragState = "none" | "over";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    over: {},
    dropper: {
      width: "100%",
      height: "100%",
      border: "2px solid " + theme.palette.primary.main,
      borderRadius: 5,
      color: theme.palette.text.secondary,
    },
    loading: {
      padding: theme.spacing(2),
      paddingLeft: 0,
      paddingRight: 0,
    },
  })
);

export function PaperClipDropZone(
  props: PropsWithChildren<{ item: ScheduleItem }>
) {
  const [dragState, setDragState] = useState<DragState>("none");
  const styles = useStyles();
  const item = props.item;

  const linkFiles = useAsyncAction(
    async (added: FileEntity[]) => {
      const toSign = (item.documentsToSign || []).map((file) => file.id);
      toSign.push(...added.map((d) => d.id));

      try {
        await api.schedule.updateFiles({
          schedule: item.id,
          toSignFiles: toSign,
          backgroundFiles: (item.documentsToSign || []).map((file) => file.id),
          signedFiles: (item.documentsToSign || []).map((file) => file.id),
          notifyOnSignedEmailsCSV: item.notifyOnDocumentSignEmailsCSV,
          notifyOnSignedJobName: item.notifyOnDocumentSignJobName,
        });

        showSnack("Files uploaded");
      } catch (e: any) {
        showSnack("Failed to upload files");
      }
    },
    [item]
  );

  const regular = useUploadRegularFiles({
    onFilesUploaded: linkFiles.callback,
  });

  const loading = regular.filesBeingUploaded.length > 0 || linkFiles.loading;

  return (
    <Dropzone
      accept={".pdf"}
      onDrop={(acceptedFiles) => {
        setDragState("none");
        regular.upload(acceptedFiles);
      }}
      onDragEnter={() => setDragState("over")}
      onDragLeave={() => setDragState("none")}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          className={dragState === "none" ? "" : styles.over}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {dragState === "none" && !loading ? (
            <div onClick={(e) => e.stopPropagation()}>{props.children}</div>
          ) : (
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
              className={styles.dropper}
            >
              {!loading && (
                <Grid item>
                  <MoveToInboxIcon color="inherit" />
                </Grid>
              )}
              {!loading && (
                <Grid item>
                  <Typography variant="caption">PDF only</Typography>
                </Grid>
              )}
              {loading && (
                <Grid item>
                  <div className={styles.loading}>
                    <CircularProgress size={20} />
                  </div>
                </Grid>
              )}
            </Grid>
          )}
        </div>
      )}
    </Dropzone>
  );
}
