import { api } from "../../../api/api";
import {
  _ScheduleItemWithDriverReportInfo,
  ScheduleItemWithDriverReportInfo,
  User,
} from "../../../api/types";
import { Moment } from "moment";
import { ScheduleItemsToItems } from "./schedule-items-to-items";
import { stateManager } from "../state/state-manager";
import { WeeklyNote } from "../../../api/WeeklyNoteAPI";
import { ScheduleUpdateWeeklyNoteAction } from "../../../core/websocket";
import { EventEmitter } from "../../../misc/event-emitter";

export interface Item {
  scheduleItem: ScheduleItemWithDriverReportInfo | null;
  dayIndex: number | null;
  date: Moment;
  weeklyNote: WeeklyNote | null;
  includeMonthSeparator: boolean | null;
  header: boolean;
  cellHeight: number | null;
}

export class Fetcher {
  private chunkSize: number;
  drivers: User[] = [];
  weeklyNotes: WeeklyNoteMap = {};
  converter = new ScheduleItemsToItems();
  updatedWeeklyNote = new EventEmitter<void>();

  constructor(chunkSize: number) {
    this.chunkSize = chunkSize;
    stateManager.ws.updateWeeklyNote.subscribe((n) => this.updateWeeklyNote(n));
  }

  updateWeeklyNote(update: ScheduleUpdateWeeklyNoteAction) {
    const key = update.updateWeeklyNote.sunday;

    if (!this.weeklyNotes[key]) {
      this.weeklyNotes[key] = update.updateWeeklyNote;
    } else {
      this.weeklyNotes[key].note = update.updateWeeklyNote.note;
    }

    this.updatedWeeklyNote.emit();
  }

  async loadSchedule(): Promise<_ScheduleItemWithDriverReportInfo[]> {
    var scheduleItems: ScheduleItemWithDriverReportInfo[] = [];

    var nResults = this.chunkSize;
    while (nResults === this.chunkSize) {
      nResults = await this.fetchNext(scheduleItems);
    }

    return scheduleItems.map(stateManager.toInternalDriverReport);
  }

  async loadDrivers(): Promise<User[]> {
    const users = await api.users.list();
    if (users === null) return [];
    if ("error" in users) throw new Error(users.error);

    return users.filter((u) => u.isDriver);
  }

  async loadAll(): Promise<{
    items: Item[];
    weeklyNotes: WeeklyNoteMap;
    scheduleItems: ScheduleItemWithDriverReportInfo[];
    drivers: User[];
  }> {
    const scheduleData = this.loadSchedule();
    const driverData = this.loadDrivers();
    const weeklyNoteData = this.loadWeeklyNotes();

    const [schedules, drivers, weeklyNotes] = await Promise.all([
      scheduleData,
      driverData,
      weeklyNoteData,
    ]);
    this.drivers = drivers;
    this.weeklyNotes = weeklyNotes;

    return {
      items: await this.convertToItems(schedules, drivers),
      weeklyNotes,
      scheduleItems: schedules,
      drivers: drivers,
    };
  }

  async loadWeeklyNotes(): Promise<WeeklyNoteMap> {
    const list = await api.weeklyNotes.list();
    return list.reduce((acc, note) => {
      acc[note.sunday] = note;
      return acc;
    }, {} as { [k: string]: WeeklyNote });
  }

  convertToItems(
    scheduleItems: _ScheduleItemWithDriverReportInfo[],
    drivers: User[],
    noFillerDates?: boolean
  ) {
    return this.converter.convert(
      scheduleItems,
      drivers,
      this.weeklyNotes,
      noFillerDates
    );
  }

  async fetchNext(list: ScheduleItemWithDriverReportInfo[]): Promise<number> {
    const promises = [];
    const nConcurrent = 4;
    let offset = list.length;

    for (let i = 0; i < nConcurrent; i++) {
      promises.push(
        api.schedule.listPaginate({
          limit: this.chunkSize,
          offset: offset,
        })
      );

      offset += this.chunkSize;
    }

    const results = await Promise.all(promises);
    let listLengths: number[] = [];

    results.map((result) => {
      if (result.list !== null) {
        list.unshift(...result.list);
        listLengths.push(result.list.length);
      } else {
        listLengths.push(0);
      }

      return null;
    });

    return Math.min(...listLengths);
  }
}

export const weeklyNoteDateFormat = "YYYY-MM-DD";
export type WeeklyNoteMap = {
  // k => YYYY-MM-DD
  [k: string]: WeeklyNote;
};

export const fetcher = new Fetcher(250);
