import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { stateManager } from "../state/state-manager";

type Action<T = any> = () => Promise<T>;

interface Context {
  undo(): Promise<void>;
  redo(): Promise<void>;
  canUndo: boolean;
  canRedo: boolean;
}

export const UndoContext = createContext<Context>({
  undo: () => Promise.reject("fail"),
  redo: () => Promise.reject("fail"),
  canUndo: false,
  canRedo: false,
});

interface TrackerContext {
  runWithTracking: RunWithTracking;
}

export type RunWithTracking<T = any> = (input: {
  run: Action<T>;
  undo: Action;
}) => Promise<T>;

export const UndoTrackerContext = createContext<TrackerContext>({
  runWithTracking(obj) {
    return Promise.resolve(1) as any;
  },
});

export function UndoProvider(props: PropsWithChildren<{}>) {
  // the idea is to never change this state, but to manipulate the original
  // arrays. This prevents undo/redo from being re-calculated and re-rendering all the children
  const [undoList] = useState<{ undo: Action; redo: Action }[]>([]);
  const [redoList] = useState<{ undo: Action; redo: Action }[]>([]);

  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);

  const trackAction = useCallback(
    async (obj: { run: Action; undo: Action }) => {
      const result = await obj.run();
      undoList.push({
        undo: obj.undo,
        redo: obj.run,
      });
      setCanUndo(true);

      return result;
    },
    [undoList]
  );

  const redo = useCallback(async () => {
    const action = redoList.pop();
    if (!action) return;

    setCanRedo(false);
    setCanUndo(false);

    try {
      await action.redo();
      undoList.push(action);
      setCanRedo(redoList.length !== 0);
      setCanUndo(undoList.length !== 0);
    } catch (e: any) {
      setCanRedo(redoList.length !== 0);
      setCanUndo(undoList.length !== 0);
      throw e;
    }
  }, [redoList, undoList]);

  const undo = useCallback(async () => {
    const action = undoList.pop();
    if (!action) return;

    setCanRedo(false);
    setCanUndo(false);

    try {
      await action.undo();
      redoList.push(action);

      setCanRedo(redoList.length !== 0);
      setCanUndo(undoList.length !== 0);
    } catch (e: any) {
      setCanRedo(redoList.length !== 0);
      setCanUndo(undoList.length !== 0);
      throw e;
    }
  }, [redoList, undoList]);

  const ctx = useMemo(
    () => ({
      redo,
      undo,
      canUndo,
      canRedo,
    }),
    [redo, undo, canUndo, canRedo]
  );

  const trackerCtx = useMemo(
    () => ({
      runWithTracking: trackAction,
    }),
    [trackAction]
  );

  useEffect(() => {
    stateManager.runWithTracking = trackerCtx.runWithTracking;
  }, [trackerCtx]);

  return (
    <UndoContext.Provider value={ctx}>
      <UndoTrackerContext.Provider value={trackerCtx}>
        {props.children}
      </UndoTrackerContext.Provider>
    </UndoContext.Provider>
  );
}
