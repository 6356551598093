import AppBar from "@material-ui/core/AppBar";
import React from "react";
import {
  createStyles,
  Grid,
  Theme,
  Toolbar,
  WithStyles,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import { StyledComponent } from "../pages/schedule-editor/layout/boxes/styled-component";

const style = (theme: Theme) =>
  createStyles({
    header: {
      minHeight: 56,
    },
    title: {
      flexGrow: 1,
    },
  });

type Props = {
  title: string;
  rightContent?: JSX.Element;
  centerContent?: JSX.Element;
} & WithStyles<typeof style>;
interface State {}

export const HeaderBar: StyledComponent<Props> = withStyles(style)(
  class HeaderBar extends React.Component<Props, State> {
    constructor(props: any) {
      super(props);

      this.state = {};
    }

    render() {
      const hasCenterContent = !!this.props.centerContent;

      return (
        <AppBar position="static">
          <Toolbar variant="dense">
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className={this.props.classes.header}
            >
              <Grid item xs={hasCenterContent ? 5 : 3}>
                <Typography variant="h6" className={this.props.classes.title}>
                  {this.props.title}
                </Typography>
              </Grid>
              {hasCenterContent && (
                <Grid item xs={2} style={{ textAlign: "center" }}>
                  {this.props.centerContent}
                </Grid>
              )}
              <Grid item xs={hasCenterContent ? 5 : 9}>
                {this.props.rightContent}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      );
    }
  }
) as any;
