import { default as React, useCallback, useContext, useRef } from "react";
import { HeaderRow, headerRowHeight } from "./layout/header-row";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { GridRow } from "./layout/grid-row";
import { HighlightParams, stateManager } from "./state/state-manager";
import AutoSizer from "react-virtualized-auto-sizer";
import { ChildrenProps, SortableVariableSizeList } from "react-window-sortable";
import { Item } from "./utils/fetcher";
import { api } from "../../api/api";
import { showSnack } from "../../components/snacker";
import { DataRow } from "./layout/data-row";
import { config } from "./state/config";
import { Subscription } from "../../misc/event-emitter";
import { ListOnItemsRenderedProps } from "react-window";
import { IsSearchingContext } from "../schedule-editor";
import { ReOrderConfig } from "../../api/schedule";

interface Props {
  items: Item[];
  isSearching: boolean;
  onClearSearch(then: (input: Item[]) => void): void;
  onUpdateItemsFromRaw(): void;
  onScrollChange(params: ListOnItemsRenderedProps): void;
}
interface State {}

interface VisibleRow {
  index: number;
  element: HTMLDivElement | null;
}

export class List extends React.Component<Props, State> {
  listRef: SortableVariableSizeList | null = null;
  visibleRows: VisibleRow[] = [];

  constructor(props: any) {
    super(props);

    this.renderList = this.renderList.bind(this);
    this.updateListRef = this.updateListRef.bind(this);
    this.getItemSize = this.getItemSize.bind(this);
    this.sortOrderChanged = this.sortOrderChanged.bind(this);
  }

  callbacks: RowCallbacks = {
    onRowVisible: (row: VisibleRow) => {
      this.visibleRows.push(row);
    },
    onClearSearch: (then: (input: Item[]) => void) => {
      this.props.onClearSearch(then);
    },
    scrollToItem: this.scrollToItem.bind(this),
    onUpdateItemsFromRaw: this.props.onUpdateItemsFromRaw,
  };

  getListRef() {
    if (this.listRef === null || this.listRef.listRef.current === null)
      return null;
    return this.listRef.listRef.current as any;
  }

  getListScroller(): HTMLDivElement | null {
    const ref = this.getListRef();
    if (ref === null) return null;
    return ref._outerRef as HTMLDivElement;
  }

  getScrollOffsetToShowIndex(index: number): number | "no-scroll" | "fallback" {
    const row = this.visibleRows.find(
      (r) => r.index === index && r.element !== null
    );
    if (!row || row.element === null) return "fallback";

    const list = this.getListScroller();
    if (list === null) return "fallback";

    if (row.element.offsetTop - headerRowHeight < list.scrollTop) {
      return row.element.offsetTop - headerRowHeight;
    }

    if (
      row.element.offsetTop + row.element.offsetHeight >
      list.scrollTop + list.offsetHeight
    ) {
      return (
        row.element.offsetTop + row.element.offsetHeight - list.offsetHeight
      );
    }

    return "no-scroll";
  }

  scrollToItem(index: number) {
    const result = this.getScrollOffsetToShowIndex(index);
    if (result === "no-scroll") {
      console.log("no need to scroll, already showing that v-index");
      return;
    }

    const listRef = this.getListRef();
    if (!listRef) {
      console.error("missing listRef");
      return;
    }

    if (result === "fallback") {
      listRef.scrollToItem(index, "center");
      return;
    }

    listRef.scrollTo(result as number);
  }

  highlightEvents: Subscription | null = null;

  componentDidMount(): void {
    this.highlightEvents = stateManager.highlightEvents.subscribe((e) =>
      this.onItemHighlighted(e)
    );
  }

  componentWillUnmount(): void {
    if (this.highlightEvents) this.highlightEvents.unsubscribe();
  }

  componentWillReceiveProps(
    nextProps: Readonly<Props>,
    nextContext: any
  ): void {
    if (nextProps.items !== this.props.items) {
      this.forceReCalculateHeights();
    }
  }

  shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<State>,
    nextContext: any
  ): boolean {
    if (nextProps.items !== this.props.items) return true;
    if (nextProps.isSearching !== this.props.isSearching) return true;
    return false;
  }

  onItemHighlighted(e: HighlightParams) {
    // no need to autoscroll b/c they managed to click on it, so the cell must be visible
    if (e.source === "mouse") return;
    if (e.type !== "schedule") return;

    const index = this.props.items.findIndex((value) => {
      return (
        value.scheduleItem !== null && value.scheduleItem.id === e.scheduleId
      );
    });

    this.scrollToItem(index);

    // only do horizontal scrolling for since cell motions (because it's probably from a keyboard event)
    if (e.centerCells.length > 1) return;

    const div = this.getListScroller();
    if (div === null) return;

    var leftOffset = 0;
    const columnIndex = stateManager.highlightIndexToConfigColumnIndex(
      e.rightCell
    );

    for (var i = 0; i < columnIndex; i++) {
      leftOffset += config[i].width;
    }

    const rightOffset = leftOffset + config[columnIndex].width;

    // check if we're falling off the left side
    if (leftOffset < div.scrollLeft) {
      div.scrollLeft = leftOffset;
      return;
    }

    // check if we're falling off the right side
    if (rightOffset > div.offsetWidth + div.scrollLeft) {
      div.scrollLeft = rightOffset - div.offsetWidth;
      return;
    }
  }

  getItemSize(index: number): number {
    const item = this.props.items[index];

    if (item.cellHeight !== null) return item.cellHeight;
    if (item.scheduleItem !== null) return DataRow.height;
    if (item.header !== null) return headerRowHeight;

    return 0;
  }

  forceReCalculateHeights() {
    const listRef = this.getListRef();
    if (listRef !== null) {
      listRef.resetAfterIndex(0, false); // force list to recalculate heights on next render
    }
  }

  preReRenderScrollY: number = 0;
  preReRenderScrollX: number = 0;

  autoScrollToPreRenderState() {
    if (this.listRef === null) return;

    const div = this.getListScroller();
    if (div === null) return;
    if (this.preReRenderScrollY === 0 && this.preReRenderScrollX === 0) return;

    div.scrollTop = this.preReRenderScrollY;
    div.scrollLeft = this.preReRenderScrollX;

    this.preReRenderScrollY = 0;
    this.preReRenderScrollX = 0;
  }

  capturePreRenderScrollPosition() {
    const div = this.getListScroller();
    this.preReRenderScrollY = (div && div.scrollTop) || 0;
    this.preReRenderScrollX = (div && div.scrollLeft) || 0;
  }

  async sortOrderChanged({
    originalIndex,
    newIndex,
  }: {
    originalIndex: number;
    newIndex: number;
  }) {
    const srcItem = this.props.items[originalIndex];
    if (srcItem.scheduleItem === null) {
      console.warn("can't move a non-schedule-item");
      return;
    }
    if (originalIndex === newIndex) {
      console.info("didn't change the position");
      return;
    }

    let newSpot = this.props.items[newIndex];
    let insertBefore = newIndex < originalIndex;

    // can't do insert before date, so convert it to an insert-after
    if (newSpot.scheduleItem === null && insertBefore) {
      insertBefore = false;
      newIndex = Math.max(newIndex - 1, 0);
      newSpot = this.props.items[newIndex];
    }

    return this.doSortUpdate(srcItem.scheduleItem.id, newSpot, insertBefore);
  }

  getUndoSortConfig(srcScheduleId: number) {
    const index = this.props.items.findIndex(
      (v) => v.scheduleItem?.id === srcScheduleId
    );
    const dateFormat = "YYYY-MMM-DD";

    if (index !== 0) {
      const prevValue = this.props.items[index - 1];

      if (prevValue.scheduleItem !== null) {
        return {
          scheduleId: srcScheduleId,
          afterScheduleId: prevValue.scheduleItem.id,
        };
      }
    }

    if (index + 1 < this.props.items.length) {
      const nextValue = this.props.items[index + 1];
      if (nextValue.scheduleItem !== null) {
        return {
          scheduleId: srcScheduleId,
          beforeScheduleId: nextValue.scheduleItem.id,
        };
      }
    }

    const prevValue = this.props.items[index - 1];
    return {
      scheduleId: srcScheduleId,
      newDate: prevValue.date.format(dateFormat),
    };
  }

  async doSortUpdate(
    srcScheduleId: number,
    newSpot: Item,
    insertBefore: boolean
  ): Promise<void> {
    const dateFormat = "YYYY-MMM-DD";
    const undoSortConfig = this.getUndoSortConfig(srcScheduleId);

    let doConfig: ReOrderConfig;
    if (newSpot.scheduleItem !== null) {
      doConfig = {
        scheduleId: srcScheduleId,
        [insertBefore ? "beforeScheduleId" : "afterScheduleId"]:
          newSpot.scheduleItem.id,
      };
    } else {
      doConfig = {
        scheduleId: srcScheduleId,
        newDate: newSpot.date.format(dateFormat),
      };
    }

    try {
      await stateManager.runWithTracking({
        run: () => api.schedule.reOrder(doConfig),
        undo: () => api.schedule.reOrder(undoSortConfig),
      });

      showSnack("Successfully updated");
    } catch (e: any) {
      showSnack("Failed to update");
    }
  }

  updateListRef(r: SortableVariableSizeList) {
    this.listRef = r;
    this.autoScrollToPreRenderState();
  }

  renderList(params: { height: number; width: number }) {
    const { height, width } = params;
    console.log("inner", this.props.items);

    return (
      <SortableVariableSizeList
        key="sortable-list"
        ref={this.updateListRef}
        style={{ backgroundColor: "white" }}
        height={height}
        itemData={this.props.items}
        itemCount={this.props.items.length}
        itemSize={this.getItemSize}
        width={width}
        onSortOrderChanged={this.sortOrderChanged}
        onItemsRendered={this.props.onScrollChange}
        innerElementType={ListInnerElement}
        itemKey={generateKey}
      >
        {ListItem}
      </SortableVariableSizeList>
    );
  }

  render() {
    this.visibleRows = [];
    return (
      <RowCallbackContext.Provider value={this.callbacks}>
        <HasNoSearchResultsContext.Provider
          value={
            this.props.items.length < 5 &&
            this.props.items.filter((item) => !item.header).length === 0 &&
            this.props.isSearching
          }
        >
          <AutoSizer key="autosizer">
            {(params) => this.renderList(params)}
          </AutoSizer>
        </HasNoSearchResultsContext.Provider>
      </RowCallbackContext.Provider>
    );
  }
}

export const HasNoSearchResultsContext = React.createContext<boolean>(false);

function ListInnerElement(props: { children: any }) {
  const { children, ...rest } = props;
  const hasNoSearchResults = useContext(HasNoSearchResultsContext);

  return (
    <div key="wrapper" {...rest}>
      <HeaderRow key="header-row" />
      {hasNoSearchResults && (
        <Grid
          container
          direction="row"
          justify="center"
          style={{ marginTop: "30px" }}
        >
          <Grid item>
            <Typography color="textSecondary">No results</Typography>
          </Grid>
        </Grid>
      )}
      {children}
    </div>
  );
}

function generateKey(index: number, data: Item[]): string {
  const item = data[index];

  if (item.scheduleItem !== null)
    return "schedule-item-" + item.scheduleItem.id;
  if (item.weeklyNote !== null) return "weekly-note-" + item.weeklyNote.sunday;
  if (item.date !== null) {
    return "moment-" + item.date.toISOString();
  }
  return "other-" + index.toString();
}

interface RowCallbacks {
  onRowVisible(row: VisibleRow): void;
  onClearSearch(then?: (input: Item[]) => void): void;
  scrollToItem(index: number): void;
  onUpdateItemsFromRaw(): void;
}

const RowCallbackContext = React.createContext<RowCallbacks>({
  onClearSearch: () => {},
  scrollToItem: () => {},
  onUpdateItemsFromRaw: () => {},
  onRowVisible: () => {},
});

const ListItem = React.memo(
  React.forwardRef(function (props: ChildrenProps, ref: React.Ref<any>) {
    const { index, style, onSortMouseDown } = props;
    const data = props.data as Item[];

    const item = data[index];

    const isSearching = useContext(IsSearchingContext);
    const { onClearSearch, scrollToItem, onRowVisible, onUpdateItemsFromRaw } =
      useContext(RowCallbackContext);

    const onJumpToRow = useCallback(() => {
      onClearSearch((items) => {
        if (item.scheduleItem === null) return;

        const id = item.scheduleItem.id;
        const index = items.findIndex(
          (item) => item.scheduleItem !== null && item.scheduleItem.id === id
        );

        scrollToItem(index);
      });
    }, [scrollToItem, onClearSearch, item.scheduleItem]);

    const onChange = useCallback(
      (newItem) => {
        stateManager.updateOrAddScheduleItem(newItem);
        onUpdateItemsFromRaw();
      },
      [onUpdateItemsFromRaw]
    );

    if (item.header) return null;

    const row: VisibleRow = { element: null, index: index };
    onRowVisible(row);

    return (
      <div
        style={style as any}
        ref={(r) => {
          row.element = r;
          if (!ref) return;
          if (ref instanceof Function) return ref(r);
          // @ts-ignore
          ref.current = r;
        }}
      >
        <GridRow
          isSearching={isSearching}
          onJumpToRow={onJumpToRow}
          includeMonthSeparator={item.includeMonthSeparator === true}
          onDragStart={onSortMouseDown}
          height={item.cellHeight}
          weeklyNote={item.weeklyNote}
          scheduleItem={item.scheduleItem}
          jobOrder={item.dayIndex}
          date={item.date}
          onChange={onChange}
        />
      </div>
    );
  })
);
