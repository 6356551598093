import { HeaderBar } from "./header-bar";
import { Sidebar } from "./sidebar";
import React from "react";

interface Props {
  title: string;
  rightContent?: JSX.Element;
  centerContent?: JSX.Element;
  noPadding?: boolean;
}

export class PageContent extends React.Component<Props, {}> {
  render() {
    return (
      <div>
        <HeaderBar
          title={this.props.title}
          rightContent={this.props.rightContent}
          centerContent={this.props.centerContent}
        />
        <Sidebar noPadding={this.props.noPadding}>
          {this.props.children}
        </Sidebar>
      </div>
    );
  }
}
