import React, { PropsWithChildren } from "react";
import { Warning } from "@material-ui/icons";
import { Grid } from "@material-ui/core";
import { yellow } from "@material-ui/core/colors";

export function Warn(props: PropsWithChildren<{}>) {
  return (
    <Grid container spacing={1}>
      <Grid item style={{ fontSize: "1rem", color: yellow["700"] }}>
        <div style={{ height: "1em" }}>
          <Warning fontSize="inherit" color="inherit" />
        </div>
      </Grid>
      <Grid item>{props.children}</Grid>
    </Grid>
  );
}
