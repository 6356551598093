import * as React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { createStyles } from "@material-ui/core";
import { Link, LinkProps } from "react-router-dom";
import { StyledComponent } from "../pages/schedule-editor/layout/boxes/styled-component";

const styles = createStyles({
  link: {
    textDecoration: "none",
    color: "inherit",
  },
});

type Props = LinkProps & WithStyles<typeof styles>;

export const MLink: StyledComponent<Props> = withStyles(styles)(
  class MLink extends React.Component<Props, {}> {
    render() {
      const { classes, ...props } = this.props;
      return <Link className={classes.link} {...props} />;
    }
  }
) as any;
