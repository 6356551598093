import * as React from "react";
import { Moment } from "moment";
import { Row } from "./row";
import { AddRowBox } from "./boxes/add-row-box";
import { MonthSeparator } from "./month-separator";
import { DateCell } from "./date-cell";

interface Props {
  date: Moment;
  includeMonthSeparator: boolean;
}

export class DateRow extends React.Component<Props, {}> {
  // height in px this row will render to
  static height: number = 40;

  render() {
    const date = this.props.date.utc();

    return (
      <React.Fragment>
        {this.props.includeMonthSeparator && (
          <Row>
            <MonthSeparator date={date} />
          </Row>
        )}
        <Row
          backgroundColor="rgb(255,255,12)"
          ignoreCurrentWeek
          noFade
          date={this.props.date}
        >
          <DateCell date={this.props.date} />
          <AddRowBox date={this.props.date} />
        </Row>
      </React.Fragment>
    );
  }
}
