import { Autocomplete } from "@material-ui/lab";
import React, { useMemo, useState } from "react";
import { TextField } from "@material-ui/core";
import { api } from "../../../../../api/api";
import { useAsync } from "../../../../../api/async";

export function EmailToAutoComplete(props: {
  value: string;
  onChange(value: string): void;
}) {
  const [value, setValue] = useState("");

  const search = useAsync(
    async () => {
      if (!value) return [];
      const emails = await api.schedule.getEmails({ search: value });

      if (emails.indexOf(value) === -1) {
        emails.push(value);
      }

      return emails;
    },
    {
      dependancies: [value],
    }
  );

  return (
    <Autocomplete
      renderInput={(props) => {
        props.InputLabelProps = Object.assign(props.InputLabelProps, {
          shrink: true,
        });

        return <TextField {...props} label="To" margin="normal" fullWidth />;
      }}
      autoSelect
      fullWidth
      value={props.value}
      options={search.asList}
      onInputChange={(_, value, reason) => {
        setValue(value);
      }}
      onChange={(e, value, misc) => {
        setValue("");
        props.onChange(value || "");
      }}
      freeSolo
    />
  );
}
