import { DriverReportResponse } from "../../../api/types";
import { getHours } from "./ScheduleDetail";
import React from "react";
import { Warn } from "./Warn";

export function ExtraHoursWarning(props: { reports: DriverReportResponse[] }) {
  const hrs = props.reports.reduce(
    (acc, d) => acc + getHours(d.hoursOnSite),
    0
  );
  if (hrs <= 0.5) return null;

  return <Warn>Bill for extra time ({hrs} hrs)</Warn>;
}
