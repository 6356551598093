import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";
import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import { alpha } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import { StyledComponent } from "../pages/schedule-editor/layout/boxes/styled-component";

const styles = (theme: Theme) =>
  createStyles({
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    searchIcon: {
      width: theme.spacing(7),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: 120,
        "&:focus": {
          width: 200,
        },
      },
    },
  });

type Props = WithStyles<typeof styles> & {
  search: string;
  onChange(str: string): void;
};

interface State {}

export const AppBarSearch: StyledComponent<Props> = withStyles(styles)(
  class AppBarSearch extends React.Component<Props, State> {
    render() {
      return (
        <div className={this.props.classes.search}>
          <div className={this.props.classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            onChange={(e) => {
              this.props.onChange(e.target.value);
            }}
            placeholder="Search…"
            value={this.props.search}
            classes={{
              root: this.props.classes.inputRoot,
              input: this.props.classes.inputInput,
            }}
          />
        </div>
      );
    }
  }
) as any;
