import * as React from "react";
import {
  DriverReportWithExtras,
  DriverReportWithExtrasResponse,
} from "../../api/types";
import { Grid, Theme } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, WithStyles } from "@material-ui/styles";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { ListItem } from "./list-item";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { AdminNoteEditor } from "./admin-note-editor";
import Button from "@material-ui/core/Button";
import { MLink } from "../../components/link";
import { Paths } from "../../nav";
import { StyledComponent } from "../schedule-editor/layout/boxes/styled-component";

const styles = (theme: Theme) =>
  createStyles({
    controls: {
      padding: theme.spacing(2),
    },
    message: {
      padding: theme.spacing(4),
    },
    noData: {
      color: theme.palette.text.disabled,
    },
    item: {
      padding: theme.spacing(4),
      "&:nth-child(even)": {
        backgroundColor: theme.palette.grey["100"],
      },
    },
    table: {
      "& > thead > tr > th": {
        whiteSpace: "nowrap",
      },
    },
  });

type Props = WithStyles<typeof styles> & {
  isSingleReport: boolean;
  list: DriverReportWithExtras[];
  error?: string;
  loading: boolean;
  onUpdate(item: DriverReportWithExtrasResponse): void;
};

interface State {
  editAdminNotes: string;
  editAdminNotesForItemId: number | null;
}

export const List: StyledComponent<Props> = withStyles(styles)(
  class List extends React.Component<Props, State> {
    constructor(props: any) {
      super(props);

      this.state = {
        editAdminNotes: "",
        editAdminNotesForItemId: null,
      };
    }
    renderContent() {
      if (this.props.loading) {
        return (
          <Grid item className={this.props.classes.message}>
            <CircularProgress size={30} />
          </Grid>
        );
      }

      if (this.props.error) {
        return (
          <Grid item className={this.props.classes.message}>
            <Typography color="error">{this.props.error}</Typography>
          </Grid>
        );
      }

      if (this.props.list.length === 0) {
        return (
          <Grid item className={this.props.classes.message}>
            <Typography className={this.props.classes.noData}>
              No driver reports. <br />
              Wait for some to come in, or adjust the date filter
            </Typography>
          </Grid>
        );
      }

      return (
        <Table size="small" className={this.props.classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Driver</TableCell>
              <TableCell>Vehicle</TableCell>
              <TableCell>Client</TableCell>
              <TableCell>Contacts</TableCell>
              <TableCell>Unit</TableCell>
              <TableCell>Done</TableCell>
              <TableCell>On Site</TableCell>
              <TableCell>Down</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Bl</TableCell>
              <TableCell>UnBl</TableCell>
              <TableCell>Chg</TableCell>
              <TableCell>Sup</TableCell>
              <TableCell>Rpl</TableCell>
              <TableCell>Driver Notes</TableCell>
              <TableCell>Admin Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.list.map((item) => {
              return (
                <ListItem
                  item={item}
                  key={item.id}
                  onEditAdminNotes={() => {
                    this.setState({
                      editAdminNotes: item.adminNotes,
                      editAdminNotesForItemId: item.id,
                    });
                  }}
                />
              );
            })}
          </TableBody>
        </Table>
      );
    }

    renderHeader() {
      if (this.props.isSingleReport) {
        return (
          <Grid container direction="row" spacing={2} alignItems="center">
            <Grid item>
              <Typography variant="body2">Showing only one report</Typography>
            </Grid>
            <Grid item>
              <MLink to={Paths.DriverReport}>
                <Button size="small">Show list</Button>
              </MLink>
            </Grid>
          </Grid>
        );
      }

      return null;
    }

    render() {
      return (
        <Paper style={{ height: "100%" }}>
          <Grid container direction="column" style={{ height: "100%" }}>
            <Grid item style={{ width: "100%" }}>
              <Grid
                container
                direction="row"
                alignItems={"flex-start"}
                className={this.props.classes.controls}
              >
                <Grid item xs={6}>
                  {this.renderHeader()}
                </Grid>
              </Grid>
              <Divider />
            </Grid>
            <Grid item style={{ overflow: "auto", width: "100%" }} xs>
              <Grid container direction={"column"}>
                {this.renderContent()}
              </Grid>
            </Grid>
          </Grid>
          <AdminNoteEditor
            open={this.state.editAdminNotesForItemId !== null}
            driverReportId={this.state.editAdminNotesForItemId || 0}
            note={this.state.editAdminNotes}
            onDone={(data) => {
              this.setState({
                editAdminNotes: "",
                editAdminNotesForItemId: null,
              });

              if (data !== null) {
                this.props.onUpdate(data);
              }
            }}
          />
        </Paper>
      );
    }
  }
) as any;
