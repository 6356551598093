import * as React from "react";
import {
  ScheduleItem,
  ScheduleItemWithDriverReportInfo,
  User,
} from "../../../api/types";
import { Row } from "./row";
import { DataField, fields, isDataField } from "../state/field";
import { ClickableBox } from "./boxes/clickable-box";
import { DriverContext } from "./cell-editor/driver-editor";
import { ControlBox } from "./boxes/control-box";
import { MouseEvent } from "react-window-sortable";
import { StatusCell } from "./special-cells/status-cell";
import { FilesCell } from "./special-cells/files-cell";
import { Moment } from "moment";

interface Props {
  height: number | null;
  date: Moment | null;
  data: ScheduleItemWithDriverReportInfo;
  jobOrder: number;
  isSearching: boolean;
  onMakeUpdate(
    key: keyof ScheduleItem,
    value: string
  ): Promise<ScheduleItemWithDriverReportInfo>;
  onDragStart(e: MouseEvent): void;
  onJumpToRow(): void;
}

interface State {}

export class DataRow extends React.Component<Props, State> {
  static height = 65;

  constructor(props: any) {
    super(props);
    this.state = {};
  }

  onMakeUpdate(key: keyof ScheduleItem) {
    return (value: string) => {
      return this.props.onMakeUpdate(key, value);
    };
  }

  renderField(
    field: DataField,
    index: number,
    height: number,
    drivers: User[]
  ) {
    const value = field.getValue(this.props.data, drivers);

    return (
      <ClickableBox
        type="editable"
        key={field.name}
        index={index}
        scheduleId={this.props.data.id}
        height={height}
        column={field.name}
        onMakeUpdate={this.onMakeUpdate(field.name)}
        dataMoment={value.moment}
        dataString={value.string}
        oneClickToEdit={field.name === "driver" || field.name === "jobType"}
      >
        {value.display}
      </ClickableBox>
    );
  }

  renderJobOrder(index: number, height: number | string) {
    return (
      <ClickableBox
        scheduleId={this.props.data.id}
        index={index}
        type={"render"}
        key="jobOrder"
        column="jobOrder"
        height={height}
      >
        {this.props.jobOrder.toString()}
      </ClickableBox>
    );
  }

  renderStatus(height: number | string) {
    return <StatusCell key="status" height={height} data={this.props.data} />;
  }

  renderFiles(height: number | string) {
    return (
      <FilesCell
        key="files"
        data={this.props.data}
        height={height}
        column="files"
      />
    );
  }

  renderControlBox(field: DataField, drivers: User[]) {
    const d = this.props.data;
    return (
      <ControlBox
        key="control-box"
        scheduleId={d.id}
        backgroundColor={d.adminRowColor}
        onDragStart={this.props.onDragStart}
        isSearching={this.props.isSearching}
        onJumpToRow={this.props.onJumpToRow}
        data={this.props.data}
        date={field.getValue(d, drivers).moment}
        field={field.name}
        onMakeUpdate={this.props.onMakeUpdate}
      />
    );
  }

  render() {
    const d = this.props.data;
    const height = this.props.height;

    if (height === null) throw new Error("height must be set");

    return (
      <Row key="row" backgroundColor={d.adminRowColor} date={this.props.date}>
        <DriverContext.Consumer>
          {(drivers) => (
            <React.Fragment>
              {fields.map((field, index) => {
                if (field.name === "jobOrder")
                  return this.renderJobOrder(index, height);
                if (field.name === "status") return this.renderStatus(height);
                if (field.name === "files") return this.renderFiles(height);

                if (!isDataField(field)) {
                  console.error("shouldn't get " + field.name);
                  return null;
                }

                if (field.name === "localDate")
                  return this.renderControlBox(field, drivers);

                return this.renderField(field, index, height, drivers);
              })}
            </React.Fragment>
          )}
        </DriverContext.Consumer>
      </Row>
    );
  }
}
