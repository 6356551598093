import { File as FileEntity } from "../../../../../api/types";
import React, { useCallback, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import { ListItemText } from "@material-ui/core";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DropZone } from "./drop-zone";
import { Label } from "../../../../../components/label";
import { FileWithSignedInfo } from "../../../../../api/files";
import { SignDocumentDetails } from "./sign-document-details";
import { useUploadRegularFiles } from "./file-group/upload-regular-files";
import { useUploadSignedFiles } from "./file-group/upload-signed-files";
import { FileList } from "./file-group/file-list";

export function FileGroup(props: {
  label: string;
  accept?: string | string[];
  files: FileEntity[];
  isSignedDocs?: boolean;
  jobName: string;
  onFilesUploaded(files: FileEntity[]): void;
  onFileArchived(file: FileEntity): void;
  extraAction?(file: FileEntity): JSX.Element | null;
}) {
  const [filesRequiringSignedInfo, setFilesRequiringSignedInfo] = useState<
    File[]
  >([]);
  const [filesWithSignedInfo, setFilesWithSignedInfo] = useState<
    FileWithSignedInfo[]
  >([]);

  const regular = useUploadRegularFiles({
    onFilesUploaded: props.onFilesUploaded,
  });
  const uploadRegular = regular.upload;

  const signed = useUploadSignedFiles({
    onFilesUploaded: props.onFilesUploaded,
  });
  const signedUpload = signed.upload;

  const cancelSign = useCallback(() => setFilesRequiringSignedInfo([]), []);
  const saveSign = useCallback((fileWithDetails: FileWithSignedInfo) => {
    setFilesWithSignedInfo((existing) => [...existing, fileWithDetails]);
    setFilesRequiringSignedInfo((existing) => existing.slice(1));
  }, []);
  const [isUploadingSigned, setIsUploadingSigned] = useState(false);

  useEffect(() => {
    if (isUploadingSigned) return;
    if (filesRequiringSignedInfo.length > 0) return;
    if (filesWithSignedInfo.length === 0) return;

    const doUpload = async () => {
      await signedUpload(filesWithSignedInfo);
      setFilesWithSignedInfo([]);
      setIsUploadingSigned(false);
    };

    setIsUploadingSigned(true);
    doUpload();
  }, [
    filesWithSignedInfo,
    filesRequiringSignedInfo,
    signedUpload,
    isUploadingSigned,
  ]);

  const onUploadFile = useCallback(
    async (list) => {
      if (props.isSignedDocs) {
        setFilesRequiringSignedInfo((existing) => [...existing, ...list]);
        return;
      }

      await uploadRegular(list);
    },
    [uploadRegular, props]
  );

  return (
    <Grid container direction="column">
      {filesRequiringSignedInfo.length > 0 && (
        <SignDocumentDetails
          file={filesRequiringSignedInfo[0]}
          hasMore={filesRequiringSignedInfo.length > 1}
          onCancel={cancelSign}
          onSave={saveSign}
        />
      )}
      <Grid item>
        <Label>{props.label}</Label>
      </Grid>
      <Grid item>
        <List dense>
          {props.files.length === 0 && (
            <ListItem>
              <Typography color="textSecondary" variant="caption">
                No files uploaded yet
              </Typography>
            </ListItem>
          )}
          <FileList
            files={props.files}
            isSignedDocs={!!props.isSignedDocs}
            onFileArchived={props.onFileArchived}
            jobName={props.jobName}
            extraAction={props.extraAction}
          />
          {[...regular.filesBeingUploaded, ...signed.filesBeingUploaded].map(
            (file) => (
              <ListItem key={file.name}>
                <ListItemText primary={file.name} />
                <ListItemSecondaryAction>
                  <CircularProgress size={12} />
                </ListItemSecondaryAction>
              </ListItem>
            )
          )}
        </List>
      </Grid>
      <Grid item>
        <DropZone accept={props.accept} onAdd={onUploadFile} />
      </Grid>
    </Grid>
  );
}
