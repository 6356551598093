import * as React from "react";
import { ListDetailSplit } from "../components/list-detail-split";
import { Dialog } from "@material-ui/core";
import { Vehicle } from "../api/types";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { api } from "../api/api";
import { EditSection } from "../components/edit-section";
import { showSnack } from "../components/snacker";
import { VehicleDetail } from "./vehicles/vehicle-detail";

interface Props {}
interface State {
  list: ListItem[];
  creating: Vehicle | null;
  selected: Vehicle | null;
  isCreating: boolean;
  loading: boolean;
  listError?: string;
  createError?: string;
}

type ListItem = Vehicle & { displayName: string };

export class Vehicles extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      list: [],
      creating: null,
      selected: null,
      isCreating: false,
      loading: true,
      listError: undefined,
      createError: undefined,
    };
  }

  startCreating() {
    this.setState({
      isCreating: true,
      creating: {
        id: 0,
        name: "",
        vin: "",
        plate: "",
        year: 2000,
        isArchived: false,
      },
    });
  }

  convertToDisplayVehicle(user: Vehicle): ListItem {
    const listItem = user as any as ListItem;
    listItem.displayName = user.name;
    return listItem;
  }

  componentDidMount(): void {
    this.getList();
  }

  async getList() {
    try {
      this.setState({
        loading: true,
        listError: undefined,
      });

      const vehicles = await api.vehicles.list();
      if (vehicles !== null && "error" in vehicles) {
        throw new Error(vehicles.error);
      }

      this.setState({
        loading: false,
        list: this.prepUserList(vehicles || []),
      });
    } catch (e: any) {
      this.setState({
        loading: false,
        listError: e.message,
      });
    }
  }

  async create() {
    if (!this.state.creating) return;

    try {
      this.setState({
        loading: true,
        createError: undefined,
      });

      const vehicles = await api.vehicles.create(this.state.creating);
      if (vehicles !== null && "error" in vehicles) {
        throw new Error(vehicles.error);
      }

      this.setState({
        loading: false,
        list: this.prepUserList(vehicles || []),
        isCreating: false,
      });
    } catch (e: any) {
      this.setState({
        loading: false,
        createError: e.message,
      });
    }
  }

  prepUserList(list: Vehicle[]): ListItem[] {
    const converted = list.map(this.convertToDisplayVehicle);

    converted.sort((a, b) => {
      if (a.displayName === b.displayName) return 0;
      if (a.displayName > b.displayName) return 1;
      return -1;
    });

    return converted;
  }

  async save() {
    if (!this.state.selected) return;
    const vehicles = await api.vehicles.edit(this.state.selected);
    if (vehicles !== null && "error" in vehicles) {
      throw new Error(vehicles.error);
    }

    if (!this.state.selected) return;
    const id = this.state.selected.id;
    const user = (vehicles || []).filter((item) => item.id === id)[0];

    this.setState({
      list: this.prepUserList(vehicles || []),
      selected: user || undefined,
    });

    showSnack("Saved");
  }

  renderDetail() {
    if (!this.state.selected) {
      return null;
    }

    return (
      <EditSection
        title="Edit Vehicle"
        onSave={() => this.save()}
        onCancel={() =>
          this.setState({
            selected: null,
          })
        }
      >
        <VehicleDetail
          {...this.state.selected}
          onChange={(key: keyof Vehicle, value: any) =>
            this.setState({
              selected: Object.assign({}, this.state.selected, {
                [key]: value,
              }),
            })
          }
        />
      </EditSection>
    );
  }

  render() {
    return (
      <div style={{ height: "100%" }}>
        <ListDetailSplit
          title="Vehicles"
          data={this.state.list}
          loading={this.state.loading}
          error={this.state.listError}
          onSelect={(item: any) => this.setState({ selected: item })}
          onCreate={() => this.startCreating()}
          renderDetail={() => this.renderDetail()}
        />
        <Dialog
          open={this.state.isCreating}
          onClose={() => this.setState({ isCreating: false })}
          fullWidth
          maxWidth="sm"
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.create();
            }}
          >
            <DialogTitle>Create Vehicle</DialogTitle>
            <DialogContent>
              {this.state.creating && (
                <VehicleDetail
                  {...this.state.creating}
                  onChange={(key: keyof Vehicle, value: any) =>
                    this.setState({
                      creating: Object.assign({}, this.state.creating, {
                        [key]: value,
                      }),
                    })
                  }
                />
              )}
            </DialogContent>
            <DialogActions>
              {this.state.createError && (
                <Typography color="error">{this.state.createError}</Typography>
              )}
              {this.state.loading && <CircularProgress size="15px" />}
              <Button
                onClick={() => this.setState({ isCreating: false })}
                color="primary"
              >
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Create
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}
