type OS = "windows" | "mac";

export const operatingSystem: OS = OSIsMac() ? "mac" : "windows";

function OSIsMac(): boolean {
  var mac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);

  if (mac) {
    return true;
  }

  return false;
}
