import EventIcon from "@material-ui/icons/Event";
import { DatePicker } from "@material-ui/pickers";
import * as React from "react";
import TextField from "@material-ui/core/TextField";
import { SimpleIconButton } from "../../../../misc/simple-icon-button";

interface Props {
  onChange(date: Date): void;
}
interface State {
  open: boolean;
  date: Date;
  anchorEl: HTMLElement | null;
}

export class EditorDatePicker extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      open: false,
      date: new Date(),
      anchorEl: null,
    };
  }

  render() {
    return (
      <div style={{ display: "relative" }}>
        <SimpleIconButton
          onClick={(e) =>
            this.setState({ open: true, anchorEl: e.currentTarget })
          }
          style={{ color: "white" }}
        >
          <EventIcon />
        </SimpleIconButton>
        <DatePicker
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          value={this.state.date}
          variant="inline"
          TextFieldComponent={(props) => (
            <TextField
              {...props}
              style={{ width: "0", height: "0", position: "absolute" }}
            />
          )}
          PopoverProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            anchorEl: this.state.anchorEl,
          }}
          onChange={(date) => {
            if (date === null) return;
            this.setState({
              open: false,
            });
            this.props.onChange(date);
          }}
          autoOk
        />
      </div>
    );
  }
}
