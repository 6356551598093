import * as React from "react";
import { Snackbar } from "@material-ui/core";

interface State {
  message?: string;
}

const showDuration = 3000;

export class Snacker extends React.Component<{}, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      message: undefined,
    };

    instance = this;
  }

  pendingMessages: string[] = [];

  show(message: string) {
    if (this.state.message !== undefined) {
      this.pendingMessages.push(message);
      return;
    }

    this.setState({
      message: message,
    });

    setTimeout(() => this.remove(), showDuration);
    return;
  }

  remove() {
    const nextMessage = this.pendingMessages.shift();

    if (this.state.message === undefined && nextMessage === undefined) {
      return;
    }

    this.setState({
      message: nextMessage,
    });

    if (nextMessage !== undefined) {
      setTimeout(() => this.remove(), showDuration);
    }
  }

  render() {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={this.state.message !== undefined}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{this.state.message}</span>}
      />
    );
  }
}

var instance: Snacker | undefined = undefined;

export function showSnack(message: string) {
  if (!instance) {
    throw new Error("missing instance");
  }

  instance.show(message);
}
