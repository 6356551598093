import { Grid } from "@material-ui/core";
import * as React from "react";
import TextField from "@material-ui/core/TextField";
import { CreateUser, User } from "../../api/types";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import { PaintButtonWithRect } from "../schedule-editor/layout/controls/paint-button-with-rect";

type Props = (User | CreateUser) & {
  onChange(key: keyof CreateUser, value: any): void;
};

export class UserDetail extends React.Component<Props, {}> {
  render() {
    return (
      <Grid container direction="row" spacing={2} style={{ width: "100%" }}>
        <Grid item xs={10}>
          <Grid container direction={"column"}>
            <TextField
              label="Name"
              type="text"
              required
              value={this.props.name}
              onChange={(event) =>
                this.props.onChange("name", event.currentTarget.value)
              }
              margin="normal"
              fullWidth
            />
            <TextField
              label="Email"
              type="email"
              required
              value={this.props.email}
              onChange={(event) =>
                this.props.onChange("email", event.currentTarget.value)
              }
              margin="normal"
              fullWidth
            />
            {"newPassword" in this.props && (
              <TextField
                label="Password"
                type="password"
                required
                value={this.props.newPassword}
                onChange={(event) =>
                  this.props.onChange("newPassword", event.currentTarget.value)
                }
                margin="normal"
                fullWidth
              />
            )}
            <div style={{ height: "10px" }}></div>
            <Grid item>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <InputLabel shrink>Driver</InputLabel>

                  <Switch
                    color="primary"
                    checked={this.props.isDriver}
                    onChange={(event) =>
                      this.props.onChange(
                        "isDriver",
                        event.currentTarget.checked
                      )
                    }
                  />
                </Grid>
                <Grid item>
                  <InputLabel shrink>Admin</InputLabel>

                  <Switch
                    color="primary"
                    checked={this.props.isAdmin}
                    onChange={(event) =>
                      this.props.onChange(
                        "isAdmin",
                        event.currentTarget.checked
                      )
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            {this.props.isDriver && (
              <Grid item>
                <div style={{ height: 20 }} />
                <InputLabel shrink>Schedule Row Color</InputLabel>

                <div style={{ height: 5 }} />
                <PaintButtonWithRect
                  color={this.props.adminRowColor}
                  onChange={(value) =>
                    this.props.onChange("adminRowColor", value)
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel
            value="top"
            control={
              <Switch
                color="primary"
                checked={this.props.isArchived}
                onChange={(event) =>
                  this.props.onChange("isArchived", event.currentTarget.checked)
                }
              />
            }
            label="Archived"
            labelPlacement="top"
          />
        </Grid>
      </Grid>
    );
  }
}
