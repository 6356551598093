import { RefreshContext } from "./Invoicing";
import { useContext } from "react";
import { useAsyncAction } from "../../api/async";
import { api } from "../../api/api";
import { Button } from "@material-ui/core";

export function RefreshDrafts(props: { onComplete(): void }) {
  const ctx = useContext(RefreshContext);
  const action = useAsyncAction(async () => {
    await api.invoices.refresh();
    ctx.reload();
    props.onComplete();
  }, [ctx.reload, props.onComplete]);

  if (action.loading || action.error) {
    return action.LoadingElement;
  }

  return (
    <Button
      size="small"
      color="primary"
      variant="outlined"
      onClick={() => action.callback(null)}
    >
      Regenerate Invoices
    </Button>
  );
}
