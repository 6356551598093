import { makeStyles } from "@material-ui/styles";
import React, { PropsWithChildren } from "react";

const useStyles = makeStyles((theme) => ({
  buttonNormal: {
    padding: "12px",
    fontSize: "1.5rem",
  },
  buttonSmall: {
    padding: "3px",
    fontSize: "1.125rem",
  },
  buttonBase: {
    display: "inline-block",
    borderRadius: "50%",
    flex: 0,
    flexBasis: "auto",
    lineHeight: 0,
    color: "rgba(0, 0, 0, 0.54)",
  },
  disabled: {
    opacity: 0.7,
  },
  active: {
    "&:hover": {
      backgroundColor: "rgba(0,0,0,.05)",
    },
  },
}));

export const SimpleIconButton = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<{
    size?: "small";
    disabled?: boolean;
    className?: string;
    style?: any;
    onMouseDown?(e: MouseEvent): void;
    onClick?(e: MouseEvent & { currentTarget: HTMLDivElement }): void;
  }>
>((props, ref) => {
  const styles = useStyles();
  const classNames = [styles.buttonBase];
  classNames.push(
    props.size === "small" ? styles.buttonSmall : styles.buttonNormal
  );

  if (!props.disabled) {
    classNames.push(styles.active);
  } else {
    classNames.push(styles.disabled);
  }

  if (props.className) {
    classNames.push(props.className);
  }

  return (
    <div
      ref={ref}
      className={classNames.join(" ")}
      onClick={props.disabled ? undefined : (props.onClick as any)}
      onMouseDown={props.disabled ? undefined : (props.onMouseDown as any)}
      style={props.style}
    >
      {props.children}
    </div>
  );
});
