import * as React from "react";
import { InputLabel, Theme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { createStyles, WithStyles } from "@material-ui/styles";
import withStyles from "@material-ui/styles/withStyles";
import { StyledComponent } from "../pages/schedule-editor/layout/boxes/styled-component";

const style = (theme: Theme) =>
  createStyles({
    disable: {
      color: theme.palette.text.disabled,
    },
  });

type Props = WithStyles<typeof style> & {
  text: any;
  label: string;
};

export const TextWithLabel: StyledComponent<Props> = withStyles(style)(
  class TextWithLabel extends React.PureComponent<Props> {
    renderText() {
      if (!this.props.text)
        return (
          <Typography className={this.props.classes.disable}>N/A</Typography>
        );
      if (typeof this.props.text === "string") {
        return (
          <Typography
            dangerouslySetInnerHTML={{ __html: this.props.text.toString() }}
          />
        );
      }

      return <Typography>{this.props.text}</Typography>;
    }

    render() {
      return (
        <div>
          <InputLabel shrink={true}>{this.props.label}</InputLabel>
          {this.renderText()}
        </div>
      );
    }
  }
) as any;
