import { Fetcher } from "./fetcher";
import { File as UploadedFile } from "./types";

export class FilesAPI {
  fetcher: Fetcher;

  constructor(fetcher: Fetcher) {
    this.fetcher = fetcher;
  }

  publicDownloadLink(id: number) {
    return this.fetcher.getOrThrow<string>(
      "/api/files/public-download-link/" + id
    );
  }

  upload(files: File[]) {
    let counter = 0;

    const fileObj = files.reduce((acc, file) => {
      counter++;

      return Object.assign(acc, {
        [counter]: file,
      });
    }, {} as { [k: string]: File });

    return this.fetcher.postFormDataOrThrow<UploadedFile[] | null>(
      "/api/files/upload",
      fileObj
    );
  }

  uploadSigned(files: FileWithSignedInfo[]) {
    let counter = 0;

    const fileObj = files.reduce((acc, file) => {
      counter++;

      return Object.assign(acc, {
        [counter]: file.file,
        [counter + "-details"]: JSON.stringify({
          signedAt: file.signedAt,
          signedBy: file.signedBy,
        }),
      });
    }, {} as { [k: string]: File | string });

    return this.fetcher.postFormDataOrThrow<UploadedFile[] | null>(
      "/api/files/upload-signed",
      fileObj
    );
  }

  download(id: number) {
    window.open("/api/files/download/" + id);
  }

  view(id: number) {
    window.open(this.viewSrc(id), "_blank");
  }

  viewSrc(id: number) {
    return "/api/files/view/" + id;
  }

  archive(id: number) {
    return this.fetcher.putOrThrow("/api/files/archive/" + id, {});
  }

  emailSigned(params: { toCSV: string; jobName: string; id: number }) {
    return this.fetcher.putOrThrow("/api/files/email-signed", params);
  }
}

export interface FileWithSignedInfo {
  file: File;
  signedBy: string;
  signedAt: Date;
}

export interface Details {
  path: string;
  token: string;
}
