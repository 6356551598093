import { SimpleIconButton } from "../../../../misc/simple-icon-button";
import FormatColorFillIcon from "@material-ui/icons/FormatColorFill";
import { PaintButtonPopover } from "./paint-button-popover";
import React, { useState } from "react";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: 100,
    padding: 5,
    border: "1px solid " + grey["300"],
    borderRadius: 5,

    "&:hover": {
      border: "1px solid " + theme.palette.primary.main,
    },
  },
}));

export function PaintButtonWithRect(props: {
  color: string;
  onChange(value: string): void;
}) {
  const styles = useStyles();
  const [open, setOpen] = useState(false);
  const [ref, setRef] = useState<HTMLDivElement | null>();

  return (
    <div
      ref={setRef}
      className={styles.wrapper}
      onClick={() => setOpen(true)}
      style={{
        backgroundColor: props.color,
      }}
    >
      <SimpleIconButton size="small">
        <FormatColorFillIcon />
      </SimpleIconButton>

      <PaintButtonPopover
        driverColors
        open={open}
        color={props.color}
        anchorEl={ref}
        onClose={() => setOpen(false)}
        onSelect={(color) => {
          setOpen(false);
          props.onChange(color);
        }}
      />
    </div>
  );
}
