import * as React from "react";
import { createStyles, Grid, Theme, WithStyles } from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from "@material-ui/icons/Add";
import CircularProgress from "@material-ui/core/CircularProgress";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { PageContent } from "./page-content";
import IconButton from "@material-ui/core/IconButton";
import { StyledComponent } from "../pages/schedule-editor/layout/boxes/styled-component";

const styles = (theme: Theme) =>
  createStyles({
    divider: {
      borderRightColor: theme.palette.grey["300"],
      borderRightWidth: "1px",
      borderRightStyle: "solid",
    },
    height100: {
      height: "100%",
    },
    noData: {
      color: theme.palette.text.disabled,
    },
    error: {
      color: theme.palette.error.dark,
    },
  });

interface NamedItem {
  displayName: string;
  isArchived: boolean;
}

type Props<T extends NamedItem> = WithStyles<typeof styles> & {
  title: string;
  data: T[];
  loading: boolean;
  error?: string;
  onSelect(element: T): void;
  onCreate(): void;
  renderDetail(): any;
};

interface State {
  showingArchived: boolean;
}

export const ListDetailSplit: StyledComponent<Props<any>> = withStyles(styles)(
  class ListDetailSplit<T extends NamedItem> extends React.Component<
    Props<T>,
    State
  > {
    constructor(props: any) {
      super(props);
      this.state = {
        showingArchived: false,
      };
    }

    renderLoadingItem() {
      return (
        <ListItem key="loading" style={{ justifyContent: "center" }}>
          <CircularProgress size="20px" />
        </ListItem>
      );
    }

    renderList() {
      if (this.props.error) {
        return (
          <ListItem key="error">
            <ListItemText
              primary={this.props.error}
              className={this.props.classes.error}
            />
          </ListItem>
        );
      }

      if (this.props.loading && this.props.data.length === 0) {
        return this.renderLoadingItem();
      }

      if (this.props.data.length === 0) {
        return (
          <ListItem key={"empty"}>
            <ListItemText
              primary="No items"
              className={this.props.classes.noData}
            />
          </ListItem>
        );
      }

      var list = this.props.data
        .filter((item) => item.isArchived === this.state.showingArchived)
        .map((item) => (
          <ListItem
            key={item.displayName}
            button
            onClick={() => this.props.onSelect(item)}
          >
            <ListItemText primary={item.displayName} />
            <ListItemSecondaryAction>
              <ChevronRight />
            </ListItemSecondaryAction>
          </ListItem>
        ));

      if (this.props.loading) {
        list.push(this.renderLoadingItem());
      }

      return list;
    }

    renderRight() {
      return (
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <FormControlLabel
              value="top"
              control={
                <Switch
                  color="secondary"
                  checked={this.state.showingArchived}
                  onChange={(event) =>
                    this.setState({
                      showingArchived: event.currentTarget.checked,
                    })
                  }
                />
              }
              label="Show Archived"
              labelPlacement="end"
            />
            <IconButton
              color="inherit"
              aria-label="Add"
              onClick={this.props.onCreate}
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
      );
    }

    render() {
      return (
        <PageContent title={this.props.title} rightContent={this.renderRight()}>
          <Grid
            container
            direction="column"
            spacing={2}
            className={this.props.classes.height100}
          >
            <Grid item xs className={this.props.classes.height100}>
              <Paper className={this.props.classes.height100}>
                <Grid
                  container
                  className={this.props.classes.height100}
                  direction={"row"}
                  alignItems={"stretch"}
                >
                  <Grid item xs={4} className={this.props.classes.height100}>
                    <Grid
                      container
                      direction={"row"}
                      alignItems={"stretch"}
                      className={this.props.classes.height100}
                    >
                      <Grid
                        item
                        xs
                        className={this.props.classes.height100}
                        style={{ overflow: "auto" }}
                      >
                        <List>{this.renderList()}</List>
                      </Grid>
                      <Grid item className={this.props.classes.divider} />
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    {this.props.renderDetail()}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </PageContent>
      );
    }
  }
) as any;
