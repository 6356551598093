import React, { useContext, useEffect, useState } from "react";
import { Box } from "./box";
import { Tooltip } from "@material-ui/core";
import { ContentCopy as DuplicateIcon } from "mdi-material-ui";
import Grid from "@material-ui/core/Grid";
import { stateManager } from "../../state/state-manager";
import { Loading } from "../../../../components/loading";
import { api } from "../../../../api/api";
import { showSnack } from "../../../../components/snacker";
import { PaintButton } from "../controls/paint-button";
import DragIcon from "@material-ui/icons/DragHandle";
import { MouseEvent } from "react-window-sortable";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import { ScheduleItemWithDriverReportInfo } from "../../../../api/types";
import { RowDeleteButton } from "./DeleteButton";
import { Moment } from "moment";
import { ControlDateCell } from "./control-date-cell";
import { SimpleIconButton } from "../../../../misc/simple-icon-button";
import { UndoTrackerContext } from "../../utils/undo";

export function ControlBox(props: {
  scheduleId: number;
  backgroundColor: string;
  isSearching: boolean;
  data: ScheduleItemWithDriverReportInfo;
  date?: Moment;
  field: string;
  onDragStart(e: MouseEvent): void;
  onJumpToRow(): void;
  onMakeUpdate(
    field: string,
    value: string
  ): Promise<ScheduleItemWithDriverReportInfo>;
}) {
  const [rowLocked, setRowLocked] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const sub = stateManager.externalHighlightEvents.subscribe((events) => {
      const shouldBeDisabled =
        events.filter(
          (event) =>
            event.type === "schedule" && event.scheduleId === props.scheduleId
        ).length > 0;

      setRowLocked(shouldBeDisabled);
    });

    return () => sub.unsubscribe();
  }, [props.scheduleId]);

  const trackerCtx = useContext(UndoTrackerContext);

  return (
    <Box
      key="controls"
      column="controls"
      height="auto"
      noPadding={true}
      valignCenter={false}
    >
      <ControlDateCell
        scheduleId={props.scheduleId}
        onMakeUpdate={(value) => props.onMakeUpdate(props.field, value)}
        date={props.date}
        isSearching={props.isSearching}
      />

      <Grid
        container
        direction="row"
        alignItems="center"
        justify="flex-start"
        style={{ height: "100%", paddingTop: "10px" }}
        spacing={0}
      >
        {!loading && !props.isSearching && (
          <React.Fragment>
            <Grid item key="drag-handle">
              <SimpleIconButton
                disabled={rowLocked}
                onMouseDown={props.onDragStart}
                size="small"
              >
                <DragIcon />
              </SimpleIconButton>
            </Grid>
            <Grid item key="duplicate">
              <Tooltip title="Duplicate">
                <SimpleIconButton
                  disabled={rowLocked}
                  onClick={async () => {
                    setLoading(true);
                    let id = 0;

                    await trackerCtx.runWithTracking({
                      run: async () => {
                        try {
                          const res = await api.schedule.duplicate(
                            props.scheduleId
                          );
                          id = res.id;
                          showSnack("Successfully duplicated item");
                        } catch (e: any) {
                          showSnack("Failed to duplicate item: " + e.message);
                        }
                      },
                      undo: async () => {
                        try {
                          await api.schedule.remove(id);
                          showSnack("Successfully removed item");
                        } catch (e: any) {
                          showSnack("Failed to remove item: " + e.message);
                        }
                      },
                    });

                    setLoading(false);
                  }}
                  size="small"
                >
                  <DuplicateIcon />
                </SimpleIconButton>
              </Tooltip>
            </Grid>
            <Grid item key="paint">
              <Tooltip title="Color">
                <PaintButton
                  disabled={rowLocked}
                  onChange={async (color: string) => {
                    setLoading(true);

                    try {
                      const oldColor = props.backgroundColor;

                      await trackerCtx.runWithTracking({
                        run: () =>
                          api.schedule.updateField({
                            changes: [
                              {
                                field: "adminRowColor",
                                value: color,
                              },
                            ],
                            rowHeight: props.data.rowHeight, // no change in height
                            scheduleId: props.scheduleId,
                          }),
                        undo: () =>
                          api.schedule.updateField({
                            changes: [
                              {
                                field: "adminRowColor",
                                value: oldColor,
                              },
                            ],
                            rowHeight: props.data.rowHeight, // no change in height
                            scheduleId: props.scheduleId,
                          }),
                      });

                      showSnack("Success");
                    } catch (e: any) {
                      showSnack("Failed: " + e.message);
                    }

                    setLoading(false);
                  }}
                  color={props.backgroundColor}
                />
              </Tooltip>
            </Grid>
            <RowDeleteButton
              disabled={rowLocked}
              onClick={async () => {
                setLoading(true);

                try {
                  await trackerCtx.runWithTracking({
                    run: () => api.schedule.remove(props.scheduleId),
                    undo: () => api.schedule.unremove(props.scheduleId),
                  });
                  showSnack("Successfully removed item");
                } catch (e: any) {
                  showSnack("Failed to remove item: " + e.message);
                }

                setLoading(false);
              }}
            />
          </React.Fragment>
        )}
        {loading && (
          <Grid item key="loading" style={{ height: "100%" }}>
            <Loading smaller={true} />
          </Grid>
        )}
        {props.isSearching && (
          <Grid item key="jump-to">
            <SimpleIconButton
              size="small"
              disabled={rowLocked}
              onClick={() => props.onJumpToRow()}
            >
              <Tooltip title="View in context">
                <GpsFixedIcon />
              </Tooltip>
            </SimpleIconButton>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
