import * as React from "react";
import Typography from "@material-ui/core/Typography";

export const PageHeaderCenter = React.memo(function (props: {
  displayMonth: string;
}) {
  return (
    <Typography variant="h6" style={{ color: "white" }}>
      {props.displayMonth}
    </Typography>
  );
});
