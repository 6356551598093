import * as React from "react";
import Button from "@material-ui/core/Button";
import { Moment } from "moment";
import { api } from "../../../../api/api";
import { showSnack } from "../../../../components/snacker";
import { Loading } from "../../../../components/loading";
import { useContext, useState } from "react";
import { UndoTrackerContext } from "../../utils/undo";

export function AddRowBox(props: { date: Moment }) {
  const [loading, setLoading] = useState(false);
  const tracker = useContext(UndoTrackerContext);

  return (
    <td>
      {!loading && (
        <Button
          size="small"
          onClick={async () => {
            setLoading(true);
            let id = 0;

            await tracker.runWithTracking({
              run: async () => {
                try {
                  const item = await api.schedule.newBlank(props.date.toDate());
                  id = item.id;
                  showSnack("Added row");
                } catch (e: any) {
                  showSnack("Failed to add row: " + e.message);
                }
              },
              undo: async () => {
                try {
                  await api.schedule.remove(id);
                  showSnack("Removed row");
                } catch (e: any) {
                  showSnack("Failed to remove row: " + e.message);
                }
              },
            });

            setLoading(false);
          }}
        >
          Add Row
        </Button>
      )}
      {loading && <Loading />}
    </td>
  );
}
