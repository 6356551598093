import { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/styles";

const overScan = 6;
const pageSize = 15;
const initialOffset = 5000;
const defaultWindowSize = overScan * 2 + pageSize;

const useStyles = makeStyles((theme) => ({
  scroller: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

export type ListController = {
  jumpTo(index: number): void;
};

export function ListControl<T>(props: {
  getItem: (index: number) => any;
  getItemHeight: (index: number) => number;
  minIndex: number;
  maxIndex: number;
  controller: (current: ListController | null) => void;
  onViewingAtUpdated: (value: number) => void;
}) {
  const styles = useStyles();

  const [scroller, setScroller] = useState<HTMLDivElement | null>(null);
  const scrollerRef = useRef(scroller);
  scrollerRef.current = scroller;

  const [shrinkWindowForFastScroll, setShrinkWindowForFastScroll] =
    useState(defaultWindowSize);
  const lastShrunkCallRef = useRef(Infinity);
  const isShrunk = shrinkWindowForFastScroll < defaultWindowSize;
  const isShrunkRef = useRef(isShrunk);
  isShrunkRef.current = isShrunk;

  useEffect(() => {
    const int = setInterval(() => {
      if (lastShrunkCallRef.current > Date.now() - 200) return;
      setShrinkWindowForFastScroll(defaultWindowSize);
    }, 100);

    return () => clearInterval(int);
  }, [isShrunk]);

  // @ts-ignore debugging
  window.scrollerRef = scroller;
  // @ts-ignore debugging
  window.getItemHeightRef = props.getItemHeight;

  const [at, setAt] = useState(Math.min(overScan, props.maxIndex));
  const atRef = useRef(at);
  atRef.current = at;

  const onViewingAtUpdatedRef = useRef(props.onViewingAtUpdated);
  useEffect(() => {
    onViewingAtUpdatedRef.current(at);
  }, [at]);

  const [atOffset, setAtOffset] = useState(initialOffset);
  const atOffsetRef = useRef(atOffset);
  atOffsetRef.current = atOffset;

  const controllerSetRef = useRef(props.controller);
  controllerSetRef.current = props.controller;

  useEffect(() => {
    if (!scroller) return;

    controllerSetRef.current({
      jumpTo: (index: number) => {
        console.log(`ListControl: jumpTo(${index})`);

        setAt(index);
        setAtOffset(initialOffset);

        if (scrollerRef.current) {
          console.log(`ListControl: jumpTo - scroll(${initialOffset})`);
          internalScroll(initialOffset);
        }
      },
    });

    return () => {
      controllerSetRef.current(null);
    };
  }, [scroller]);

  const max = Math.min(at + pageSize + overScan, props.maxIndex);
  const min = Math.max(at - overScan, props.minIndex);
  const minEdge = Math.max(at - 2, props.minIndex);
  const maxEdge = Math.min(at + pageSize + 2, props.maxIndex);
  const renderMin = isShrunk ? Math.max(at, props.minIndex) : min;
  const renderMax = isShrunk
    ? Math.min(at + shrinkWindowForFastScroll, props.maxIndex)
    : max;

  useEffect(() => {
    console.log(
      `ListControl: min=${min}, max=${max}, at=${at}, props={minIndex: ${props.minIndex}, maxIndex: ${props.maxIndex}}, isShrunk=${isShrunk}`
    );
  }, [min, max, at, props.minIndex, props.maxIndex, isShrunk]);

  const none = props.minIndex === 0 && props.maxIndex === -1;
  const [offsetCalc] = useState(new OffsetCalculator(props.getItemHeight));

  offsetCalc.baseOffset = atOffset;
  offsetCalc.baseIndex = at;

  const renderedMinEdgeOffset = useRef(0);
  renderedMinEdgeOffset.current = none ? 0 : offsetCalc.get(minEdge);

  const renderedMaxEdgeOffset = useRef(0);
  renderedMaxEdgeOffset.current = none ? 0 : offsetCalc.get(maxEdge);

  const minIndexRef = useRef(props.minIndex);
  minIndexRef.current = props.minIndex;

  const maxIndexRef = useRef(props.maxIndex);
  maxIndexRef.current = props.maxIndex;

  const isInternalScrollTrigger = useRef(false);
  const internalScroll = useCallback((to: number) => {
    if (!scrollerRef.current) return;
    isInternalScrollTrigger.current = true;
    scrollerRef.current?.scrollTo({
      top: to,
    });
  }, []);

  const shiftScrollWindow = useCallback((atIndex: number, velocity: number) => {
    const scroll = scrollerRef.current;
    if (!scroll) return;

    console.log(
      `ListControl: shiftScrollWindow scrollTop=${scroll.scrollTop} fromIndex=${atRef.current} toIndex=${atIndex} toOffsetTop=${initialOffset} currentOffsetTop=${atOffsetRef.current} velocity=${velocity}`
    );
    internalScroll(
      scroll.scrollTop - (offsetCalc.get(atIndex) - atOffsetRef.current)
    );

    setAtOffset(initialOffset);
    setAt(atIndex);

    lastShrunkCallRef.current = Date.now();

    if (!isShrunkRef.current && Math.abs(velocity) > 20) {
      setShrinkWindowForFastScroll(8);
    }
  }, []);

  const scrollVelocityTracker = useRef<{
    lastTimestamp: number;
    lastValue: number;
  } | null>(null);

  const checkScroll = useCallback(() => {
    const scroll = scrollerRef.current;
    if (!scroll) return;

    let velocity = 0;
    const prev = scrollVelocityTracker.current;
    if (prev) {
      velocity =
        (scroll.scrollTop - prev.lastValue) / (Date.now() - prev.lastTimestamp);
    }

    if (scroll.scrollTop < renderedMinEdgeOffset.current) {
      let index = atRef.current;

      while (offsetCalc.get(index) > scroll.scrollTop) {
        if (index === minIndexRef.current) break;
        index--;
      }

      shiftScrollWindow(index, velocity);
      return;
    }

    if (
      scroll.scrollTop + scroll.clientHeight >
      renderedMaxEdgeOffset.current
    ) {
      let index = atRef.current;

      while (offsetCalc.get(index) < scroll.scrollTop + scroll.clientHeight) {
        if (index === maxIndexRef.current) break;
        index++;
      }

      shiftScrollWindow(index, velocity);
      return;
    }
  }, [none]);

  useEffect(() => {
    if (!scroller) return;

    scroller.scrollTo({
      top: initialOffset,
      left: 0,
      behavior: "auto",
    });

    checkScroll();

    const scroll = (e: any) => {
      if (isInternalScrollTrigger.current) {
        // ignore our js scroll calls
        isInternalScrollTrigger.current = false;
        return;
      }

      checkScroll();

      scrollVelocityTracker.current = {
        lastTimestamp: Date.now(),
        lastValue: scroller.scrollTop,
      };
    };

    scroller.addEventListener("scroll", scroll);

    return () => {
      scroller.addEventListener("scroll", scroll);
    };
  }, [scroller, checkScroll]);

  return (
    <div
      className={styles.scroller}
      style={{ flex: 1, position: "relative", overflow: "auto" }}
      ref={(r) => {
        setScroller(r);
      }}
    >
      <div style={{ position: "absolute", top: 0, left: 0 }}>
        {none
          ? null
          : range(renderMin, renderMax).map((index, itemNumber) => (
              <div
                key={index.toString()}
                style={{
                  position: "absolute",
                  top: offsetCalc.get(index),
                  left: 0,
                }}
              >
                {props.getItem(index)}
              </div>
            ))}

        <div style={{ height: 10000 }} className="v-scroll-helper" />
      </div>
    </div>
  );
}

class OffsetCalculator {
  baseIndex: number = 0;
  baseOffset: number = 0;

  lookup: (index: number) => number;

  constructor(lookup: (index: number) => number) {
    this.lookup = lookup;
  }

  get(index: number) {
    let offset = this.baseOffset;

    if (index < this.baseIndex) {
      for (let i = this.baseIndex - 1; i >= index; i--) {
        offset -= this.lookup(i);
      }
    } else if (index > this.baseIndex) {
      for (let i = this.baseIndex; i < index; i++) {
        offset += this.lookup(i);
      }
    }

    return offset;
  }
}

function range(start: number, end: number) {
  const list: number[] = [];

  for (let i = start; i <= end; i++) {
    list.push(i);
  }

  return list;
}
