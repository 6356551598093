import * as React from "react";
import { DefaultEditor } from "./cell-editor/default-editor";
import { makeStyles } from "@material-ui/styles";
import { useContext, useEffect, useState } from "react";
import { renderCell } from "./boxes/clickable-box";
import { createStyles, Theme } from "@material-ui/core";
import { api } from "../../../api/api";
import { WeeklyNote } from "../../../api/WeeklyNoteAPI";
import { stateManager } from "../state/state-manager";
import clsx from "clsx";
import { UndoTrackerContext } from "../utils/undo";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      borderTop: "1px solid #e0e0e0",
      height: "100%",
    },
    displayViewCommon: {
      position: "relative",
      "& p": {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    displayView: {
      border: "2px solid transparent",
      cursor: "pointer",
      overflow: "hidden",
      "&:hover": {
        borderColor: theme.palette.primary.main,
      },
    },
    lockedView: {
      border: "2px solid " + theme.palette.grey["600"],
      cursor: "pointer",
      overflow: "hidden",
    },
    lockedBy: {
      position: "absolute",
      top: 0,
      left: 0,
      padding: 5,
      opacity: 0.8,
      fontSize: 11,
      backgroundColor: theme.palette.grey["600"],
      color: "white",
    },
  })
);

export function WeeklyNoteRow(props: {
  note: WeeklyNote;
  cellHeight: number | null;
}) {
  const [editing, setEditing] = useState(false);
  const [rowLockedBy, setRowLockedBy] = useState<null | string>(null);
  const styles = useStyles();

  useEffect(() => {
    const subA = stateManager.clickEvents.subscribe((params) => {
      if (params.type === "schedule" && params.scheduleId > 0) {
        setEditing(false);
      }
    });
    const subB = stateManager.highlightEvents.subscribe((params) => {
      if (params.type === "schedule" && params.scheduleId > 0) {
        setEditing(false);
      }
    });
    return () => {
      subA.unsubscribe();
      subB.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const sub = stateManager.externalHighlightEvents.subscribe((events) => {
      const applicableEvents = events.filter(
        (event) =>
          event.type === "weekly-note" &&
          event.weeklyNoteSunday === props.note.sunday
      );
      if (applicableEvents.length === 0) {
        setRowLockedBy(null);
      } else {
        setRowLockedBy(applicableEvents[0].userName);
      }
    });

    return () => sub.unsubscribe();
  }, [props.note.sunday]);

  const undoContext = useContext(UndoTrackerContext);

  return (
    <React.Fragment>
      <div className={styles.wrapper}>
        {editing ? (
          <DefaultEditor
            key="box-editor"
            ignoreStateManager
            initialValue={props.note.note}
            onEsc={() => setEditing(false)}
            onMakeUpdate={async (change) => {
              let original = props.note.note;

              await undoContext.runWithTracking({
                run: () =>
                  api.weeklyNotes.set({
                    sunday: props.note.sunday,
                    note: change,
                  }),
                undo: () =>
                  api.weeklyNotes.set({
                    sunday: props.note.sunday,
                    note: original,
                  }),
              });

              props.note.note = change;
              setEditing(false);
              stateManager.clearClickSelection();
            }}
          />
        ) : (
          <div
            onClick={() => {
              if (rowLockedBy !== null) return;

              stateManager.currentHighlight = {
                type: "weekly-note",
                weeklyNoteSunday: props.note.sunday,
                source: "mouse",
              };
              stateManager.highlightEvents.emit(stateManager.currentHighlight);
              stateManager.emitClick();
              setEditing(true);
            }}
            className={clsx({
              [styles.displayViewCommon]: true,
              [styles.lockedView]: rowLockedBy !== null,
              [styles.displayView]: rowLockedBy === null,
            })}
          >
            {rowLockedBy !== null && (
              <div className={styles.lockedBy}>{rowLockedBy}</div>
            )}
            {renderCell({
              boxClasses: "",
              height: props.cellHeight ? props.cellHeight - 5 : 200,
              width: 100000,
              display: props.note.note,
            })}
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
