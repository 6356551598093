import { AccessToken, ErrResponse, LoginResult, User } from "./types";
import { Fetcher } from "./fetcher";
import { api } from "./api";

export class UserAPI {
  fetcher: Fetcher;
  constructor(fetcher: Fetcher) {
    this.fetcher = fetcher;
  }

  create(
    user: User,
    newPassword: string
  ): Promise<User[] | null | ErrResponse> {
    return this.fetcher.post<User[] | null>("/api/user", {
      newPassword: newPassword,
      user: user,
    });
  }

  list(): Promise<User[] | null | ErrResponse> {
    return this.fetcher.get<User[] | null>("/api/users");
  }

  edit(user: User): Promise<User[] | null | ErrResponse> {
    return this.fetcher.put<User[] | null>("/api/user", user);
  }

  setPassword(info: {
    id: number;
    newPassword: string;
  }): Promise<void | ErrResponse> {
    return this.fetcher.put<void>("/api/user/set-password", info);
  }

  login(email: string, password: string): Promise<ErrResponse | LoginResult> {
    return this.fetcher.publicPost("/api/login", {
      email: email,
      password: password,
      timeZoneOffset: -new Date().getTimezoneOffset(),
      source: "web",
    });
  }

  logout() {
    // @ts-ignore
    window.location = "/api/logout";
  }

  refreshAccessToken(): Promise<ErrResponse | AccessToken> {
    return api.fetcher.post<ErrResponse | AccessToken>(
      "/api/update-access-token",
      null
    );
  }
}
