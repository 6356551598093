import Grid from "@material-ui/core/Grid";
import { CompletedIcon } from "../../../../components/completed-icon";
import { Tooltip } from "@material-ui/core";
import * as React from "react";
import { ScheduleItemWithDriverReportInfo } from "../../../../api/types";
import { history, Nav, Paths } from "../../../../nav";
import { RouteComponentProps, withRouter } from "react-router";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import { Box } from "../boxes/box";
import { SimpleIconButton } from "../../../../misc/simple-icon-button";
import { StyledComponent } from "../boxes/styled-component";

type Props = RouteComponentProps<any> & {
  data: ScheduleItemWithDriverReportInfo;
  height: string | number;
};

type RouterComponent<T> = (
  props: Omit<T, "history" | "location" | "match" | "staticContext">
) => JSX.Element;

export const StatusCell: RouterComponent<Props> = withRouter(
  class StatusCell extends React.PureComponent<Props> {
    render() {
      return (
        <Box key="status" column="status" height={this.props.height}>
          <Grid container direction="column" spacing={2} alignItems="center">
            <Grid
              item
              key="completed"
              style={{
                padding: "10px",
                paddingTop: "20px",
                paddingBottom: "0",
              }}
            >
              <CompletedIcon status={this.props.data.driverReportStatus} />
            </Grid>
            {this.props.data.driverReportId !== null && (
              <Grid item key="link">
                <Tooltip title="View Report">
                  <SimpleIconButton
                    size="small"
                    onClick={() => {
                      // add a bread-crumb so when we return it will autoscroll
                      // to the correct spot
                      history.push(
                        window.location.pathname + "?show=" + this.props.data.id
                      );

                      const newPath = Nav.WithId(
                        Paths.DriverReport,
                        this.props.data.driverReportId || 0
                      );

                      history.push(newPath);
                    }}
                  >
                    <InsertChartIcon />
                  </SimpleIconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Box>
      );
    }
  } as any
) as any;
