import AttachFileIcon from "@material-ui/icons/AttachFile";
import { Theme } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { DocumentDialog } from "./document-dialog";
import { ScheduleItemWithDriverReportInfo } from "../../../../api/types";
import Grid from "@material-ui/core/Grid";
import { widths } from "../../state/config";
import { ColumnName } from "../../state/field";
import SettingsRemoteIcon from "@material-ui/icons/SettingsRemote";
import Tooltip from "@material-ui/core/Tooltip";
import { createStyles, makeStyles } from "@material-ui/styles";
import orange from "@material-ui/core/colors/orange";
import { PaperClipDropZone } from "./document-dialog/paper-clip-dropzone";
import { SimpleIconButton } from "../../../../misc/simple-icon-button";
import BusinessIcon from "@material-ui/icons/Business";
import green from "@material-ui/core/colors/green";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    driverButton: {
      color: orange["800"],
    },
    businessButton: {
      color: green["800"],
    },
  })
);

export function FilesCell(props: {
  data: ScheduleItemWithDriverReportInfo;
  column: ColumnName;
  height: number | string;
}) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const borderWidth = 1;
  const cellWidth = widths[props.column];
  const divWidth = widths[props.column] - borderWidth * 2 - 1;
  const onShowModal = useCallback(() => setShowModal(true), []);
  const styles = useStyles();

  const [delayShowTooltip, setDelayShowTooltip] = useState(true);
  useEffect(() => {
    const tm = setTimeout(() => {
      setDelayShowTooltip(true);
    }, 1000);

    return () => clearTimeout(tm);
  }, []);

  return (
    <td
      key="cell-selected"
      style={{
        width: cellWidth,
      }}
    >
      <div
        style={{
          width: divWidth,
          height: props.height,
          padding: 0,
        }}
      >
        <div style={{ padding: "0" }}>
          <PaperClipDropZone item={props.data}>
            <Grid
              container
              direction="row"
              spacing={0}
              alignItems="center"
              justify="center"
            >
              <Grid item>
                <SimpleIconButton onClick={onShowModal}>
                  <AttachFileIcon />
                </SimpleIconButton>
              </Grid>
              <Grid item>
                <Grid container>
                  {props.data.driverUploadedOneOrMoreFiles && (
                    <Grid item>
                      {delayShowTooltip && (
                        <Tooltip title="Driver uploaded files">
                          <SimpleIconButton
                            size="small"
                            onClick={onShowModal}
                            className={styles.driverButton}
                          >
                            <SettingsRemoteIcon />
                          </SimpleIconButton>
                        </Tooltip>
                      )}
                    </Grid>
                  )}
                  {props.data.officeUploadedOneOrMoreFiles && (
                    <Grid item>
                      <Tooltip title="Office uploaded files">
                        <SimpleIconButton
                          size="small"
                          onClick={onShowModal}
                          className={styles.businessButton}
                        >
                          <BusinessIcon />
                        </SimpleIconButton>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </PaperClipDropZone>
        </div>
        {showModal && (
          <DocumentDialog
            onClose={() => setShowModal(false)}
            data={props.data}
          />
        )}
      </div>
    </td>
  );
}
