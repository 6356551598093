import { createStyles, Dialog, Theme } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { DriverReportWithExtrasResponse } from "../../api/types";
import * as React from "react";
import Typography from "@material-ui/core/Typography";
import { api } from "../../api/api";
import ReactQuill from "react-quill";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles, WithStyles } from "@material-ui/styles";
import { StyledComponent } from "../schedule-editor/layout/boxes/styled-component";

const RQuill = ReactQuill as any;

const styles = (theme: Theme) =>
  createStyles({
    quill: {
      minHeight: "300px",
      "& > .ql-container": {
        minHeight: "270px",
      },
    },
  });

type Props = WithStyles<typeof styles> & {
  open: boolean;
  driverReportId: number;
  note: string;
  onDone(data: null | DriverReportWithExtrasResponse): void;
};

interface State {
  note: string;
  loading: boolean;
  error: string | null;
}

export const AdminNoteEditor: StyledComponent<Props> = withStyles(styles)(
  class AdminNoteEditor extends React.Component<Props, State> {
    constructor(props: any) {
      super(props);

      this.state = {
        loading: false,
        error: null,
        note: "",
      };
    }

    async save() {
      this.setState({
        loading: true,
        error: null,
      });

      try {
        const item = await api.driverReport.setAdminNotes({
          note: this.state.note,
          driverReportId: this.props.driverReportId,
        });

        if ("error" in item) throw new Error(item.error);

        this.setState({
          loading: false,
          note: "",
        });

        this.props.onDone(item);
      } catch (e: any) {
        this.setState({
          loading: false,
          error: e.message,
        });
      }
    }

    cancel() {
      this.setState({
        note: "",
      });

      this.props.onDone(null);
    }

    render() {
      return (
        <Dialog open={this.props.open} fullWidth={true} maxWidth={"sm"}>
          <DialogTitle>Admin Notes</DialogTitle>
          <DialogContent style={{ minHeight: "300px" }}>
            <RQuill
              theme="snow"
              modules={{
                toolbar: [["bold", { color: [] }]],
              }}
              formats={["bold", "color"]}
              className={this.props.classes.quill}
              onChange={(content: any) => {
                if (!this.props.open) return;

                this.setState({
                  note: content,
                });
              }}
              value={this.state.note || this.props.note}
            />
          </DialogContent>
          <DialogActions>
            {this.state.error && (
              <Typography color="error">{this.state.error}</Typography>
            )}
            {this.state.loading && <CircularProgress size="small" />}
            <Button disabled={this.state.loading} onClick={() => this.cancel()}>
              Cancel
            </Button>
            <Button disabled={this.state.loading} onClick={() => this.save()}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  }
) as any;
