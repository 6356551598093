import * as React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, WithStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { showSnack } from "../../../../components/snacker";
import ReactQuill from "react-quill";
import Quill from "quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { Command, processCommand } from "./command";
import { stateManager } from "../../state/state-manager";
import { StyledComponent } from "../boxes/styled-component";

// Quill fix for https://github.com/quilljs/quill/issues/2096
const Inline = Quill.import("blots/inline");

class CustomAttributes extends Inline {
  constructor(domNode: any, value: any) {
    super(domNode, value);

    const span = this.replaceWith(new Inline(Inline.create()));

    span.children.forEach((child: any) => {
      if (child.attributes) child.attributes.copy(span);
      if (child.unwrap) child.unwrap();
    });

    // here we apply every attribute from <font> tag to span as a style
    Object.keys(domNode.attributes).forEach(function (key) {
      if (domNode.attributes[key].name != "style") {
        var value = domNode.attributes[key].value;
        var name = domNode.attributes[key].name;
        if (name == "face") name = "font-family";
        span.format(name, value);
      }
    });

    this.remove();

    return span;
  }
}

CustomAttributes.blotName = "customAttributes";
CustomAttributes.tagName = "FONT";

Quill.register(CustomAttributes, true);
// end fix

let RQuill = ReactQuill as any;

const styles = (theme: Theme) =>
  createStyles({
    editorBox: {
      width: "100%",
      minWidth: "250px",
      minHeight: "calc(100% + 42px)",
      boxShadow: "0 0 5px 0px hsla(0, 0%, 0%, 0.6)",
      position: "relative",
      top: "-42px",
      background: "white",
    },
    textWrapper: {
      border: "2px solid " + theme.palette.primary.main,
      backgroundColor: "white",
      zIndex: 1,
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "stretch",
      "& .quill": {
        width: "100%",
        paddingBottom: "42px",
      },
    },
    hint: {
      position: "absolute",
      bottom: "0",
      left: "0",
      width: "100%",
      padding: "5px",
      paddingLeft: "10px",
    },
    textField: {
      width: "100%",
      height: "100%",
      outline: "none",
    },
  });

export function keyEventToCommand(e: React.KeyboardEvent<any>): Command {
  if (e.key === "Tab") {
    if (e.shiftKey) return "prev-cell";
    return "next-cell";
  }

  if (e.ctrlKey) {
    switch (e.key) {
      case "ArrowDown":
        return "down-cell";
      case "ArrowUp":
        return "up-cell";
      case "ArrowLeft":
        return "prev-cell";
      case "ArrowRight":
        return "next-cell";
    }
  }

  return "none";
}

type HTMLString = string;

type Props = WithStyles<typeof styles> & {
  ignoreStateManager?: boolean;
  initialValue: string;
  onMakeUpdate(value: string): Promise<any>;
  onEsc(): void;
};

interface State {
  value: string;
}

export class DefaultEditorClass extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      value: "",
    };
  }

  shouldSaveOnBlur: boolean = true;

  componentDidMount(): void {
    this.setState({
      value: this.props.initialValue,
    });

    stateManager.currentEditor = this;
  }

  componentWillUnmount() {
    if (this.shouldSaveOnBlur) {
      this.save();
    }
  }

  onEscapePressed() {
    this.shouldSaveOnBlur = false;
  }

  async save(): Promise<any> {
    if (this.props.initialValue === this.state.value) {
      return;
    }

    try {
      await this.props.onMakeUpdate(this.state.value);
      showSnack("Saved");
    } catch (e: any) {
      showSnack("Failed to save: " + e.message);
    }
  }

  // when enter is pressed and something is selected, onChange is fired
  // removing text just before it's saved. This captures the value before
  // onChange, so that if it's an enter press, we can still get that original
  // value
  lastValue: string = "";

  editor: ReactQuill | null = null;

  render() {
    stateManager.currentEditor = this;

    return (
      <Grid
        container
        className={this.props.classes.editorBox}
        direction="column"
        alignItems="stretch"
      >
        <Grid item className={this.props.classes.textWrapper} xs>
          <RQuill
            theme="snow"
            ref={(r: any) => {
              this.editor = r;
              // auto focus on construct
              if (this.editor !== null) this.editor.focus();
            }}
            modules={{
              toolbar: [["bold", { color: [] }]],
            }}
            formats={["bold", "color"]}
            onChange={(content: any) => {
              this.lastValue = this.state.value;

              this.setState({
                value: content,
              });
            }}
            onKeyDown={async (e: any) => {
              if (e.key === "Escape") {
                this.shouldSaveOnBlur = false;
                this.props.onEsc();
                return;
              }

              if (this.props.ignoreStateManager) {
                if (e.key === "Enter" && e.ctrlKey) {
                  this.shouldSaveOnBlur = false;
                  this.save();
                }

                return;
              }

              // do state manager integrated things
              const cmd = keyEventToCommand(e);

              if (
                [
                  "Enter",
                  "ArrowUp",
                  "ArrowDown",
                  "ArrowLeft",
                  "ArrowRight",
                ].indexOf(e.key) !== -1 &&
                e.ctrlKey
              ) {
                processCommand(cmd);
                return;
              }

              if (e.key === "Tab") {
                e.preventDefault();
                processCommand(cmd);
                return;
              }
            }}
            value={this.state.value || ""}
          />
          <Typography
            color="textSecondary"
            className={this.props.classes.hint}
            variant="caption"
          >
            Press ctrl+enter to close cell
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export const DefaultEditor: StyledComponent<Props> = withStyles(styles)(
  DefaultEditorClass
) as any;
