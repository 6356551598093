import * as React from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import { amber, green, grey } from "@material-ui/core/colors";
import { DriverReportStatus } from "../api/types";
import CancelIcon from "@material-ui/icons/Cancel";

interface Props {
  status: DriverReportStatus;
}

export class CompletedIcon extends React.PureComponent<Props> {
  render() {
    switch (this.props.status) {
      case "completed":
        return <CheckCircleIcon style={{ color: green["700"] }} />;
      case "in-progress":
        return <HourglassEmptyIcon style={{ color: amber["700"] }} />;
      default:
        return <CancelIcon style={{ color: grey["700"] }} />;
    }
  }
}
