import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import * as React from "react";
import { Nav, Paths } from "../nav";
import { MLink } from "./link";
import { createStyles, Grid, Theme, WithStyles } from "@material-ui/core";
import { RouteComponentProps, withRouter } from "react-router";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = (theme: Theme) =>
  createStyles({
    selected: {
      backgroundColor: theme.palette.primary.main,
      color: "white",

      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    button: {
      borderRadius: "2px",
    },
  });

interface PathProps {
  path: string;
}

interface ClickProps {
  onClick(): void;
}

type Props = {
  name: string;
  icon: JSX.Element;
  onClick?(): void;
} & (PathProps | ClickProps) &
  WithStyles<typeof styles> &
  RouteComponentProps;

export const SidebarItem = withRouter(
  // @ts-ignore: ts for withStyles and class-components is broken
  withStyles(styles)(
    class SidebarItem extends React.Component<Props, {}> {
      render() {
        if ("onClick" in this.props) {
          return (
            <Grid item>
              <Tooltip title={this.props.name}>
                <IconButton onClick={this.props.onClick}>
                  {this.props.icon}
                </IconButton>
              </Tooltip>
            </Grid>
          );
        }

        const path = this.props.path as Paths;

        if (!path) {
          throw new Error("missing .path property");
        }

        const isSelected = Nav.IsPath(this.props.location.pathname, path);
        var buttonClass = this.props.classes.button;
        if (isSelected) {
          buttonClass += " " + this.props.classes.selected;
        }

        return (
          <Grid item>
            <MLink to={this.props.path}>
              <Tooltip title={this.props.name}>
                <IconButton classes={{ root: buttonClass }}>
                  {this.props.icon}
                </IconButton>
              </Tooltip>
            </MLink>
          </Grid>
        );
      }
    } as any
  )
) as any;
