import { ColumnName, fields } from "./field";

interface ColumnConfig {
  name: ColumnName;
  width: number;
}

// column configurations, order is important!
export const config: ColumnConfig[] = fields.map((field) => {
  return {
    name: field.name,
    width: field.columnWidth,
  };
});

export const widths = fields.reduce((acc, item) => {
  // @ts-ignore
  acc[item.name] = item.columnWidth;
  return acc;
}, {}) as { [k in ColumnName]: number };

// @ts-ignore
export const totalWidth = fields.reduce(
  (acc, item) => item.columnWidth + acc,
  0
);
