import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import { createStyles } from "@material-ui/styles";
import {
  DateString,
  DriverReportResponse,
  ScheduleItem,
} from "../../../api/types";
import moment from "moment";
import { booleanToYesNo } from "../../../components/util";
import { api } from "../../../api/api";

const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      "& p": {
        margin: 0,
      },
    },
  })
);

export function ScheduleDetail(props: {
  items: ScheduleItem[] | null;
  reports: DriverReportResponse[] | null;
}) {
  const styles = useStyles();
  return (
    <Table size="small" className={styles.table}>
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell style={{ width: 150 }}>From</TableCell>
          <TableCell style={{ width: 150 }}>To</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Size</TableCell>
          <TableCell>Contact</TableCell>
          <TableCell>Admin</TableCell>
          <TableCell>Signed</TableCell>
          <TableCell>Site/Down</TableCell>
          <TableCell>Bl/UBl</TableCell>
          <TableCell>Tire</TableCell>
          <TableCell>Driver</TableCell>
          <TableCell>Notes</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.items?.map((s) => (
          <TableRow key={s.id}>
            <TableCell>
              {moment(s.localDate).utc().format("YYYY-MMM-DD")}
            </TableCell>
            <TableCell dangerouslySetInnerHTML={{ __html: s.pickupLocation }} />
            <TableCell
              dangerouslySetInnerHTML={{ __html: s.dropOffLocation }}
            />
            <TableCell dangerouslySetInnerHTML={{ __html: s.jobType }} />
            <TableCell dangerouslySetInnerHTML={{ __html: s.unitSize }} />
            <TableCell>
              {s.contacts?.map((c, index) => (
                <div
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: c.description.replace(/(<p><br><\/p>)+$/g, ""),
                  }}
                />
              ))}
            </TableCell>
            <TableCell dangerouslySetInnerHTML={{ __html: s.adminNotes }} />
            <TableCell>
              {s.signedDocuments?.map((d) => (
                <div key={d.id}>
                  <a target="_blank" href={api.files.viewSrc(d.id)}>
                    {d.name}
                  </a>
                </div>
              ))}
            </TableCell>
            <TableCell>
              {props.reports
                ?.filter((d) => d.scheduleItem === s.id)
                .map((d) => (
                  <div>
                    {formatHours(d.hoursOnSite)}/{formatHours(d.hoursDownTime)}
                  </div>
                ))}
            </TableCell>
            <TableCell>
              {props.reports
                ?.filter((d) => d.scheduleItem === s.id)
                .map((d) => (
                  <div>
                    {booleanToYesNo(d.block)}/{booleanToYesNo(d.unblock)}
                  </div>
                ))}
            </TableCell>
            <TableCell>
              {props.reports
                ?.filter((d) => d.scheduleItem === s.id)
                .map((d) => (
                  <div>{d.suppliedTires}</div>
                ))}
            </TableCell>
            <TableCell>{s.driver}</TableCell>
            <TableCell>
              {props.reports
                ?.filter((d) => d.scheduleItem === s.id)
                .map((d) => (
                  <div>{d.notes}</div>
                ))}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export function getHours(input: null | DateString[]) {
  if (!input || input.length === 0) return 0;

  const dates = input.map((i) => moment(i));

  let hrs = 0;
  for (let i = 0; i + 1 < dates.length; i += 2) {
    hrs += Math.abs(dates[i + 1].diff(dates[i], "hour", true));
  }

  return hrs;
}

function formatHours(input: null | DateString[]) {
  const h = getHours(input);
  if (h === 0) return "-";
  return h.toFixed(2) + "hrs";
}
