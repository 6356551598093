import { RouteComponentProps, withRouter } from "react-router";
import * as React from "react";
import { Dialog } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { auth } from "../core/authentication";

type Props = RouteComponentProps & {};
interface State {
  email: string;
  password: string;
  showing: boolean;
  loading: boolean;
  error?: string;
}

export const Login = withRouter(
  class Login extends React.Component<Props, State> {
    constructor(props: any) {
      super(props);

      this.state = {
        showing: false,
        email: "",
        password: "",
        loading: false,
        error: undefined,
      };

      auth.onLoginRequired = () => {
        if (this.state.showing) return;

        this.setState({
          showing: true,
          email: "",
          password: "",
        });
      };
    }

    async login() {
      try {
        this.setState({
          loading: true,
          error: undefined,
        });

        await auth.login(this.state.email, this.state.password);
        window.location.reload();
      } catch (e: any) {
        this.setState({
          loading: false,
          error: e.message,
        });
      }
    }

    render() {
      return (
        <Dialog open={this.state.showing} maxWidth="xs" fullWidth={true}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.login();
            }}
          >
            <DialogTitle>Login</DialogTitle>
            <DialogContent>
              <Grid container direction="column">
                <TextField
                  label="Email"
                  type="email"
                  name="email"
                  margin="normal"
                  required
                  value={this.state.email}
                  onChange={(e) =>
                    this.setState({
                      email: e.currentTarget.value,
                    })
                  }
                />
                <TextField
                  label="Password"
                  type="password"
                  name="password"
                  margin="normal"
                  required
                  value={this.state.password}
                  onChange={(e) =>
                    this.setState({
                      password: e.currentTarget.value,
                    })
                  }
                />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                {this.state.error && (
                  <Grid item>
                    <Typography color="error">{this.state.error}</Typography>
                  </Grid>
                )}
                {this.state.loading && (
                  <Grid item>
                    <CircularProgress size="18px" />
                  </Grid>
                )}
                <Grid item>
                  <Button type="submit" onClick={() => this.login()}>
                    Login
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </Dialog>
      );
    }
  } as any
);
