import { EventEmitter } from "./event-emitter";

type Callback<T> = (p: T) => void;
export interface Subscription {
  unsubscribe: () => void;
}

export class StatefulEventEmitter<T> extends EventEmitter<T> {
  lastEmit: T | undefined;

  emit(param: T) {
    this.lastEmit = param;
    super.emit(param);
  }

  subscribe(callback: Callback<T>): Subscription {
    if (this.lastEmit !== undefined) {
      callback(this.lastEmit);
    }

    return super.subscribe(callback);
  }

  clearCurrentState() {
    this.lastEmit = undefined;
  }
}
