import React, { useState } from "react";
import { EmailSignedDocModal } from "./email-signed-doc";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import { api } from "../../../../../../api/api";
import GetAppIcon from "@material-ui/icons/GetApp";

export function SignedFileAction(props: {
  id: number;
  docName: string;
  jobName: string;
}) {
  const [showEmailSignedDoc, setShowEmailSignedDoc] = useState(false);

  if (showEmailSignedDoc) {
    return (
      <EmailSignedDocModal
        id={props.id}
        docName={props.docName}
        jobName={props.jobName}
        onDone={() => setShowEmailSignedDoc(false)}
      />
    );
  }

  return (
    <Grid direction="row" container spacing={1}>
      <IconButton onClick={() => setShowEmailSignedDoc(true)}>
        <AlternateEmailIcon />
      </IconButton>
      <IconButton onClick={() => api.files.download(props.id)}>
        <GetAppIcon />
      </IconButton>
    </Grid>
  );
}
