import React, { useContext, useEffect, useState } from "react";
import { Dialog, List, Grid } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { useAsyncAction } from "../../../../api/async";
import { api } from "../../../../api/api";
import DialogContent from "@material-ui/core/DialogContent";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import TextField from "@material-ui/core/TextField";
import { JobTypeContext } from "./job-type-provider";
import { SimpleIconButton } from "../../../../misc/simple-icon-button";
import EditIcon from "@material-ui/icons/Edit";

export function EditJobTypeList(props: {}) {
  const [list, setList] = useState<string[]>([]);
  const [show, setShow] = useState(false);
  const [addValue, setAddValue] = useState("");

  const ctx = useContext(JobTypeContext);
  const updateProvider = ctx.update;

  useEffect(() => {
    setList(ctx.list);
  }, [ctx.list]);

  const action = useAsyncAction(async () => {
    await api.schedule.updateJobTypes(list);
    updateProvider(list);
    setShow(false);
  }, [updateProvider, list]);

  if (!show) {
    return (
      <SimpleIconButton size="small" onClick={() => setShow(true)}>
        <EditIcon style={{ fontSize: 16 }} />
      </SimpleIconButton>
    );
  }

  return (
    <Dialog open={show} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Job Types</DialogTitle>
      <DialogContent>
        <List dense>
          {list.map((l) => (
            <ListItem>
              <ListItemText primary={l} />
              <ListItemSecondaryAction>
                <IconButton
                  size="small"
                  onClick={() =>
                    setList((old) => old.filter((item) => item !== l))
                  }
                  edge="end"
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions style={{ paddingLeft: 36 }}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <TextField
              value={addValue}
              variant="outlined"
              label="Add New Value"
              onChange={(e) => setAddValue(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                setList([...list, addValue]);
                setAddValue("");
              }}
              variant="contained"
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
      <DialogActions>
        {action.LoadingElement}
        <Button onClick={() => setShow(false)}>Cancel</Button>
        <Button onClick={action.callback}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}
