import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  AutocompleteChangeReason,
  FilterOptionsState,
} from "@material-ui/lab/useAutocomplete/useAutocomplete";
import { TextField, Theme } from "@material-ui/core";
import * as React from "react";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles } from "@material-ui/styles";
import { keyEventToCommand } from "./default-editor";
import { Command } from "./command";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editorBox: {
      width: "100%",
      minWidth: "250px",
      boxShadow: "0 0 5px 0px hsla(0, 0%, 0%, 0.6)",
      position: "relative",
    },
    textWrapper: {
      border: "2px solid " + theme.palette.primary.main,
      paddingLeft: "5px",
      paddingRight: "5px",
      backgroundColor: "white",
      zIndex: 1,
      width: "100%",
    },
  })
);

export function CellAutoComplete<T>(
  props: {
    options: T[];
    getOptionLabel?: (option: T) => string;
    freeSolo?: boolean;
    placeholder?: string;
    filterOptions?: (options: T[], state: FilterOptionsState<T>) => T[];
    close(cmd: Command): void;
    requireConfirm?: boolean;
  } & (
    | {
        value: T | null;
        multiple?: false;
        save(value: T | string | null, cmd: Command): void;
      }
    | {
        value: T[];
        multiple: true;
        save(value: (T | string)[] | null, cmd: Command): void;
      }
  )
) {
  var commandBasedOnLastKey: Command = "none";
  const styles = useStyles();
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <Grid
      container
      className={styles.editorBox}
      direction="row"
      alignItems="stretch"
    >
      <Grid item className={styles.textWrapper}>
        <Autocomplete
          options={props.options}
          getOptionLabel={props.getOptionLabel}
          filterOptions={props.filterOptions}
          freeSolo={props.freeSolo}
          style={{ width: 250, paddingLeft: 10, paddingRight: 10 }}
          multiple={props.multiple}
          onChange={(
            e: React.ChangeEvent<{}>,
            value: string | T | (string | T)[] | null,
            reason: AutocompleteChangeReason
          ) => {
            if (!props.requireConfirm) {
              props.save(value as any, commandBasedOnLastKey);
            } else {
              setValue(value as any);
            }
          }}
          onBlur={(e) => props.close(commandBasedOnLastKey)}
          onKeyDown={(e) => {
            commandBasedOnLastKey = keyEventToCommand(e);
            if (commandBasedOnLastKey !== "none") {
              if (props.requireConfirm) {
                props.save(value as any, commandBasedOnLastKey);
              } else {
                // @ts-ignore
                e.target.blur();
              }
            }
          }}
          PaperComponent={({ children }) => (
            <Paper>
              {children}
              {props.requireConfirm && (
                <div style={{ padding: "10px", textAlign: "right" }}>
                  <Button
                    size="small"
                    onClick={() => {
                      props.close(commandBasedOnLastKey);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onMouseDown={(e) => {
                      e.stopPropagation();
                      props.save(value as any, commandBasedOnLastKey);
                    }}
                  >
                    Save
                  </Button>
                </div>
              )}
            </Paper>
          )}
          open={props.requireConfirm ? true : undefined}
          openOnFocus={!props.requireConfirm}
          blurOnSelect={!props.requireConfirm}
          value={value}
          renderInput={(params) => {
            var { InputProps, ...rest } = params;

            return (
              <TextField
                InputProps={Object.assign({}, InputProps, {
                  disableUnderline: true,
                  style: {
                    fontSize: "14px",
                    padding: "10px",
                  },
                })}
                placeholder={props.placeholder}
                {...rest}
                fullWidth
                autoFocus={true}
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );
}
