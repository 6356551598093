import { Grid, Paper } from "@material-ui/core";
import { QuickbooksStatus } from "./QuickbooksStatus";
import React, { useContext, useEffect, useRef } from "react";
import { useAsync } from "../../api/async";
import { api } from "../../api/api";
import { RefreshDrafts } from "./RefreshDrafts";
import { RefreshContext } from "./Invoicing";

export function Status(props: {}) {
  const status = useAsync(() => {
    return api.invoices.status();
  });

  const reload = useRef(status.reload);
  reload.current = status.reload;

  useEffect(() => {
    const i = setInterval(() => {
      reload.current();
    }, 15 * 1000);

    return () => clearInterval(i);
  }, []);

  const ctx = useContext(RefreshContext);

  useEffect(() => {
    const sub = ctx.subscribe(() => {
      setTimeout(() => reload.current(), 2000);
    });

    return () => sub.cancel();
  }, []);

  return (
    <div style={{ paddingBottom: 12 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          {status.error && status.LoadingOrErrorElement}
        </Grid>
        <Grid item>
          <RefreshDrafts
            onComplete={() => {
              status.reload();
            }}
          />
        </Grid>
        <Grid item>
          <QuickbooksStatus count={status.result?.pendingQuickbooks} />
        </Grid>
      </Grid>
    </div>
  );
}
