import * as React from "react";
import { Moment } from "moment";
import { Box } from "./boxes/box";
import { DateRow } from "./date-row";
import { dateColumnFormat } from "../state/field";

export function DateCell(props: { date: Moment }) {
  return (
    <Box
      dateRow={true}
      column="localDate"
      height={DateRow.height}
      valignCenter={true}
    >
      {props.date.format(dateColumnFormat)}
    </Box>
  );
}
