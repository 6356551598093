import * as React from "react";
import { ListDetailSplit } from "../components/list-detail-split";
import { Dialog } from "@material-ui/core";
import { CreateUser, User } from "../api/types";
import DialogContent from "@material-ui/core/DialogContent";
import { UserDetail } from "./users/user-detail";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { api } from "../api/api";
import { EditSection } from "../components/edit-section";
import { SetPasswordDialog } from "./users/set-password-dialog";
import { showSnack } from "../components/snacker";
import { driverColors } from "./schedule-editor/layout/controls/paint-button-popover";

interface Props {}
interface State {
  list: ListItem[];
  creating: CreateUser | null;
  selected: User | null;
  isCreating: boolean;
  isSettingPassword: boolean;
  loading: boolean;
  listError?: string;
  createError?: string;
}

type ListItem = User & { displayName: string };

export class Users extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      list: [],
      creating: null,
      selected: null,
      isCreating: false,
      isSettingPassword: false,
      loading: true,
      listError: undefined,
      createError: undefined,
    };
  }

  startCreating() {
    this.setState({
      isCreating: true,
      creating: {
        id: 0,
        name: "",
        email: "",
        isDriver: false,
        isAdmin: false,
        isArchived: false,
        newPassword: "",
        adminRowColor:
          driverColors[Math.round(Math.random() * (driverColors.length - 1))],
      },
    });
  }

  convertToDisplayUser(user: User): ListItem {
    const listItem = user as any as ListItem;
    listItem.displayName = user.name;
    return listItem;
  }

  componentDidMount(): void {
    this.getList();
  }

  async getList() {
    try {
      this.setState({
        loading: true,
        listError: undefined,
      });

      const users = await api.users.list();
      if (users !== null && "error" in users) {
        throw new Error(users.error);
      }

      this.setState({
        loading: false,
        list: this.prepUserList(users || []),
      });
    } catch (e: any) {
      this.setState({
        loading: false,
        listError: e.message,
      });
    }
  }

  async create() {
    if (!this.state.creating) return;
    if (!("newPassword" in this.state.creating)) return;

    try {
      this.setState({
        loading: true,
        createError: undefined,
      });

      const { displayName, newPassword, ...user } = this.state
        .creating as CreateUser & ListItem;
      const users = await api.users.create(user, newPassword);

      if (users !== null && "error" in users) {
        throw new Error(users.error);
      }

      this.setState({
        loading: false,
        list: this.prepUserList(users || []),
        isCreating: false,
      });
    } catch (e: any) {
      this.setState({
        loading: false,
        createError: e.message,
      });
    }
  }

  prepUserList(list: User[]): ListItem[] {
    const converted = list.map(this.convertToDisplayUser);

    converted.sort((a, b) => {
      if (a.displayName === b.displayName) return 0;
      if (a.displayName > b.displayName) return 1;
      return -1;
    });

    return converted;
  }

  async save() {
    if (!this.state.selected) return;
    const users = await api.users.edit(this.state.selected);
    if (users !== null && "error" in users) {
      throw new Error(users.error);
    }

    if (!this.state.selected) return;
    const id = this.state.selected.id;
    const user = (users || []).filter((item) => item.id === id)[0];

    this.setState({
      list: this.prepUserList(users || []),
      selected: user || undefined,
    });

    showSnack("Saved");
  }

  renderDetail() {
    if (!this.state.selected) {
      return null;
    }

    return (
      <EditSection
        title="Edit User"
        onSave={() => this.save()}
        onCancel={() =>
          this.setState({
            selected: null,
          })
        }
        extraButtons={[
          {
            name: "Set Password",
            onClick: () => this.setState({ isSettingPassword: true }),
          },
        ]}
      >
        <UserDetail
          {...this.state.selected}
          onChange={(key: keyof User, value: any) =>
            this.setState({
              selected: Object.assign({}, this.state.selected, {
                [key]: value,
              }),
            })
          }
        />
        <SetPasswordDialog
          userId={this.state.selected.id}
          name={this.state.selected.name}
          open={this.state.isSettingPassword}
          onDone={() => this.setState({ isSettingPassword: false })}
        />
      </EditSection>
    );
  }

  render() {
    return (
      <div style={{ height: "100%" }}>
        <ListDetailSplit
          title="Users"
          data={this.state.list}
          loading={this.state.loading}
          error={this.state.listError}
          onSelect={(item: any) => this.setState({ selected: item })}
          onCreate={() => this.startCreating()}
          renderDetail={() => this.renderDetail()}
        />
        <Dialog
          open={this.state.isCreating}
          onClose={() => this.setState({ isCreating: false })}
          fullWidth
          maxWidth="sm"
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.create();
            }}
          >
            <DialogTitle>Create User</DialogTitle>
            <DialogContent>
              {this.state.creating && (
                <UserDetail
                  {...this.state.creating}
                  onChange={(key: keyof CreateUser, value: any) =>
                    this.setState({
                      creating: Object.assign({}, this.state.creating, {
                        [key]: value,
                      }),
                    })
                  }
                />
              )}
            </DialogContent>
            <DialogActions>
              {this.state.createError && (
                <Typography color="error">{this.state.createError}</Typography>
              )}
              {this.state.loading && <CircularProgress size="15px" />}
              <Button
                onClick={() => this.setState({ isCreating: false })}
                color="primary"
              >
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Create
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}
